const HTML_TEMPLATES = {};

HTML_TEMPLATES.ROUTE_POINT_POPUP = `
  <div class="map-route-popup-container">
    <div class="map-route-popup__label" aria-hidden="true">Use this point as</div>
    <button class="map-route-popup__cta" aria-label="Use this point as start point" data-point="start">Start-point</button>
    <button class="map-route-popup__cta" aria-label="Use this point as end point" data-point="end">End-point</button>
  </div>
`;

export default HTML_TEMPLATES;
