import React, { useState } from 'react';
import s from './ShareMapDeepLink.module.scss';

/**
 * ShareMapDeepLink
 */
const ShareMapDeepLink = () => {
  const [copiedText, setCopiedText] = useState('');

  const deepLinkUrl = window.location.href;
  const handleDeepLinkUrlClick = () => {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        setCopiedText(' Map Link Copied!');
      })
      .catch(() => {
        setCopiedText('');
      });
  };

  return (
    <div className={`shareDeepLink ${s.ShareMapDeepLink}`}>
      <div className={`shareDeepLink__title ${s.ShareMapDeepLinkTitle}`}>
        Click to copy this link for map sharing
      </div>
      <div
        className={`shareDeepLink__url ${s.ShareMapDeepLinkUrl}`}
        onClick={handleDeepLinkUrlClick}
        aria-label="click to copy the map link"
      >
        {deepLinkUrl}
        {!!copiedText && (
          <div className={`shareDeepLink__copied ${s.ShareMapDeepLinkCopied}`}>
            {copiedText}
          </div>
        )}
      </div>
    </div>
  );
};

export default ShareMapDeepLink;
