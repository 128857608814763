import * as _ from 'lodash-es';
import FEATURES from '../../constants/features';
import mw from '../../utils/maps';
import { isNumber } from '../../utils/number';
import { isObject } from '../../utils/utils';
import { isStringEmpty } from '../../utils/string';
import { computeGeoDistanceInKmBetween } from '../../utils/distance';

/**
 * formatCategoryPoiResult
 * @param {[*]} results
 * @returns {[]}
 */
export const formatCategoryPoiResult = (results = []) => {
  if (!Array.isArray(results)) {
    return [];
  }

  const categoryPoisResults = [];

  results.forEach((item) => {
    const coordinates = _.get(item, 'geometry.coordinates', null);
    const coordinateLng = Array.isArray(coordinates) ? coordinates[0] : null;
    const coordinateLat = Array.isArray(coordinates) ? coordinates[1] : null;
    const building = _.get(item, 'properties.buildingName', null);
    const hasBuilding = !isStringEmpty(building);
    const campusId = _.get(item, 'properties.campusId', null);
    const campusDetail = isNumber(campusId) ? mw.getCampusById(campusId) : null;
    const campusName = isObject(campusDetail) ? campusDetail.name || '' : '';
    const campusLng = isObject(campusDetail) ? campusDetail.lng : null;
    const campusLat = isObject(campusDetail) ? campusDetail.lat : null;
    const floorVal = _.get(item, 'properties.floorName', null);
    const floor = floorVal === 'G' ? 'Ground' : floorVal;
    const hasZLevel =
      isNumber(_.get(item, 'properties.zLevel', null)) && !isStringEmpty(floor);
    const resultId = _.get(item, 'properties.poiId', null);
    const title = _.get(item, 'properties.title', null);

    categoryPoisResults.push({
      coordinates,
      coordinateLng,
      coordinateLat,
      building,
      campusId,
      campusName,
      floor,
      hasZLevel,
      hasBuilding,
      resultId,
      title,
      type: 'poi',
      distanceToCampus: {
        km: FEATURES.DISPLAY_SEARCH_RESULT_DISTANCE_TO_CAMPUS_CENTRE
          ? computeGeoDistanceInKmBetween(
              campusLng,
              campusLat,
              coordinateLng,
              coordinateLat,
            )
          : null,
      },
      distanceToGeolocation: {
        km: null, // default value, updated via geolocation updates in real time
      },
      originalPoiDetails: {
        ...item,
      },
    });
  });

  return categoryPoisResults;
};
