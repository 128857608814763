import { isNumber } from "./number";



const OPEN_DAY_CATEGORY_DATA = {
    "47664": [
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0009/3326373/OD23_MM-0fac_ADA.svg",
            "poiId": 1001607121,
            "title": "Art, design and architecture zone"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0010/3326374/OD23_MM-0fac_ARTS.svg",
            "poiId": 1001607122,
            "title": "Arts zone"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0011/3326375/OD23_MM-0fac_BUS.svg",
            "poiId": 1001607123,
            "title": "Business zone"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0003/3326376/OD23_MM-0fac_EDU.svg",
            "poiId": 1001607124,
            "title": "Education zone"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0004/3326377/OD23_MM-0fac_ENG.svg",
            "poiId": 1001607125,
            "title": "Engineering zone"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0005/3326378/OD23_MM-0fac_IT.svg",
            "poiId": 1001607126,
            "title": "Information technology zone"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0006/3326379/OD23_MM-0fac_LAW.svg",
            "poiId": 1001607127,
            "title": "Law zone"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0007/3326380/OD23_MM-0fac_MNHS.svg",
            "poiId": 1001607128,
            "title": "Medicine, nursing and health sciences zone"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0006/3326388/OD23_MM-0fac_PPS.svg",
            "poiId": 1001607129,
            "title": "Pharmacy and pharmaceutical sciences zone"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0007/3326389/OD23_MM-0fac_SCI.svg",
            "poiId": 1001607130,
            "title": "Science zone"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0003/3326349/OD23_MM_SCHOLS.svg",
            "poiId": 1001607131,
            "title": "Scholarships, admissions and student services zone"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0004/3326350/OD23_MM_CAMPUSLIFE.svg",
            "poiId": 1001607132,
            "title": "Campus life zone"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0009/3326355/OD23_MM_FOODandBEATS.svg",
            "poiId": 1001607133,
            "title": "Eats and beats zone"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0010/3342853/OD23_MM__ENTRY_315.svg",
            "poiId": 1001607134,
            "title": "Welcome point"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0009/3342870/OD23_MM__ENTRY_45.svg",
            "poiId": 1001607135,
            "title": "Welcome point"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0005/3342857/OD23_MM__ENTRY_270.svg",
            "poiId": 1001607136,
            "title": "Welcome point"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0008/3342869/OD23_MM__ENTRY_180.svg",
            "poiId": 1001607137,
            "title": "Welcome point"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0007/3326353/OD23_MM_INFO.svg",
            "poiId": 1001607138,
            "title": "Need help? #ChangeIt"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0006/3326352/OD23_MM_ACTIVATIONS.svg",
            "poiId": 1001607139,
            "title": "The Monash Experience"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0006/3326352/OD23_MM_ACTIVATIONS.svg",
            "poiId": 1001607140,
            "title": "The Change Exhibit"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0011/3326366/OD23_MM_ACCOMM.svg",
            "poiId": 1001607141,
            "title": "Accommodation information"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0009/3326364/OD23_MM_PARKING.svg",
            "poiId": 1001607142,
            "title": "Free parking"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0009/3326364/OD23_MM_PARKING.svg",
            "poiId": 1001607143,
            "title": "Free parking"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0009/3326364/OD23_MM_PARKING.svg",
            "poiId": 1001607144,
            "title": "Free parking"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0009/3326364/OD23_MM_PARKING.svg",
            "poiId": 1001607145,
            "title": "Free parking"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0009/3326364/OD23_MM_PARKING.svg",
            "poiId": 1001607146,
            "title": "Free parking"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0009/3326364/OD23_MM_PARKING.svg",
            "poiId": 1001607147,
            "title": "Free parking"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0009/3326364/OD23_MM_PARKING.svg",
            "poiId": 1001607148,
            "title": "Free parking"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0009/3326364/OD23_MM_PARKING.svg",
            "poiId": 1001607149,
            "title": "Free parking"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0003/3330642/OD23_MM_SBUS.svg",
            "poiId": 1001607150,
            "title": "Public transport"
        }
    ],
    "47887": [
        {
            "poiId": 1001620781,
            "imgUrl": "https://www.monash.edu/__data/assets/file/0009/3326373/OD23_MM-0fac_ADA.svg",
            "title": "Art, design and architecture"
        }, 
        {
            "poiId": 1001620782,
            "imgUrl": "https://www.monash.edu/__data/assets/file/0010/3326374/OD23_MM-0fac_ARTS.svg",
            "title": "Arts, humanities, social sciences and performing arts"
        },
        {
            "poiId": 1001620783,
            "imgUrl": "https://www.monash.edu/__data/assets/file/0011/3326375/OD23_MM-0fac_BUS.svg",
            "title": "Business"
        },
        {
            "poiId": 1001620784,
            "imgUrl": "https://www.monash.edu/__data/assets/file/0007/3326380/OD23_MM-0fac_MNHS.svg",
            "title": "Medicine, nursing and health sciences"
        },
        {
            "poiId": 1001620786, 
            "imgUrl": "https://www.monash.edu/__data/assets/file/0003/3326349/OD23_MM_SCHOLS.svg",
            "title": "Scholarships, admissions and support services"
        },
        {
            "poiId": 1001620785,
            "imgUrl": "https://www.monash.edu/__data/assets/file/0004/3326350/OD23_MM_CAMPUSLIFE.svg",
            "title": "Campus life"
        },
        {
            "poiId": 1001620787,
            "imgUrl": "https://www.monash.edu/__data/assets/file/0009/3326355/OD23_MM_FOODandBEATS.svg",
            "title": "Eats and beats zone"
        },
        {
            "poiId": 1001620788,
            "imgUrl": "https://www.monash.edu/__data/assets/file/0009/3342960/OD23_MM__ENTRY_90.svg",
            "title": "Welcome point"
        },
        {
            "poiId": 1001620789,
            "imgUrl": "https://www.monash.edu/__data/assets/file/0008/3342869/OD23_MM__ENTRY_180.svg",
            "title": "Welcome point"
        },
        {
            "poiId": 1001620790,
            "imgUrl": "https://www.monash.edu/__data/assets/file/0006/3342957/OD23_MM__ENTRY_0.svg",
            "title": "Welcome point"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0007/3326353/OD23_MM_INFO.svg",
            "poiId": 1001620791,
            "title": "Need help? #ChangeIt"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0009/3326364/OD23_MM_PARKING.svg",
            "poiId": 1001620793,
            "title": "Free parking"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0005/3339986/OD23_MM_TRAIN.svg",
            "poiId": 1001620794,
            "title": "Public transport"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0003/3330642/OD23_MM_SBUS.svg",
            "poiId": 1001636194,
            "title": "Park and ride"
        }
    ],
    "47662": [
        {
            "poiId": 1001605880,
            "imgUrl": "https://www.monash.edu/__data/assets/file/0011/3326375/OD23_MM-0fac_BUS.svg",
            "title": "Business"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0003/3326376/OD23_MM-0fac_EDU.svg",
            "poiId": 1001605881,
            "title": "Education zone"
        },
        {
            "poiId": 1001607009,
            "imgUrl": "https://www.monash.edu/__data/assets/file/0007/3326380/OD23_MM-0fac_MNHS.svg",
            "title": "Medicine, nursing and health sciences"
        },
        {
            "poiId": 1001607010, 
            "imgUrl": "https://www.monash.edu/__data/assets/file/0003/3326349/OD23_MM_SCHOLS.svg",
            "title": "Scholarships, admissions and support services"
        },
        {
            "poiId": 1001607011,
            "imgUrl": "https://www.monash.edu/__data/assets/file/0004/3326350/OD23_MM_CAMPUSLIFE.svg",
            "title": "Campus life"
        },
        {
            "poiId": 1001607012,
            "imgUrl": "https://www.monash.edu/__data/assets/file/0009/3326355/OD23_MM_FOODandBEATS.svg",
            "title": "Eats and beats zone"
        },
        {
            "poiId": 1001607013,
            "imgUrl": "https://www.monash.edu/__data/assets/file/0010/3342961/OD23_MM__ENTRY_135.svg",
            "title": "Welcome point"
        },
        {
            "poiId": 1001607014,
            "imgUrl": "https://www.monash.edu/__data/assets/file/0006/3342957/OD23_MM__ENTRY_0.svg",
            "title": "Welcome point"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0007/3326353/OD23_MM_INFO.svg",
            "poiId": 1001607015,
            "title": "Need help? #ChangeIt"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0009/3326364/OD23_MM_PARKING.svg",
            "poiId": 1001607016,
            "title": "Free parking"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0009/3326364/OD23_MM_PARKING.svg",
            "poiId": 1001607017,
            "title": "Free parking"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0009/3326364/OD23_MM_PARKING.svg",
            "poiId": 1001607018,
            "title": "Free parking"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0009/3326364/OD23_MM_PARKING.svg",
            "poiId": 1001607019,
            "title": "Free parking"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0009/3326364/OD23_MM_PARKING.svg",
            "poiId": 1001607020,
            "title": "Free parking"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0003/3330642/OD23_MM_SBUS.svg",
            "poiId": 1001607021,
            "title": "Shuttle Bus"
        }
    ],
    "47970": [
        {
            "poiId": 1001627604,
            "imgUrl": "https://www.monash.edu/__data/assets/file/0003/3326349/OD23_MM_SCHOLS.svg",
            "title": "Information Expo"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0006/3326388/OD23_MM-0fac_PPS.svg",
            "poiId": 1001627605,
            "title": "Course presentations"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0006/3326388/OD23_MM-0fac_PPS.svg",
            "poiId": 1001627607,
            "title": "Interactive activities"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0006/3326388/OD23_MM-0fac_PPS.svg",
            "poiId": 1001627606,
            "title": "Campus tours"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0004/3326350/OD23_MM_CAMPUSLIFE.svg",
            "poiId": 1001627614,
            "title": "Parkville student life"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0009/3326355/OD23_MM_FOODandBEATS.svg",
            "poiId": 1001627615,
            "title": "Free food and entertainment"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0005/3342857/OD23_MM__ENTRY_270.svg",
            "poiId": 1001627616,
            "title": "Welcome point"
        },
        {
            "imgUrl": "https://www.monash.edu/__data/assets/file/0005/3339986/OD23_MM_TRAIN.svg",
            "poiId": 1001627884,
            "title": "Public transport"
        },
    ]
}

const OPEN_DAY_MARKER_OPTIONS = {
    imgUrl: 'https://www.monash.edu/open-day/media/icons/OD-03-BUS.svg',
    imgScale: 1.35,
    color: 'white',
    size: 42,
    shape: 'marker',
    innerCircle: true,
    innerCircleColor: '#FEFEFE',
    innerCircleScale: 0.9, // Percentage of the marker area
    includeShadow: true,
    title: 'Open day 2022: '
}

/**
 * Is Open Day Category
 * @param {number} campusId 
 * @param {number} poiTypeId 
 */
const isOpenDayCategory = (campusId, poiTypeId) => {
    return (campusId === 159 && poiTypeId === 47664) ||
        (campusId === 410 && poiTypeId === 47887) ||
        (campusId === 411 && poiTypeId === 47662) ||
        (campusId === 412 && poiTypeId === 47970)
}

/**
 * 
 * @param {boolean} isOpenDay 
 * @param {number} poiId 
 * @param {number} poiTypeId 
 * @param {obj} defaultMarkerOption - fallback option when not Open Day category or info is not found.
 * @returns 
 */
const makeOpenDayMarker = (isOpenDay, poiId, poiTypeId, defaultMarkerOption) => {

    if (isOpenDay && poiId !== null && isNumber(poiId)) {
        const item = OPEN_DAY_CATEGORY_DATA[poiTypeId].find(f => f.poiId === poiId);
        if (item && item.imgUrl) {
            // not sanitize as data source is from fixed json
            return {
                ...OPEN_DAY_MARKER_OPTIONS,
                imgUrl: item?.imgUrl,
                title: `${OPEN_DAY_MARKER_OPTIONS.title + item?.title}`
            }
        }
    }

    return defaultMarkerOption
}


/**
 * Generates an object for button styling
 * @param {boolean} shouldDisplayIcon 
 * @param {number} poiId 
 */
const makeOpenDayButtonStyle = (shouldDisplayIcon, poiId) => {

    const allDataArray = Object.keys(OPEN_DAY_CATEGORY_DATA)
                        .map(key => OPEN_DAY_CATEGORY_DATA[key])
                        .reduce((prev, current) => [...prev, ...current])

    const imgUrl = !shouldDisplayIcon && allDataArray.find(f => f.poiId === poiId)?.imgUrl
    const btnStyle = imgUrl ? { backgroundImage: `url(${imgUrl})` } : null
    return {
        style: btnStyle,
        className: imgUrl ? 'OpenDayIcon' : null
    }
}

const OpenDay = {
    isOpenDayCategory,
    makeOpenDayMarker,
    makeOpenDayButtonStyle
}

export default OpenDay;