import * as _ from 'lodash-es';
import mw from '../../utils/maps';
import { MAP_ACTIONS, UI_ACTIONS } from '../../reducers/mapReducer';
import {
  DEFAULT_SEARCH_DISPLAY_STATE,
  DEFAULT_SEARCH_RESULTS_STATE,
} from '../../contexts/MapContext';
import {
  BACK_BTN_TARGET,
  BOTTOM_SHEET_VIEW_STATE,
  SEARCH_DISPLAY,
} from '../../constants/constants';
import { isFunction, isObject } from '../../utils/utils';
import { isNumber } from '../../utils/number';
import { isStringEmpty } from '../../utils/string';
import { SEARCH_RESULT_ITEM_TYPE } from './SearchResults.utils';

/**
 * isPoiDetailsTypeBuilding
 * @param poiDetails
 * @returns {boolean}
 */
export const isPoiDetailsTypeBuilding = (poiDetails = {}) => {
  const kind = _.get(poiDetails, 'properties.kind');
  const type = _.get(poiDetails, 'properties.type');
  return (
    kind === SEARCH_RESULT_ITEM_TYPE.BUILDING ||
    type === SEARCH_RESULT_ITEM_TYPE.BUILDING
  );
};

/**
 * applyPoiPointDetails
 */
export const applyPoiPointDetails = ({ lngLat, poi }, dispatch) => {
  // skip if no lngLat data
  if (!Array.isArray(lngLat) || !isNumber(lngLat[0]) || !isNumber(lngLat[1])) {
    return;
  }
  // skip if no details data
  if (!isObject(poi)) {
    // reset selected POI state and view
    dispatch({
      type: MAP_ACTIONS.SEARCH_POI_SELECTED,
      payload: {
        poiDetail: null,
      },
    });
    dispatch({
      type: MAP_ACTIONS.SEARCH_CATEGORY_SELECTED,
      payload: {
        ...DEFAULT_SEARCH_RESULTS_STATE.selectedCategory,
      },
    });
    dispatch({
      type: UI_ACTIONS.UPDATE_UI_SEARCH_DISPLAY,
      payload: {
        ...DEFAULT_SEARCH_DISPLAY_STATE,
      },
    });
    dispatch({
      type: UI_ACTIONS.UPDATE_UI_BOTTOM_SHEET,
      payload: {
        bottomSheetState: BOTTOM_SHEET_VIEW_STATE.HIDDEN,
      },
    });

    return;
  }
  // skip if no dispatch
  if (!isFunction(dispatch)) {
    return;
  }

  // update map UI
  const isSearchResultData = !isStringEmpty(poi.searchResultDataType);
  mw.clearCategoryMarkers();
  // Note: only search result data requires manually adding marker
  if (isSearchResultData) {
    // needs manual delay for map updates after bottom sheet map.resize()
    setTimeout(() => {
      mw.placeClickMarker({
        lngLat: [...lngLat],
        poi: { ...poi },
      });

      // set the zLevel to the marker if different
      mw.setMapZLevel(mw.getZLevelFromPoi(poi));
    }, 30);
  }

  // update app UI
  dispatch({
    type: MAP_ACTIONS.SEARCH_POI_SELECTED,
    payload: {
      poiDetail: {
        originalPoiDetails: {
          ...poi,
          lngLat: [...lngLat],
        },
      },
    },
  });
  const backBtnTarget = poi.searchResultBackBtnTarget || BACK_BTN_TARGET.NONE;
  dispatch({
    type: UI_ACTIONS.UPDATE_UI_SEARCH_DISPLAY,
    payload: {
      component: SEARCH_DISPLAY.SEARCH_RESULT_POI_DETAIL,
      backBtnTarget,
    },
  });
  dispatch({
    type: UI_ACTIONS.UPDATE_UI_BOTTOM_SHEET,
    payload: {
      bottomSheetState: BOTTOM_SHEET_VIEW_STATE.COLLAPSED,
    },
  });
};
