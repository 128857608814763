import * as _ from 'lodash-es';
import {
  BOTTOM_SHEET_VIEW_STATE,
  DIRECTIONS_RESULT_VIEW_STATES,
  POINT_DISPLAY_TEXT,
  TRAVEL_MODES,
  TRAVEL_OPTIONS,
} from '../constants/constants';
import mw from './maps';
import { MAP_ACTIONS, UI_ACTIONS } from '../reducers/mapReducer';
import { isStringEmpty } from './string';
import { isNumber } from './number';
import { isBoolean, isFunction, isObject } from './utils';
import {
  transformDirectionSearchIns,
  transformDirectionSearchSteps,
} from '../components/directions/DirectionsResult.mapper';
import BIData from '../api/BIData';

/**
 * isDirectionsStartDefined
 * @param mapState
 * @returns {boolean}
 */
export const isDirectionsStartDefined = (mapState = {}) => {
  if (!mapState || !mapState.directions) {
    return false;
  }
  return isObject(mapState.directions.from);
};

/**
 * isDirectionsEndDefined
 * @param mapState
 * @returns {boolean}
 */
export const isDirectionsEndDefined = (mapState = {}) => {
  if (!mapState || !mapState.directions) {
    return false;
  }
  return isObject(mapState.directions.to);
};

/**
 * getDirectionsPointData
 * @param mapClickEventData
 * @returns {null} matches shape from initial state
 */
export const getDirectionsPointData = (mapClickEventData = {}) => {
  const { lngLat, poi } = mapClickEventData;
  const hasLngLat = isObject(lngLat);
  const hasPoi = isObject(poi) || isBoolean(poi);

  if (!hasLngLat && !hasPoi) {
    return null;
  }

  return {
    lngLat: hasLngLat ? { ...lngLat } : null,
    poi: hasPoi ? (isBoolean(poi) ? poi : { ...poi }) : null,
  };
};

/**
 * getDirectionsPointDataFromSearchResult
 * @param searchResultData
 * @returns {null} matches shape from initial state
 */
export const getDirectionsPointDataFromSearchResult = (
  searchResultData = {},
) => {
  // lng lat
  const lngLat = _.get(searchResultData, 'originalPoiDetails.lngLat', null);
  if (!Array.isArray(lngLat) || !isNumber(lngLat[0]) || !isNumber(lngLat[1])) {
    return null;
  }

  // title with default
  let title = _.get(searchResultData, 'originalPoiDetails.title', '');
  if (isStringEmpty(title)) {
    title = 'Point';
  }

  // z Level with default
  const zLevelData = _.get(
    searchResultData,
    'originalPoiDetails.properties.zLevel',
    null,
  );
  const zValueData = _.get(
    searchResultData,
    'originalPoiDetails.properties.zValue',
    null,
  );
  const zLevel = isNumber(zLevelData)
    ? zLevelData
    : isNumber(zValueData)
    ? zValueData
    : mw.MAP_INIT_DEFAULT_CONFIGS.zLevel;

  // POI id
  const poiId = _.get(
    searchResultData,
    'originalPoiDetails.properties.poiId',
    null,
  );
  const hasPoiId = isNumber(poiId);

  // prep and return route point data payload
  return {
    lngLat: {
      lng: lngLat[0],
      lat: lngLat[1],
    },
    poi: hasPoiId
      ? {
          properties: {
            poiId,
            title,
            zLevel,
          },
        }
      : false,
  };
};

/**
 * transformPointToDisplayText
 * @param point
 * @returns {string}
 */
export const transformPointToDisplayText = (point = null) => {
  if (!isObject(point)) {
    return '';
  }

  const { lngLat, poi, isGeolocateData } = point;
  const hasLngLat = isObject(lngLat);
  const hasPoiObj = isObject(poi);

  if (!hasLngLat && !hasPoiObj) {
    return POINT_DISPLAY_TEXT.NONE;
  } else if (hasPoiObj) {
    return (
      (poi.properties && poi.properties.title) || POINT_DISPLAY_TEXT.DEFAULT
    );
  } else if (isGeolocateData === true) {
    return POINT_DISPLAY_TEXT.USER_LOCATION;
  } else {
    return POINT_DISPLAY_TEXT.DEFAULT;
  }
};

/**
 * transformPointToDisplayVariant
 * @param point
 * @returns {string}
 */
export const transformPointToDisplayVariant = (point = null) => {
  if (!isObject(point)) {
    return '';
  }

  if (point.isGeolocateData === true) {
    return 'geolocate';
  } else {
    return '';
  }
};

/**
 * transformPointToApiParam
 * @param point
 * @returns {object}
 */
export const transformPointToApiParam = (point = null) => {
  if (!isObject(point)) {
    return null;
  }

  const { lngLat, poi } = point;

  // check POI first
  if (isObject(poi)) {
    return { poiId: poi.properties && poi.properties.poiId };
  }

  // then check LngLat
  if (isObject(lngLat)) {
    return {
      lngLat: { ...lngLat },
      zLevel: 1, // default floor level
    };
  }

  return null;
};

/**
 * transformOptionsToApiParam
 * @param mode
 * @param options
 * @returns {object}
 */
export const transformOptionsToApiParam = (mode, options) => {
  const avoidStairsVal = options[TRAVEL_OPTIONS.AVOID_STAIRS_OBSTACLES.keyText];
  return {
    mode: mode || TRAVEL_MODES.PEDESTRIAN,
    avoidStairs: isBoolean(avoidStairsVal) ? avoidStairsVal : true,
  };
};

/**
 * isPointValidForDirections
 * @param point
 * @returns {boolean}
 */
export const isPointValidForDirections = (point = null) => {
  if (!isObject(point)) {
    return false;
  }

  const { lngLat, poi } = point;
  const hasLngLat = isObject(lngLat);
  const hasPoi = isObject(poi) || isBoolean(poi);

  return hasLngLat && hasPoi;
};

/**
 * getSetMapRouteDataCallback
 * @param from
 * @param to
 * @param options
 * @param dispatch
 * @returns {Function}
 */
export const getSetMapRouteDataCallback =
  ({ from = null, to = null, options = null, dispatch = null }) =>
  (geoJson = null) => {
    if (
      !isObject(geoJson) ||
      !isObject(from) ||
      !isObject(to) ||
      !isObject(options) ||
      !isFunction(dispatch)
    ) {
      return;
    }

    // these two data are already available before making this API call
    const { metrics, steps } = transformDirectionSearchSteps(geoJson);

    // data for bi analytics
    const biData = {
      routing: geoJson,
      directions: null,
    };

    // make API call
    window.Mazemap.Data.getDirections(from, to, options)
      .then((data) => {
        biData.directions = data; // fill bi data

        const instructions = transformDirectionSearchIns(data);
        // push results to map states
        dispatch({
          type: MAP_ACTIONS.UPDATE_DIRECTION_RESULT,
          payload: {
            viewState: DIRECTIONS_RESULT_VIEW_STATES.DETAILS_LOADED, // all data loaded
            errorMsg: null,
            metrics,
            steps,
            instructions,
          },
        });
        dispatch({
          type: UI_ACTIONS.UPDATE_UI_BOTTOM_SHEET,
          payload: {
            bottomSheetState: BOTTOM_SHEET_VIEW_STATE.COLLAPSED,
          },
        });
      })
      .catch((err) => {
        console.warn('Mazemap.Data.getDirections: ', err);
        // 404 error means 'step-by-step navigation is unavailable', update as null
        dispatch({
          type: MAP_ACTIONS.UPDATE_DIRECTION_RESULT,
          payload: {
            viewState: DIRECTIONS_RESULT_VIEW_STATES.ROUTE_LOADED, // no instructions results
            errorMsg: null,
            metrics,
            steps,
            instructions: null,
          },
        });
        dispatch({
          type: UI_ACTIONS.UPDATE_UI_BOTTOM_SHEET,
          payload: {
            bottomSheetState: BOTTOM_SHEET_VIEW_STATE.COLLAPSED,
          },
        });
      })
      .finally(() => {
        // analytics
        BIData.push(BIData.TAG_POINT_TO_POINT_ROUTING, biData);
      });
  };
