import './Loader.scss';

const Loader = ({ size = 'small', text = '' }) => (
  <div className={size}>
    {text && <span className="loader-text">{text}</span>}
    <div className="line-scale-pulse-out-rapid">
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);

export default Loader;
