import React from 'react';
import { TRAVEL_OPTIONS } from '../../../constants/constants';
import TravelOption from './TravelOption';

import s from './TravelOptionGroup.module.scss';

/**
 * TravelOptionGroup
 */
const TravelOptionGroup = ({
  travelOptions = {},
  updateTravelOptionsCallBack = null,
}) => {
  return (
    <div className={`travel-option-group ${s.TravelOptionGroup}`}>
      {/* render all travel options with boolean value */}
      {Object.keys(TRAVEL_OPTIONS).map((optionKey, index) => {
        const optionValue = travelOptions[optionKey];
        return (
          <TravelOption
            key={`travel-option-item-${index + 1}`}
            optionKey={optionKey}
            optionValue={optionValue}
            updateTravelOptionsCallBack={updateTravelOptionsCallBack}
          />
        );
      })}
    </div>
  );
};

export default TravelOptionGroup;
