import { isObject } from '../utils/utils';
import { isNumber } from '../utils/number';

const updateCategoriesIconMap = (state, action) => {
  const categoriesIcons = state.categoriesIcons;
  const typeInfos = action.payload.typeInfos;
  if (!isObject(categoriesIcons) || !Array.isArray(typeInfos)) {
    return state;
  }

  // add new type infos into 'newCategoriesIcons'
  // note: 'icon_id' can be null, we will still add and keep the key in icon map
  const newCategoriesIcons = {};
  typeInfos.forEach((typeInfo) => {
    const { id, icon_id } = typeInfo;
    if (!isNumber(id)) {
      return;
    }
    const key = `${id}`;
    if (
      (Object.keys(categoriesIcons).includes(key) &&
        categoriesIcons[key] === icon_id) ||
      (Object.keys(newCategoriesIcons).includes(key) &&
        newCategoriesIcons[key] === icon_id)
    ) {
      return;
    }
    newCategoriesIcons[key] = icon_id;
  });
  if (Object.keys(newCategoriesIcons).length === 0) {
    return state;
  }

  console.debug('[newCategoriesIcons]:', newCategoriesIcons);
  return {
    ...state,
    categoriesIcons: {
      ...categoriesIcons,
      ...newCategoriesIcons,
    },
  };
};

const categoriesReducers = {
  updateCategoriesIconMap,
};
export default categoriesReducers;
