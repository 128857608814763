import React, { useState } from 'react';
import IconWrapper, { Icons } from '../icons/Icons';

import s from './SimpleAccordion.module.scss';

/**
 * LinkTrigger
 */
export const LinkTrigger = ({
  expanded = false,
  labelExpanded = 'Close',
  labelCollapsed = 'Open',
  onClickHandlerCallback = null,
}) => {
  const handleTriggerClick = (e) => {
    e.preventDefault();
    if (typeof onClickHandlerCallback === 'function') {
      onClickHandlerCallback();
    }
  };

  return (
    <button
      className="btnLink"
      data-expanded={expanded}
      aria-expanded={expanded}
      onClick={handleTriggerClick}
    >
      {expanded ? labelExpanded : labelCollapsed}
      <IconWrapper>{Icons.Chevron()}</IconWrapper>
    </button>
  );
};

/**
 * SimpleAccordion
 */
const SimpleAccordion = ({
  labelExpanded = 'Close',
  labelCollapsed = 'Open',
  children,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // handler
  const triggerClickHandlerCallback = () => {
    setIsExpanded(!isExpanded);
  };

  return !!children ? (
    <div
      className={`simple-accordion ${s.SimpleAccordion}`}
      data-expanded={isExpanded}
      data-dir-result-sec="content-accordion"
    >
      <div className={`simple-accordion__trigger ${s.Trigger}`}>
        <LinkTrigger
          expanded={isExpanded}
          labelExpanded={labelExpanded}
          labelCollapsed={labelCollapsed}
          onClickHandlerCallback={triggerClickHandlerCallback}
        />
      </div>
      <div className={`simple-accordion__content ${s.Content}`}>{children}</div>
    </div>
  ) : null;
};

export default SimpleAccordion;
