/**
 * isNumber
 * @param input
 * @returns {boolean}
 */
export const isNumber = (input) => {
  // supports all modern browsers, NOT IE
  return Number.isFinite(input);
};

/**
 * roundToPrecision
 * @param num
 * @param precision
 * @returns {number}
 */
export const roundToPrecision = (num, precision) => {
  return parseFloat(num.toFixed(precision));
};
