import React from 'react';

import s from '../DirectionsResult.module.scss';

/**
 * ResultLoading
 */
const ResultLoading = ({ instance = '' }) => {
  return (
    <div className={s.DirectionsResultLoading} data-instancce={instance}>
      Loading results...
    </div>
  );
};

export default ResultLoading;
