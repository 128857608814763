import React from 'react';
import Slider from 'react-slick';
import mw from '../../utils/maps';

import s from '../search/SearchControl.module.scss';

// Note: All Campuses filter conflicts with deep linking functionality, disabled
const ALL_CAMPUSES_FILTER_ENABLED = false;

// slider view configs
const CAMPUS_SLIDER_CONFIGS = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 2,
  variableWidth: true,
};

export default function CampusCategoryFilterDesktop({
  campusIdSelected = '',
  campusRadioChangeHandler = () => {},
}) {
  return (
    // campus filter scroller control
    <div className={s.CampusScroller}>
      <Slider {...CAMPUS_SLIDER_CONFIGS}>
        {/* campus options */}
        {Object.keys(mw.CAMPUS_DETAILS).map(function (key) {
          const id = mw.CAMPUS_DETAILS[key].id;
          const htmlId = `campus-cate-filter-desktop-${id}`;
          return (
            <div className={s.CampusPill} key={key}>
              <input
                className="visuallyhidden"
                key={id.toString()}
                id={htmlId}
                type="radio"
                name="optCampusCateDesktop"
                value={id.toString()}
                checked={`${campusIdSelected}` === id.toString()}
                onChange={(e) => campusRadioChangeHandler(e, id, key)}
                tabIndex="-1"
              />
              <label htmlFor={htmlId} tabIndex="0">
                {mw.CAMPUS_DETAILS[key].name}
              </label>
            </div>
          );
        })}
        {/* all campuses option */}
        {ALL_CAMPUSES_FILTER_ENABLED && (
          <div className={s.CampusPill}>
            <input
              className="visuallyhidden"
              type="radio"
              name="optCampusCateDesktop"
              id="campus-cate-filter-desktop-all"
              value={'All'}
              checked={campusIdSelected === 'All'}
              onChange={(e) => {
                campusRadioChangeHandler(e, null);
              }}
              tabIndex="-1"
            />
            <label htmlFor="campus-cate-filter-desktop-all" tabIndex="0">
              All Campuses
            </label>
          </div>
        )}
      </Slider>
    </div>
  );
}
