import React, { useContext } from 'react';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { MapContext } from '../../contexts/MapContext';
import { SEARCH_CONTROL } from '../../constants/constants';

const PanelDrawer = ({ children }) => {
  const isMobile = useMediaQuery();
  const { map } = useContext(MapContext);
  const { searchControl } = map.ui;

  // search section display flags
  const isDirectionsSearch = searchControl === SEARCH_CONTROL.DIRECTIONS;

  const shouldHidePanelDrawer = isDirectionsSearch && isMobile;

  return (
    <div className={`panel-drawer${shouldHidePanelDrawer ? ' hidden' : ''}`}>
      {children}
    </div>
  );
};

export default PanelDrawer;
