import * as _ from 'lodash-es';
import React, { useContext, useRef } from 'react';
import { DIRECTIONS_RESULT_VIEW_STATES } from '../../constants/constants';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { MapContext } from '../../contexts/MapContext';
import mw from '../../utils/maps';
import { isInstanceMatching } from '../../utils/utils';
import {
  getSetMapRouteDataCallback,
  isPointValidForDirections,
  transformOptionsToApiParam,
  transformPointToApiParam,
} from '../../utils/directions';
import ResultLoading from './directionsResult/ResultLoading';
import ResultError from './directionsResult/ResultError';
import ResultContents from './directionsResult/ResultContents';

/**
 * DirectionsResult
 * - this UI helps populate map directions search results
 */
const DirectionsResult = ({ instance = '' }) => {
  const isMobile = useMediaQuery();
  const shouldRenderInstance = isInstanceMatching(isMobile, instance);
  const { map, dispatch } = useContext(MapContext);
  const { directions, directionsResult } = map;
  const { from, to, mode, options } = directions;
  const { viewState } = directionsResult;
  const refGetDirectionsAPIParams = useRef({});

  // retrieve directions result from API and update to context store
  useDeepCompareEffect(() => {
    // skip if this instance not meant to be rendered
    if (!shouldRenderInstance) {
      return;
    }
    // skip if loading in progress
    if (viewState === DIRECTIONS_RESULT_VIEW_STATES.LOADING) {
      return;
    }
    // skip if any search param data is invalid
    // - only checks 'from' and 'to', others all have default values
    if (!isPointValidForDirections(from) || !isPointValidForDirections(to)) {
      return;
    }

    // prepare API call params
    const fromParam = transformPointToApiParam(from);
    const toParam = transformPointToApiParam(to);

    // skip if transformed location data is invalid
    if (!fromParam || !toParam) {
      return;
    }

    // prepare all directions result API params
    const optionsParam = transformOptionsToApiParam(mode, options);
    const allParams = {
      from: fromParam,
      to: toParam,
      options: optionsParam,
    };

    // skip if API call params are the same
    // - so result should also be the same, NO need for API call
    // - removing this will most likely cause infinite loop
    const shouldSkipAPICall = _.isEqual(
      allParams,
      refGetDirectionsAPIParams.current,
    );
    if (shouldSkipAPICall) {
      return;
    } else {
      refGetDirectionsAPIParams.current = allParams;
    }

    // finally, make the API call and update directions results states
    mw.setMapRoute({
      ...allParams,
      dispatch,
      cb: getSetMapRouteDataCallback({
        ...allParams,
        dispatch,
      }),
    });
  }, [viewState, shouldRenderInstance, dispatch, from, to, mode, options]);

  // no render if instance is not matching
  if (!shouldRenderInstance) {
    return null;
  }

  // Conditional Rendering based on View States
  switch (viewState) {
    // loading
    case DIRECTIONS_RESULT_VIEW_STATES.LOADING:
      return <ResultLoading instance={instance} />;

    // error
    case DIRECTIONS_RESULT_VIEW_STATES.ERROR:
      return (
        <ResultError resultData={map.directionsResult} instance={instance} />
      );

    // results
    case DIRECTIONS_RESULT_VIEW_STATES.ROUTE_LOADED:
    case DIRECTIONS_RESULT_VIEW_STATES.DETAILS_LOADED:
      return (
        <ResultContents
          mode={mode}
          resultData={map.directionsResult}
          instance={instance}
        />
      );

    // no render
    case DIRECTIONS_RESULT_VIEW_STATES.NEVER_LOADED:
    default:
      return null;
  }
};

export default DirectionsResult;
