import React from 'react';
import IconWrapper, { Icons } from '../icons/Icons';
import s from './IconBtnBack.module.scss';

const IconBtnBack = ({
  label = 'Back',
  isRoundBtn = false,
  isDisabled = false,
  onClickHandler = null,
}) => {
  return (
    <button
      className={s.BtnBack}
      title={label}
      aria-label={label}
      onClick={onClickHandler}
      data-is-round={isRoundBtn}
      disabled={isDisabled}
    >
      <IconWrapper>{Icons.Arrow()}</IconWrapper>
    </button>
  );
};

export default IconBtnBack;
