import { deepEqualObj } from '../utils/utils';

const updateCampus = (state, action) => {
  const campusId = action.payload.id || state.campus.id;
  return {
    ...state,
    campus: {
      ...state.campus,
      key: action.payload.campusKey ||  state.campus.key,
      id: campusId ||  state.campus.id,
      name: action.payload.name || state.campus.name,
    },
  };
};

const updateCampusCategories = (state, action) => {
  const categories = action.payload.categories;
  const prevCategories = state.campus.categories;
  const hasCategoriesChanged = !deepEqualObj(prevCategories, categories);

  console.debug('[hasCategoriesChanged]:', hasCategoriesChanged);
  return hasCategoriesChanged
    ? {
        ...state,
        campus: {
          ...state.campus,
          categories: {
            ...state.campus.categories,
            ...categories,
          },
        },
      }
    : state;
};

const campusReducers = {
  updateCampus,
  updateCampusCategories,
};
export default campusReducers;
