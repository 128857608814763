import {
  BOTTOM_SHEET_VIEW_STATE,
  OVERLAY_VIEW_STATE,
  SEARCH_CONTROL,
  SEARCH_DISPLAY,
} from '../constants/constants';
import {
  DEFAULT_DIRECTIONS_STATE,
  DEFAULT_DIRECTIONS_RESULT_STATE,
  DEFAULT_SEARCH_DISPLAY_STATE,
  DEFAULT_SEARCH_RESULTS_STATE,
} from '../contexts/MapContext';
import mw from '../utils/maps';
import { updateDeepLinkFromApp } from '../utils/deepLink';

const updateUI = (state, action) => {
  const { store } = mw.getMap();
  // sync search UI state to map store as well
  store.uiSearchControl = action.payload.searchControl;

  return {
    ...state,
    ui: {
      ...state.ui,
      ...action.payload,
    },
  };
};

const updateUIBottomSheet = (state, action) => {
  // need to manually call map resize here as this affects map canvas size
  mw.resizeMap();

  const isBottomSheetHidden =
    action.payload.bottomSheetState === BOTTOM_SHEET_VIEW_STATE.HIDDEN;

  return {
    ...state,
    ui: {
      ...state.ui,
      bottomSheetState: action.payload.bottomSheetState,
      overlayState: isBottomSheetHidden
        ? state.ui.overlayState
        : OVERLAY_VIEW_STATE.HIDDEN,
    },
  };
};

const updateUIOverlay = (state, action) => {
  return {
    ...state,
    ui: {
      ...state.ui,
      overlayState: action.payload.overlayState,
    },
  };
};

const updateUISearchControl = (state, action) => {
  // UI logic conditions
  const isBackToInitialSearch =
    action.payload.searchControl === SEARCH_CONTROL.SEARCH;
  const isNotBackToDirectionsSearch =
    action.payload.searchControl !== SEARCH_CONTROL.DIRECTIONS;

  const hasMixedSearchResults =
    Array.isArray(state.searchResults.results) &&
    !!state.searchResults.results.length;

  const showSearchOverlay = isBackToInitialSearch && hasMixedSearchResults;

  // prepare conditional payloads
  const directionsPayload = isBackToInitialSearch
    ? {
        directions: {
          ...DEFAULT_DIRECTIONS_STATE, // reset search control
        },
        directionsResult: {
          ...DEFAULT_DIRECTIONS_RESULT_STATE, // reset search results
        },
      }
    : {};
  const searchResultsPayload = isBackToInitialSearch
    ? {
        searchResults: {
          ...state.searchResults, // keep search results
          selectedPoi: {
            ...DEFAULT_SEARCH_RESULTS_STATE.selectedPoi, // reset selected POI data
          },
          selectedCategory: {
            ...DEFAULT_SEARCH_RESULTS_STATE.selectedCategory, // reset selected category data
          },
        },
        searchDisplay: {
          ...DEFAULT_SEARCH_DISPLAY_STATE, // reset search display
          component: hasMixedSearchResults // still display mixed search when having results
            ? SEARCH_DISPLAY.SEARCH_MIXED
            : DEFAULT_SEARCH_DISPLAY_STATE.component,
        },
      }
    : {};

  // Map operations
  const { store } = mw.getMap();
  // clear directions point popup
  if (isNotBackToDirectionsSearch) {
    mw.clearRouteSearchPointsPopup();
  }
  // sync search UI state to map store as well
  store.uiSearchControl = action.payload.searchControl;
  // update map store as well for deep link
  store.routeSearchPointFrom = null;
  store.routeSearchPointTo = null;
  if (isBackToInitialSearch) {
    mw.clearSelectedPoiValues();
    mw.clearSelectedPoiTypeValues();
    mw.clearClickMarker();
  }
  updateDeepLinkFromApp({
    dataCaller: `searchControlUpdate:${action.payload.searchControl}`,
  });

  return {
    ...state,
    ui: {
      bottomSheetState: BOTTOM_SHEET_VIEW_STATE.HIDDEN,
      overlayState: showSearchOverlay
        ? OVERLAY_VIEW_STATE.VISIBLE
        : OVERLAY_VIEW_STATE.HIDDEN,
      searchControl: action.payload.searchControl,
    },
    ...directionsPayload,
    ...searchResultsPayload,
  };
};

const uiReducers = {
  updateUI,
  updateUIBottomSheet,
  updateUIOverlay,
  updateUISearchControl,
};
export default uiReducers;
