import * as _ from 'lodash-es';
import { isNumber } from './utils/number';
import { isObject } from './utils/utils';
import { isStringEmpty } from './utils/string';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from './hooks/useMediaQuery';
import { MAP_ACTIONS } from './reducers/mapReducer';
import { MapContext } from './contexts/MapContext';
import FEATURES from './constants/features';
import {
  MM_LAYERS,
  SEARCH_CONTROL,
  SEARCH_DISPLAY,
} from './constants/constants';
import mw from './utils/maps';
import {
  applyHashDeepLinkFromSearch,
  getHashValuesObject,
  hasLocationHash,
  updateAppFromDeepLink,
  updateDeepLinkFromApp,
} from './utils/deepLink';
import { updateGeolocateControlFitBoundsOptions } from './utils/geolocate';
import { initRouteSearchPointsPopup } from './App.utils';
import {
  initZLevelUpdateListener,
  updateZLevelControlOptions,
  updateZLevelUpdaterOptions,
} from './utils/maps.controls';
import IconWrapper, { Icons } from './components/icons/Icons';
import PanelDrawer from './components/panels/PanelDrawer';
import PanelMap from './components/panels/PanelMap';
import SearchControl from './components/search/SearchControl';
import MapboxGLButtonControl from './components/buttons/MapboxGLButtonControl';
import DirectionsSearch from './components/directions/DirectionsSearch';
import DirectionsResult from './components/directions/DirectionsResult';
import SearchResults from './components/search/SearchResults';
import PoiDetails from './components/search/PoiDetails';
import CategoryDetails from './components/search/CategoryDetails';

import './App.scss';

export const CLASS_FULLSCREEN = 'fs';

function App() {
  const [fullScreen, setFullScreen] = useState(false);
  const isMobile = useMediaQuery();
  const mapContainer = useRef(null);
  const { map: mapState, dispatch } = useContext(MapContext);
  const { mapLoaded } = mapState;
  const { searchControl } = mapState.ui;

  useEffect(() => {
    const map = mw.initMap(mapContainer.current, dispatch, {
      onLoadCallback: async (loaded) => {
        // check and apply search deep link
        await applyHashDeepLinkFromSearch();

        // update zLevelControl with customised active colour
        // Note: no longer using CSS overrides for v2.0.94
        updateZLevelControlOptions();

        // maze map default floor control change handler
        updateZLevelUpdaterOptions();
        initZLevelUpdateListener();

        // initial hash deep link update
        if (hasLocationHash()) {
          updateAppFromDeepLink(dispatch).then(() => {
            window.location.hash += '&deepLink=1';
            mw.updateCampusData(
              {
                id: getHashValuesObject().campusid,
              },
              dispatch,
            );
          });
        } else {
          updateDeepLinkFromApp({ dataCaller: 'appOnMapLoaded' });
        }

        // global update: map ready
        dispatch({
          type: MAP_ACTIONS.MAP_LOADED,
          payload: {
            mapLoaded: loaded,
            monashMap: map,
          },
        });

        map.on('moveend', (e) => {
          if (window.location.hash.indexOf('deepLink') < 0) {
            updateDeepLinkFromApp({ dataCaller: 'appOnMapMoveEnd' });
          }
        });
      },
      onClickCallback: async ({ e, lngLat, poi, mapClickResults }) => {
        const { store } = mw.getMap();
        const isDirectionsUI =
          store.uiSearchControl === SEARCH_CONTROL.DIRECTIONS;

        // check map click result features
        const hasBuildingLabelFeat =
          isObject(mapClickResults) &&
          isObject(mapClickResults.buildingLabel) &&
          isNumber(mapClickResults.buildingLabel.id);

        // Step 1: collate final data
        // check and get real building POI data if clicked on building labels
        // excluding directions search UI mode
        if (hasBuildingLabelFeat && !isDirectionsUI) {
          const buildingIdPoi = await window.Mazemap.Data.getBuildingPoiJSON(
            mapClickResults.buildingLabel.id,
          );
          const buildingIdPoiId = _.get(buildingIdPoi, 'poiId', null);
          if (isNumber(buildingIdPoiId)) {
            const realBuildingPoi = await window.Mazemap.Data.getPoi(
              buildingIdPoiId,
            );
            // replace POI data with map click building label feature
            poi = {
              ...realBuildingPoi,
              'feat-layer-id': MM_LAYERS.BUILDING_LABEL,
            };
          }
        }

        // Step 2: pass on final data and render
        const mapClickEventData = { e, lngLat, poi };

        // global update: map area click with data
        dispatch({
          type: MAP_ACTIONS.MAP_CLICK,
          payload: {
            mapClickEventData,
          },
        });

        // If in directions search UI:
        if (isDirectionsUI) {
          // launch route popup
          initRouteSearchPointsPopup({ mapClickEventData, dispatch });
        } else {
          // otherwise, add marker and highlight
          mw.onMapAreaClick({ lngLat, poi }, dispatch);
        }
      },
      onMoveEndCallback: null,
      onIdleCallback: () => {
        console.debug(
          '[onIdleCallback]: updateGeolocateControlFitBoundsOptions',
        );
        updateGeolocateControlFitBoundsOptions();

        console.debug('[onIdleCallback]: updateCampusData');
        if (window.location.hash.indexOf('deepLink') < 0) {
          // gets the campus from the map as this is used on the search (mw.getLatestCampusId)
          const latestId = mw.getLatestCampusId();
          if (latestId !== null) {
            console.debug('[onIdleCallback]: updateCampusData', latestId);
            mw.updateCampusData(
              {
                id: latestId,
              },
              dispatch,
            );
          }
        }
      },
    });

    const ctrlBoundary = new MapboxGLButtonControl({
      className: 'mapboxgl-ctrl-fullscreen',
      title: 'Full screen',
      eventHandler: function (event) {
        this.classList.toggle('mapboxgl-ctrl-shrink');
        toggleFullscreen();
      },
    });
    map.addControl(ctrlBoundary, 'top-right');

    // cleanup func
    return () => {
      map.remove();
    };
    // eslint-disable-next-line
  }, [dispatch]);

  // Z level control max height
  useEffect(() => {
    mapLoaded && mw.adjustZLevelControlMaxHeight();
  }, [mapLoaded, isMobile]);

  useEffect(() => {
    switch (searchControl) {
      // initial search control
      case SEARCH_CONTROL.SEARCH:
        mw.clearMapRoutePath();
        break;

      // directions search control
      case SEARCH_CONTROL.DIRECTIONS:
        break;

      default:
    }
  }, [searchControl]);

  // Fullscreen
  const fsClassName = fullScreen
    ? isMobile && !FEATURES.MOBILE_FULLSCREEN_ALLOWED
      ? ''
      : CLASS_FULLSCREEN
    : '';
  const toggleFullscreen = () => {
    if (isMobile && !FEATURES.MOBILE_FULLSCREEN_ALLOWED) {
      return;
    }

    setFullScreen((prev) => {
      setTimeout(() => {
        const { map } = mw.getMap();
        map.resize();
      }, 10);
      return !prev;
    });
  };

  useEffect(() => {
    const htmlEl = document.querySelector('html');
    if (htmlEl) {
      htmlEl.setAttribute(
        'data-mobile-fs-allowed',
        `${FEATURES.MOBILE_FULLSCREEN_ALLOWED}`,
      );
    }
  }, []);

  // outside of React app, manually toggle
  useEffect(() => {
    const htmlEl = document.querySelector('html');
    const pageWrapperEl = document.querySelector('#page-wrapper');
    if (htmlEl && pageWrapperEl) {
      if (isStringEmpty(fsClassName)) {
        htmlEl.classList.remove(CLASS_FULLSCREEN);
        pageWrapperEl.classList.remove(CLASS_FULLSCREEN);
      } else {
        htmlEl.classList.add(CLASS_FULLSCREEN);
        pageWrapperEl.classList.add(CLASS_FULLSCREEN);
      }
      setTimeout(() => {
        const { map } = mw.getMap();
        map.resize();
      }, 10);
    }
  }, [fsClassName]);

  // return before map content components based on search group conditions
  const getBeforeMapContents = () => {
    switch (searchControl) {
      // initial search control
      case SEARCH_CONTROL.SEARCH:
        return null;

      // directions search control
      case SEARCH_CONTROL.DIRECTIONS:
        return <DirectionsSearch instance="mobile" />;

      default:
        return null;
    }
  };

  // return after map content components based on search group conditions
  const getAfterMapContents = () => {
    const isPoiDetails =
      SEARCH_DISPLAY.SEARCH_RESULT_POI_DETAIL ===
      mapState.searchDisplay.component;
    const isCategoryDetails =
      SEARCH_DISPLAY.SEARCH_RESULT_CATEGORY_DETAIL ===
      mapState.searchDisplay.component;

    switch (searchControl) {
      // initial search control
      case SEARCH_CONTROL.SEARCH:
        // Put a shared component called search result and use instance = mobile then use show/hide into dom
        return (
          <>
            {isPoiDetails && <PoiDetails instance="mobile" />}
            {isCategoryDetails && <CategoryDetails instance="mobile" />}
          </>
        );

      // directions search control
      case SEARCH_CONTROL.DIRECTIONS:
        return <DirectionsResult instance="mobile" />;

      default:
        return null;
    }
  };

  // return overlay content components based on conditions
  const getOverlayContents = () => {
    const isSearchResults =
      SEARCH_DISPLAY.SEARCH_MIXED === mapState.searchDisplay.component;

    switch (searchControl) {
      // initial search control
      case SEARCH_CONTROL.SEARCH:
        // Put a shared component called search result and use instance = mobile then use show/hide into dom
        return <>{isSearchResults && <SearchResults instance="mobile" />}</>;

      default:
        return null;
    }
  };

  return (
    <div
      className="app-way-finding"
      data-media-bp={isMobile ? 'mobile' : 'desktop'}
    >
      {FEATURES.MOBILE_FULLSCREEN_ALLOWED && (
        <button className={`mobile-fs-button`} onClick={toggleFullscreen}>
          {fullScreen ? (
            <IconWrapper>{Icons.FsOff()}</IconWrapper>
          ) : (
            <IconWrapper>{Icons.FsOn()}</IconWrapper>
          )}
          {fullScreen ? 'Close' : 'Open'} map full screen
        </button>
      )}
      <section id="mapContainer" className={`map-container ${fsClassName}`}>
        {/* drawer panel */}
        <PanelDrawer>
          <SearchControl hasMapLoaded={mapLoaded} />
        </PanelDrawer>

        {/* map panel */}
        <PanelMap
          className={`panel-map ${fsClassName}`}
          mapArea={
            <div
              className="panel-map-area"
              id="mapRoot"
              ref={mapContainer}
              data-area="map"
            />
          }
          beforeMap={getBeforeMapContents()}
          afterMap={getAfterMapContents()}
          overlay={getOverlayContents()}
        />
      </section>
    </div>
  );
}

export default App;
