/**
 * isStringEmpty
 * @param str
 * @returns {boolean}
 */
export const isStringEmpty = (str = '') => {
  // return true for invalid strings
  if (typeof str !== 'string') {
    return true;
  }
  return !str.trim().length;
};

/**
 * convertHtmlStringToText
 * @param str
 * @returns {string}
 */
export const convertHtmlStringToText = (str = '') => {
  return str.replace(/<[^>]*>?/gm, '');
};
