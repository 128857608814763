import * as _ from 'lodash-es';
import React, { useContext } from 'react';
import mw from '../../utils/maps';
import { isMediaQuery } from '../../hooks/useMediaQuery';
import { MapContext } from '../../contexts/MapContext';
import { BACK_BTN_TARGET } from '../../constants/constants';
import { displayMixedSearchOverlay } from './SearchControlInputField';
import { displayDefaultCategorySearch } from './SeachControlClearButton';
import IconBtnBack from '../buttons/IconBtnBack';

import s from './SearchControl.module.scss';

const SearchControlBackButton = () => {
  const { map, dispatch } = useContext(MapContext);

  // get mixed search results data
  const searchResults = _.get(map, 'searchResults.results', null);
  const hasMixedSearchResults =
    Array.isArray(searchResults) && !!searchResults.length;

  // view flags
  const backBtnTarget = _.get(map, 'searchDisplay.backBtnTarget');
  const shouldDisplayBackButton = backBtnTarget !== BACK_BTN_TARGET.NONE;
  const isTargetCategoryDetails =
    backBtnTarget === BACK_BTN_TARGET.CATEGORY_DETAILS;
  const isTargetMixedSearchResult =
    backBtnTarget === BACK_BTN_TARGET.MIXED_SEARCH_RESULT &&
    hasMixedSearchResults;
  const isTargetDefaultCategorySearch =
    backBtnTarget === BACK_BTN_TARGET.MIXED_SEARCH_RESULT &&
    !hasMixedSearchResults;

  const btnLabel = `Back${
    isTargetCategoryDetails
      ? ' to category results'
      : isTargetMixedSearchResult
      ? ' to keyword search results'
      : isTargetDefaultCategorySearch
      ? ' to campus category search'
      : ''
  }`;

  // handlers
  const handleSearchBackClick = (e) => {
    e.preventDefault();
    if (isTargetCategoryDetails) {
      const selectedCategory = _.get(map, 'searchResults.selectedCategory');
      mw.loadTypePoisForCampus(
        {
          poiTypeTitle: selectedCategory.typePoisTitle,
          poiTypeId: `${selectedCategory.typePoisId}`, // string,
          campusId: selectedCategory.campusId,
          customProps: {
            dataTrigger: 'searchControlBackBtn',
            dataIsMobile: isMediaQuery(),
          },
        },
        dispatch,
      );
    } else if (isTargetMixedSearchResult) {
      displayMixedSearchOverlay({
        map,
        dispatch,
      });
    } else if (isTargetDefaultCategorySearch) {
      displayDefaultCategorySearch({
        dispatch,
      });
    }
  };

  return shouldDisplayBackButton ? (
    <div className={s.IconBtnBackWrapper}>
      <IconBtnBack
        label={btnLabel}
        isRoundBtn={true}
        onClickHandler={handleSearchBackClick}
      />
    </div>
  ) : null;
};

export default SearchControlBackButton;
