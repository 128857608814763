import React, { useContext, useEffect, useRef } from 'react';
import { BOTTOM_SHEET_VIEW_STATE } from '../../constants/constants';
import { MapContext } from '../../contexts/MapContext';
import { UI_ACTIONS } from '../../reducers/mapReducer';
import IconWrapper, { Icons } from '../icons/Icons';
import ReportIssueCTA from '../buttons/ReportIssueCTA';

import s from './BottomSheet.module.scss';

/**
 * isBottomSheetToggleAllowed
 * @param state
 */
export const isBottomSheetToggleAllowed = (state) => {
  return (
    state === BOTTOM_SHEET_VIEW_STATE.EXPANDED ||
    state === BOTTOM_SHEET_VIEW_STATE.COLLAPSED
  );
};

/**
 * BottomSheet
 * - acting as a shared parent container for contents within a bottom sheet design
 * - intended to be used for bottom sheet UI contents in mobile map view areas
 * - renders children inside the bottom sheet wrapper container
 * - has built in expand/collapse functionality
 */
const BottomSheet = ({ reportIssueEnabled = true, children }) => {
  const { map, dispatch } = useContext(MapContext);
  const { bottomSheetState } = map.ui;
  const isBottomSheetExpanded =
    bottomSheetState === BOTTOM_SHEET_VIEW_STATE.EXPANDED;
  const contentNodeId = 'bottomSheetContent';
  const refContentContainer = useRef(null);

  useEffect(() => {
    // make sure content container scrolls to top upon toggle state changes
    if (refContentContainer.current !== null) {
      refContentContainer.current.scrollTop = 0;
    }
  }, [bottomSheetState, refContentContainer]);

  // btn toggle handler
  // toggles bottom sheet expanded state
  const btnToggleClickHandler = (e) => {
    e.preventDefault();

    if (
      isBottomSheetToggleAllowed(bottomSheetState) &&
      typeof dispatch === 'function'
    ) {
      // toggle expanded state
      const newState = isBottomSheetExpanded
        ? BOTTOM_SHEET_VIEW_STATE.COLLAPSED
        : BOTTOM_SHEET_VIEW_STATE.EXPANDED;

      // update UI
      dispatch({
        type: UI_ACTIONS.UPDATE_UI_BOTTOM_SHEET,
        payload: {
          bottomSheetState: newState,
        },
      });
    }
  };

  // display flags
  const displayFooter = isBottomSheetExpanded && reportIssueEnabled;

  return (
    <div className={s.BottomSheet} data-state={bottomSheetState.toLowerCase()}>
      <div className={s.Header}>
        <button
          className={s.BtnToggle}
          aria-label="toggle bottom sheet content"
          aria-controls={contentNodeId}
          aria-expanded={isBottomSheetExpanded}
          onClick={btnToggleClickHandler}
        >
          <IconWrapper>{Icons.ArrowFat()}</IconWrapper>
        </button>
      </div>
      <div id={contentNodeId} className={s.Content} ref={refContentContainer}>
        {children}
      </div>
      {displayFooter && (
        <div className={s.Footer}>
          {reportIssueEnabled && <ReportIssueCTA />}
        </div>
      )}
    </div>
  );
};

export default BottomSheet;
