import mapReducers from './mapReducer.map';
import uiReducers from './mapReducer.ui';
import campusReducers from './mapReducer.campus';
import directionReducers from './mapReducer.directions';
import directionResultReducers from './mapReducer.directionsResults';
import searchResultsReducers from './mapReducer.searchResults';
import categoriesReducers from './mapReducer.categories';

export const MAP_ACTIONS = {
  MAP_CLICK: 'MAP_CLICK',
  MAP_LOADED: 'MAP_LOADED',
  MAP_GEOLOCATE: 'MAP_GEOLOCATE',
  UPDATE_CAMPUS: 'UPDATE_CAMPUS',
  UPDATE_CAMPUS_CATEGORIES: 'UPDATE_CAMPUS_CATEGORIES',
  UPDATE_CATEGORIES_ICON_MAP: 'UPDATE_CATEGORIES_ICON_MAP',
  UPDATE_DIRECTION: 'UPDATE_DIRECTION',
  UPDATE_DIRECTION_RESULT: 'UPDATE_DIRECTION_RESULT',
  UPDATE_SEARCH_RESULT: 'UPDATE_SEARCH_RESULT',
  UPDATE_DIRECTION_POINT: 'UPDATE_DIRECTION_POINT',
  UPDATE_DIRECTION_POINT_FROM: 'UPDATE_DIRECTION_POINT_FROM',
  UPDATE_DIRECTION_POINT_TO: 'UPDATE_DIRECTION_POINT_TO',
  SEARCH_POI_SELECTED: 'SEARCH_POI_SELECTED',
  SEARCH_CATEGORY_SELECTED: 'SEARCH_CATEGORY_SELECTED',
  UPDATE_SEARCH_RESULT_QUERY: 'UPDATE_SEARCH_RESULT_QUERY',
};

export const UI_ACTIONS = {
  UPDATE_UI: 'UPDATE_UI',
  UPDATE_UI_BOTTOM_SHEET: 'UPDATE_UI_BOTTOM_SHEET',
  UPDATE_UI_OVERLAY: 'UPDATE_UI_OVERLAY',
  UPDATE_UI_SEARCH_CONTROL: 'UPDATE_UI_SEARCH_CONTROL',
  UPDATE_UI_SEARCH_DISPLAY: 'UPDATE_UI_SEARCH_DISPLAY',
  RESET_MAP_SEARCH_CONTEXT_TO_INITIAL_STATE:
    'RESET_MAP_SEARCH_CONTEXT_TO_INITIAL_STATE',
  RESET_MAP_SEARCH_CONTEXT_TO_INITIAL_STATE_FOR_MOBILE_CATEGORY_SELECTION:
    'RESET_MAP_SEARCH_CONTEXT_TO_INITIAL_STATE_FOR_MOBILE_CATEGORY_SELECTION',
};

/**
 * mapReducer
 * @param state
 * @param action
 * @returns object
 */
export const mapReducer = (state, action) => {
  console.debug('[mapReducer]:', action.type, action.payload);
  switch (action.type) {
    /**
     * Map
     */
    case MAP_ACTIONS.MAP_LOADED:
      return mapReducers.updateMapLoaded(state, action);
    case MAP_ACTIONS.MAP_CLICK:
      return mapReducers.updateMapClick(state, action);
    case MAP_ACTIONS.MAP_GEOLOCATE:
      return mapReducers.updateMapGeolocate(state, action);

    /**
     * UI
     */
    case UI_ACTIONS.UPDATE_UI:
      return uiReducers.updateUI(state, action);
    case UI_ACTIONS.UPDATE_UI_BOTTOM_SHEET:
      return uiReducers.updateUIBottomSheet(state, action);
    case UI_ACTIONS.UPDATE_UI_OVERLAY:
      return uiReducers.updateUIOverlay(state, action);
    case UI_ACTIONS.UPDATE_UI_SEARCH_CONTROL:
      return uiReducers.updateUISearchControl(state, action);

    /**
     * Campus
     */
    case MAP_ACTIONS.UPDATE_CAMPUS:
      return campusReducers.updateCampus(state, action);
    case MAP_ACTIONS.UPDATE_CAMPUS_CATEGORIES:
      return campusReducers.updateCampusCategories(state, action);

    /**
     * Categories
     */
    case MAP_ACTIONS.UPDATE_CATEGORIES_ICON_MAP:
      return categoriesReducers.updateCategoriesIconMap(state, action);

    /**
     * Directions Search
     */
    case MAP_ACTIONS.UPDATE_DIRECTION:
      return directionReducers.updateDirection(state, action);
    case MAP_ACTIONS.UPDATE_DIRECTION_POINT:
      return directionReducers.updateDirectionPoint(state, action);
    case MAP_ACTIONS.UPDATE_DIRECTION_POINT_FROM:
      return directionReducers.updateDirectionPointFrom(state, action);
    case MAP_ACTIONS.UPDATE_DIRECTION_POINT_TO:
      return directionReducers.updateDirectionPointTo(state, action);

    /**
     * Directions Results
     */
    case MAP_ACTIONS.UPDATE_DIRECTION_RESULT:
      return directionResultReducers.updateDirectionResult(state, action);

    /**
     * Search Results
     */
    case MAP_ACTIONS.UPDATE_SEARCH_RESULT:
      return searchResultsReducers.updateSearchResult(state, action);

    /**
     * Poi is selected
     */
    case MAP_ACTIONS.SEARCH_POI_SELECTED:
      return searchResultsReducers.searchPoiSelected(state, action);

    /**
     * Category is selected
     */
    case MAP_ACTIONS.SEARCH_CATEGORY_SELECTED:
      return searchResultsReducers.searchCategorySelected(state, action);

    /**
     * Search content display update
     */
    case UI_ACTIONS.UPDATE_UI_SEARCH_DISPLAY:
      return searchResultsReducers.updateUiSearchDisplay(state, action);

    /**
     * Update user-entered query
     */
    case MAP_ACTIONS.UPDATE_SEARCH_RESULT_QUERY:
      return searchResultsReducers.updateSearchResultQuery(state, action);

    /**
     * Reset the Search Results screen to default/initial state
     */
    case UI_ACTIONS.RESET_MAP_SEARCH_CONTEXT_TO_INITIAL_STATE:
      return searchResultsReducers.resetMapSearchContextToInitialState(
        state,
        action,
      );

    case UI_ACTIONS.RESET_MAP_SEARCH_CONTEXT_TO_INITIAL_STATE_FOR_MOBILE_CATEGORY_SELECTION:
      return searchResultsReducers.resetMapSearchContextForMobileCategorySelection(
        state,
      );

    default:
      return state;
  }
};
