const updateDirectionResult = (state, action) => {
  return {
    ...state,
    directionsResult: {
      ...state.directionsResult,
      ...action.payload,
    },
  };
};

const directionResultReducers = {
  updateDirectionResult,
};
export default directionResultReducers;
