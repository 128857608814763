import { isStringEmpty } from '../utils/string';

// constants
const BASE_URL = '/splunk/s';

const TAG_SEARCH = 'da-1_searched';
const TAG_LOCATION_ACTIVATION = 'da-2_blue_dot'; // current location is activated return lng/lat.
const TAG_POINT_TO_POINT_ROUTING = 'da-4_point_A_point_B_route';
const TAG_SEARCH_CATEGORY_SELECTED = 'da-7_poi_categories_selected'; // campus category (not search result).
const TAG_SEARCH_RESULT_SELECTED = 'da-8_search_result_selected'; // search result with poi information.

const WHITE_LISTED_DOMAINS = [
  'monash-digital-wayfinding-uat.appspot.com',
  'monash-digital-wayfinding-prod.appspot.com',
  'maps.monash.edu',
];

// Utils
// Note: Covered by data collection statement, no longer requires GDPR consents to collect BI data.
// const isAllowToPush = () => {
//   if (window?.utag?.gdpr?.getConsentState) {
//     const consentState = window.utag.gdpr.getConsentState();
//     if (consentState && Array.isArray(consentState) && consentState !== 0) {
//       // has consent to something
//       return true;
//     }
//   }
//   console.warn('[BIData]: Consent not found or given.');
//   return false;
// };

const isDomainWhitelisted = (() => {
  const whitelistedDomains = WHITE_LISTED_DOMAINS.join('_');
  return whitelistedDomains.indexOf(window.location.hostname) !== -1;
})();

// API methods
const push = (tag, data, url = '') => {
  // Note: Tracking permission is covered by data collection statement, NOT GDPR consents
  console.debug('[BIData]:push', tag, data, url);

  
  if (tag === TAG_SEARCH && data?.query === 'synthetic'){ // WEBSP-453
    console.warn(
      '[BIData]:push: synthetic not permitted.',
    );
    return;    
  }

  if (!isDomainWhitelisted) {
    console.warn(
      '[BIData]:push: current domain is not whitelisted to submit data',
    );
    return;
  }

  const fetchBaseUrl = !isStringEmpty(url) ? url : BASE_URL;
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify({
      source: 'web',
      ...data,
    }),
  };

  fetch(`${fetchBaseUrl}/${tag}`, requestOptions)
    .then((response) => response.text())
    .then((result) => console.debug('📈', result))
    .catch((error) => console.warn('error', error));
};

// return module
const BIData = {
  // enum
  TAG_SEARCH,
  TAG_LOCATION_ACTIVATION,
  TAG_POINT_TO_POINT_ROUTING,
  TAG_SEARCH_CATEGORY_SELECTED,
  TAG_SEARCH_RESULT_SELECTED,

  // methods
  push,
};

export default BIData;
