import React from 'react';
import { TRAVEL_OPTIONS } from '../../../constants/constants';
import { isStringEmpty } from '../../../utils/string';
import FormCheckbox from '../../form/FormCheckbox';

import s from './TravelOption.module.scss';

/**
 * getTravelOptionChangeHandler
 */
const getTravelOptionChangeHandler =
  (optionKey = '', callback = null) =>
  (e) => {
    // returns actual click handler fn here
    const isChecked = e.target.checked;
    if (
      typeof callback === 'function' &&
      typeof isChecked === 'boolean' &&
      !isStringEmpty(optionKey)
    ) {
      callback(optionKey, isChecked);
    }
  };

/**
 * TravelOption
 */
const TravelOption = ({
  optionKey = '',
  optionValue = false,
  updateTravelOptionsCallBack = null,
}) => {
  const optionData = TRAVEL_OPTIONS[optionKey] || {};
  const optionLabel = (optionData && optionData.label) || '';
  const shouldRenderOption =
    !isStringEmpty(optionLabel) && typeof optionValue === 'boolean';

  return shouldRenderOption ? (
    <div className={`travel-option-item ${s.TravelOption}`}>
      <FormCheckbox
        labelText={optionLabel}
        isChecked={optionValue}
        onChangeHandler={getTravelOptionChangeHandler(
          optionKey,
          updateTravelOptionsCallBack,
        )}
      />
    </div>
  ) : null;
};

export default TravelOption;
