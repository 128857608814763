import React from 'react';
import { isStringEmpty } from '../../../utils/string';

import s from './TravelInstructions.module.scss';

/**
 * TravelInstructions
 */
const TravelInstructions = ({ instructions = null }) => {
  if (!Array.isArray(instructions)) {
    return null;
  }

  const validatedData = instructions.filter((item) => {
    return !isStringEmpty(item);
  });
  const hasData = !!validatedData.length;

  return hasData ? (
    <div
      className={`travel-result-ins ${s.TravelInstructions}`}
      data-dir-result-sec="ins"
    >
      <ol>
        {validatedData.map((item, index) => {
          const id = `travel-result-ins-item${index + 1}`;
          const indexText = `${index + 1}.`;
          return (
            <li key={id} data-index-text={indexText}>
              {item.trim()}
            </li>
          );
        })}
      </ol>
    </div>
  ) : null;
};

export default TravelInstructions;
