import * as _ from 'lodash-es';
import { isNumber } from '../../utils/number';
import { isObject } from '../../utils/utils';
import { computeGeoDistanceInKmBetween } from '../../utils/distance';
import {
  isGeolocateActive,
  isGeolocateBackground,
} from '../../utils/geolocate';

export const SEARCH_RESULT_ITEM_TYPE = {
  POI: 'poi',
  CATEGORY: 'category',
  BUILDING: 'building',
};

export const isBuildingId = (id = null) => {
  // TODO: try to find out new pattern for building id if any,
  // Note: new building ids breaks the 4 digit length pattern: e.g. 1000202
  return isNumber(id);
};

export const isSearchItemTypePoi = (item = {}) => {
  const { type } = item;
  return type === SEARCH_RESULT_ITEM_TYPE.POI;
};

export const isSearchItemTypeCategory = (item = {}) => {
  const { type } = item;
  return type === SEARCH_RESULT_ITEM_TYPE.CATEGORY;
};

export const isSearchItemTypeBuilding = (item = {}) => {
  const { type, resultId } = item;
  return type === SEARCH_RESULT_ITEM_TYPE.BUILDING && isBuildingId(resultId);
};

/**
 * sortSearchResults
 * @param results
 * @param data
 * @returns {*[]}
 */
export const sortSearchResults = (
  results = [],
  data = {
    shouldSortByDistanceToGeolocation: false,
    geolocatePosition: null,
  },
) => {
  if (!Array.isArray(results)) {
    return [];
  }

  // initial values, unsorted
  let sortedResults = [...results];

  // data
  const dataObj = isObject(data) ? { ...data } : {};
  const { shouldSortByDistanceToGeolocation, geolocatePosition } = dataObj;

  // calculate, add geolocation and sort
  if (
    shouldSortByDistanceToGeolocation &&
    isObject(geolocatePosition) &&
    (isGeolocateActive() || isGeolocateBackground())
  ) {
    sortedResults = sortedResults.map((result, index) => {
      const distanceToGeolocation = {
        km: computeGeoDistanceInKmBetween(
          _.get(geolocatePosition, 'coords.latitude', null),
          _.get(geolocatePosition, 'coords.longitude', null),
          result.coordinateLat,
          result.coordinateLng,
        ),
      };
      // TODO: validate 'distanceToGeolocation' if need to cater for current campus context
      // so we don't display distance when not within current campus
      return {
        ...result,
        originalIndex: index,
        sortBy: 'distanceToGeolocation',
        distanceToGeolocation,
      };
    });
    sortedResults = sortedResults.sort((resultA, resultB) => {
      const distA = _.get(resultA, 'distanceToGeolocation.km', null);
      const distB = _.get(resultB, 'distanceToGeolocation.km', null);
      if (isNumber(distA) && isNumber(distB)) {
        return distA - distB;
      } else {
        return 0;
      }
    });
    console.debug('[sortSearchResults]:by geolocation', sortedResults);
  } else {
    sortedResults = sortedResults.map((result, index) => {
      return {
        ...result,
        originalIndex: index,
        sortBy: '',
      };
    });
    console.debug('[sortSearchResults]:by nothing');
  }

  return sortedResults;
};
