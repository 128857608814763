import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode';

// Documentation: https://github.com/soldair/node-qrcode
const QRCodeDisplay = ({ dataString = '' }) => {
  // Example Usage: <QRCodeData data="https://goo.gl/maps/XCcf4TKmWZqX2qGY8" altText="Placeholder Link" customClassName="aClass" />
  const [data, setData] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(false);

    // use 'isMounted' to make sure setState NOT called after unmount
    let isMounted = true;
    QRCode.toDataURL(dataString)
      .then((data) => {
        isMounted && setData(data);
      })
      .catch((err) => {
        console.warn(err);
        isMounted && setError(true);
      });

    return () => {
      isMounted = false; // prevent setState being called
    };
  }, [dataString]);

  return !error ? (
    <img className="img-qr-code-share-display" src={data} alt="" />
  ) : null;
};

export default QRCodeDisplay;
