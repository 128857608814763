import * as _ from 'lodash-es';
import React, { useContext } from 'react';
import { MapContext } from '../../contexts/MapContext';
import { BACK_BTN_TARGET } from '../../constants/constants';

import s from './SearchControl.module.scss';

const SearchControlSearchIcon = () => {
  const { map } = useContext(MapContext);
  const backBtnTarget = _.get(map, 'searchDisplay.backBtnTarget');
  const shouldDisplaySearchIcon = backBtnTarget === BACK_BTN_TARGET.NONE;

  return shouldDisplaySearchIcon ? (
    <div className={s.IconSearch} aria-hidden="true" />
  ) : null;
};

export default SearchControlSearchIcon;
