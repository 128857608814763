import React from 'react';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import searchSecStyles from './SearchControlSection.module.scss';

const SearchControlSection = ({
  section = '',
  isHidden = false,
  header = null,
  footer = null,
  customMainStyles = null,
  children,
  mobilePassThrough = false,
}) => {
  // return children straight away without section wrapper
  // enable 'mobilePassThrough' prop when mobile and desktop share the same children content
  const isMobile = useMediaQuery();
  if (mobilePassThrough && isMobile) {
    return children;
  }

  const globalCompToken = 'search-ctrl-sec';
  const sectionId = `${globalCompToken}--${section}`.toLowerCase();

  const hasHeader = header !== null;
  const hasFooter = footer !== null;
  const hasMain = children !== null;

  const rootUIProps = {
    'data-has-header': hasHeader ? 'true' : 'false',
    'data-has-footer': hasFooter ? 'true' : 'false',
    'data-has-main': hasMain ? 'true' : 'false',
  };

  return (
    <section
      id={sectionId}
      className={`search-ctrl-sec ${searchSecStyles.SearchControlSection} ${
        isHidden ? 'hidden' : ''
      }`}
      {...rootUIProps}
    >
      {/* header */}
      {hasHeader && (
        <div
          className={`${globalCompToken}__header ${searchSecStyles.SectionHeader}`}
        >
          {header}
        </div>
      )}

      {/* main */}
      <div
        className={`${globalCompToken}__main ${searchSecStyles.SectionMain}`}
        style={{ ...customMainStyles }}
      >
        {children}
      </div>

      {/* footer */}
      {hasFooter && (
        <div
          className={`${globalCompToken}__footer ${searchSecStyles.SectionFooter}`}
        >
          {footer}
        </div>
      )}
    </section>
  );
};

export default SearchControlSection;
