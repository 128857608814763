import * as _ from 'lodash-es';
import React, { useContext } from 'react';
import { MapContext } from '../../contexts/MapContext';
import { isObject } from '../../utils/utils';
import { isStringEmpty } from '../../utils/string';
import PoiDetailsCTAs, { POI_DETAILS_TYPE } from './PoiDetailsCTAs';
import ShareQRCode from '../utils/ShareQRCode';
import { isPoiDetailsTypeBuilding } from './PoiDetails.utils';

import s from './PoiDetails.module.scss';

export const IMAGE_BASE_URL = 'https://api.mazemap.com/api/images/';

const PoiDetails = ({ instance = '' }) => {
  const { map } = useContext(MapContext);
  const poiDetails = _.get(
    map,
    'searchResults.selectedPoi.poiDetail.originalPoiDetails',
    null,
  );
  if (!isObject(poiDetails)) {
    return null;
  }

  // prep view models
  const isBuildingType = isPoiDetailsTypeBuilding(poiDetails);
  const floorText = poiDetails.hasZLevel ? `Floor ${poiDetails.floor}` : '';
  const buildingText = poiDetails.building || '';
  const campusText = poiDetails.campusName || '';
  const descriptionText = _.get(poiDetails, 'properties.description', '');
  const infoUrl = _.get(poiDetails, 'properties.infoUrl', '');
  const infoUrlText = _.get(poiDetails, 'properties.infoUrlText', '');
  const images = _.get(poiDetails, 'properties.images', []);
  const categoryTypes = _.get(poiDetails, 'properties.types', []);
  const hasCategoryTypes =
    Array.isArray(categoryTypes) && !!categoryTypes.length;
  const dispPoiNames = poiDetails.formattedDispPoiNames || '';
  const hasDispPoiNames = !isStringEmpty(dispPoiNames);
  const hasImages = Array.isArray(images) && images.length > 0;
  const fullImageUrls = images.map((img = {}, index) => {
    const { id = '', extension = '', name = '' } = img;
    if (isStringEmpty(id) || isStringEmpty(extension)) {
      return null;
    }
    return (
      <a
        key={`poi-details-image-link-${index + 1}`}
        className={s.PoiDetailsImageLink}
        target="_blank"
        rel="noreferrer"
        href={`${IMAGE_BASE_URL}${id}.original.${extension}`}
      >
        <img
          src={`${IMAGE_BASE_URL}${id}.w400.${extension}`}
          alt={name}
          loading="lazy"
        />
      </a>
    );
  });

  return (
    <div className={s.PoiDetails} data-instancce={instance}>
      <div className={s.PoiDetailsPrimary} data-test-id="poiDetail-primary">
        {poiDetails.title}
      </div>
      <div className={s.PoiDetailsSecondary} data-test-id="poiDetail-secondary">
        {floorText}
        {`${floorText && buildingText ? ', ' : ''}`}
        {buildingText}
        {`${buildingText && campusText ? ', ' : ''}`}
        {campusText}
        {hasCategoryTypes && (
          <span className={s.PoiDetailsTypes}>
            {categoryTypes.map((f) => f.name).join(', ')}
          </span>
        )}
        {hasDispPoiNames && (
          <span
            className={s.PoiDetailsDispPoiNames}
          >{`(${dispPoiNames})`}</span>
        )}
      </div>
      <PoiDetailsCTAs
        dataType={
          isBuildingType ? POI_DETAILS_TYPE.BUILDING : POI_DETAILS_TYPE.POI
        }
        shareQREnabled={false}
      />
      <ShareQRCode />
      <div className={s.PoiDetailsExtraContent}>
        {descriptionText && (
          <p className={`${s.PoiDetailsDesc} white-space--pre-wrap`}>
            {descriptionText}
          </p>
        )}
        {infoUrl && (
          <p className={s.PoiDetailsUrl}>
            <a href={infoUrl} target="_blank" rel="noreferrer">
              {isStringEmpty(infoUrlText) ? infoUrl : infoUrlText}
            </a>
          </p>
        )}
        {hasImages && <div className={s.PoiDetailsImages}>{fullImageUrls}</div>}
      </div>
    </div>
  );
};

export default PoiDetails;
