export const campusDataIds = [159, 410, 411, 412, 415];
export const isCampusDataAvailableForId = (campusId) => {
  return campusDataIds.indexOf(campusId) !== -1;
};
export const campusData = {
  clayton: {
    id: 159,
    name: 'Clayton',
    lng: 145.134226,
    lat: -37.911573,
    zoom: 15.79,
  },
  caulfield: {
    id: 410,
    name: 'Caulfield',
    lng: 145.044755,
    lat: -37.877144,
    zoom: 17.01,
  },
  parkville: {
    id: 412,
    name: 'Parkville',
    lng: 144.95897,
    lat: -37.783768,
    zoom: 17.49,
  },
  peninsula: {
    id: 411,
    name: 'Peninsula',
    lng: 145.136588,
    lat: -38.152776,
    zoom: 16.76,
  },
  "kuala lumpur": {
    id: 415,
    name: 'Kuala Lumpur',
    lng: 101.600257,
    lat: 3.063953,
    zoom: 16.33,
  },
};
