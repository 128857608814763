import * as _ from 'lodash-es';
import React, { useContext, useState } from 'react';
import { BOTTOM_SHEET_VIEW_STATE } from '../../../constants/constants';
import { MapContext } from '../../../contexts/MapContext';
import { UI_ACTIONS } from '../../../reducers/mapReducer';
import ShareMapDeepLink from '../../utils/ShareMapDeepLink';
import {
  isNavCtaAvailable,
  startDirectionsNavigation,
} from '../../../utils/geolocate';

import s from './TravelCTAs.module.scss';

/**
 * TravelCTAs
 */
const TravelCTAs = () => {
  const hasNavigationSupport = true;

  const { map, dispatch } = useContext(MapContext);
  const [showShareContent, setShowShareContent] = useState(false);
  const routeFrom = _.get(map, 'directions.from', null);
  const routeTo = _.get(map, 'directions.to', null);
  const isNavAvailable = isNavCtaAvailable({
    from: routeFrom,
    to: routeTo,
  });

  // handlers
  const handleBtnShareClick = (e) => {
    e.preventDefault();

    setShowShareContent(true);
    dispatch({
      type: UI_ACTIONS.UPDATE_UI_BOTTOM_SHEET,
      payload: {
        bottomSheetState: BOTTOM_SHEET_VIEW_STATE.EXPANDED,
      },
    });
  };
  const handleBtnStartNavClick = (e) => {
    e.preventDefault();
    if (!hasNavigationSupport || !isNavAvailable) {
      return false;
    }
    startDirectionsNavigation(dispatch);
  };

  return (
    <>
      <div className={s.TravelCTAs} data-dir-result-sec="cta">
        <button
          className="btnCTA"
          data-variant="secondary"
          onClick={handleBtnShareClick}
          disabled={showShareContent}
        >
          Share
        </button>
        {hasNavigationSupport && isNavAvailable && (
          <button
            className="btnCTA"
            data-variant="primary"
            onClick={handleBtnStartNavClick}
            disabled={!isNavAvailable}
          >
            Start navigation
          </button>
        )}
      </div>
      {/* share content */}
      {showShareContent && <ShareMapDeepLink />}
    </>
  );
};

export default TravelCTAs;
