import mw from './utils/maps';
import { isFunction, isObject } from './utils/utils';
import { getDirectionsPointData } from './utils/directions';

/**
 * initRouteSearchPointsPopup
 * @param mapClickEventData
 * @param dispatch
 */
export const initRouteSearchPointsPopup = ({ mapClickEventData, dispatch }) => {
  if (!isObject(mapClickEventData) || !isFunction(dispatch)) return;

  // mimic maze map demo, each click either adds a new popup, or dismisses an existing popup
  const { store } = mw.getMap();
  if (!!store.routeSearchPointsPopup) {
    mw.clearRouteSearchPointsPopup();
  } else {
    const point = getDirectionsPointData(mapClickEventData);
    mw.setRouteSearchPointsPopup({ point, dispatch });
  }
};
