import * as _ from 'lodash-es';
import React, { useEffect, useContext, useState } from 'react';
import Slider from 'react-slick';
import { MapContext } from '../../contexts/MapContext';
import mw from '../../utils/maps';
import { isCampusDataAvailableForId } from '../../utils/data/campusData';
import { isSearchTermValid } from '../search/SearchControlInputField';

import s from '../search/SearchControl.module.scss';

// slider view configs
const CAMPUS_SLIDER_CONFIGS = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 2,
  variableWidth: true,
};

export default function CampusSearchFilter({ instance = '' }) {
  const { map } = useContext(MapContext);
  const searchTerm = _.get(map, 'searchResults.query', '');
  // Note: 'mapCampusIdSelected', 'campusIdSelected' values here could be ad-hoc mazemap campus ids in some edge cases
  const mapCampusIdSelected = _.get(map, 'campus.id', null);
  const [campusIdSelected, setCampusIdSelected] = useState(mapCampusIdSelected);

  // apply map state updates to local UI state
  useEffect(() => {
    if (
      isSearchTermValid(searchTerm) &&
      isCampusDataAvailableForId(mapCampusIdSelected)
    ) {
      setCampusIdSelected(mapCampusIdSelected);
    }
  }, [searchTerm, mapCampusIdSelected]);

  // handler
  const getCampusOnChangeHandler = (campusName, campusId) => () => {
    if (isSearchTermValid(searchTerm) && isCampusDataAvailableForId(campusId)) {
      setCampusIdSelected(campusId);
      mw.jumpCampus(campusName);
    }
  };

  return (
    // campus filter scroller control
    <div
      className={s.CampusScroller}
      data-instance={instance}
      data-usage="search-result"
    >
      <Slider {...CAMPUS_SLIDER_CONFIGS}>
        {/* campus options */}
        {Object.keys(mw.CAMPUS_DETAILS).map(function (key) {
          const id = mw.CAMPUS_DETAILS[key].id;
          const htmlId = `campus-search-filter-${instance}-${id}`;
          const isDisabled = !isSearchTermValid(searchTerm);
          return (
            <div className={s.CampusPill} key={key}>
              <input
                className="visuallyhidden"
                key={id.toString()}
                id={htmlId}
                type="radio"
                name={`optCampusSearch${instance}`}
                value={id.toString()}
                checked={`${campusIdSelected}` === id.toString()}
                disabled={isDisabled}
                onChange={getCampusOnChangeHandler(key, id)}
                tabIndex="-1"
              />
              <label htmlFor={htmlId} tabIndex="0">
                {mw.CAMPUS_DETAILS[key].name}
              </label>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}
