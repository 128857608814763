import * as _ from 'lodash-es';
import React, { useContext, useState } from 'react';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import mw from '../../utils/maps';
import { MAP_ACTIONS, UI_ACTIONS } from '../../reducers/mapReducer';
import { MapContext } from '../../contexts/MapContext';
import {
  DIRECTIONS_RESULT_VIEW_STATES,
  POINT_DISPLAY_TEXT,
  SEARCH_CONTROL,
} from '../../constants/constants';
import { isStringEmpty } from '../../utils/string';
import { isInstanceMatching } from '../../utils/utils';
import {
  transformPointToDisplayText,
  transformPointToDisplayVariant,
} from '../../utils/directions';
import {
  GEOLOCATE_WATCH_STATE,
  getGeoLocationAsRoutePoint,
  isGeolocateState,
  sendGeoAsRoutePoint,
} from '../../utils/geolocate';
import IconWrapper, { Icons } from '../icons/Icons';
import TravelModeGroup from './directionsSearch/TravelModeGroup';
import TravelOptionGroup from './directionsSearch/TravelOptionGroup';
import IconBtnBack from '../buttons/IconBtnBack';

import s from './DirectionsSearch.module.scss';

// constants
const BTN_BACK_TITLE = 'back to initial search controls';
const BTN_SWAP_TITLE = 'Reverse starting point and destination';
const INPUT_FROM_PLACEHOLDER = {
  DEFAULT: 'Choose start point',
  GEO_LOADING: 'Checking location ...',
};

/**
 * DirectionsSearch
 * - this UI helps populate map directions search inputs and options
 */
const DirectionsSearch = ({ instance = '', customClassName = '' }) => {
  const isMobile = useMediaQuery();
  const { map, dispatch } = useContext(MapContext);
  const shouldRenderInstance = isInstanceMatching(isMobile, instance);
  const [isGeoLoading, setIsGeoLoading] = useState(false);
  const [isGeoError, setIsGeoError] = useState(false);
  const inputFromPlaceholder = isGeoLoading
    ? INPUT_FROM_PLACEHOLDER.GEO_LOADING
    : INPUT_FROM_PLACEHOLDER.DEFAULT;

  // stop rendering component is instance is not matching
  if (!shouldRenderInstance) {
    return null;
  }

  const mapDirStates = map.directions;

  // util - dispatches local state updates back to context
  const updateMapDirStates = (payload = {}) => {
    dispatch({
      type: MAP_ACTIONS.UPDATE_DIRECTION,
      payload,
    });
  };

  // util - back button state
  const isBackBtnDisabled = () => {
    const directionsResultViewState = _.get(
      map,
      'directionsResult.viewState',
      '',
    );
    return directionsResultViewState === DIRECTIONS_RESULT_VIEW_STATES.LOADING;
  };

  // handlers
  const handleBtnBackClick = (e) => {
    e.preventDefault();
    if (typeof dispatch === 'function') {
      dispatch({
        type: UI_ACTIONS.UPDATE_UI_SEARCH_CONTROL,
        payload: {
          searchControl: SEARCH_CONTROL.SEARCH,
        },
      });
      // need to manually call map resize here as this affects map canvas size
      mw.resizeMap();
    }
  };
  const handleBtnSwapClick = (e) => {
    e.preventDefault();
    updateMapDirStates({
      from: mapDirStates.to,
      to: mapDirStates.from,
    });
  };
  const handleGeoBtnFromClick = (e) => {
    e.preventDefault();

    // prevent trigger another geolocate request while waiting
    const shouldSkipGeolocate = isGeolocateState(
      GEOLOCATE_WATCH_STATE.WAITING_ACTIVE,
    );
    if (shouldSkipGeolocate) {
      return false;
    }
    setIsGeoLoading(true);
    getGeoLocationAsRoutePoint(
      // success callback
      (lng, lat) => {
        setIsGeoLoading(false);
        sendGeoAsRoutePoint(dispatch, true)(lng, lat);
      },
      // error callback
      () => {
        setIsGeoLoading(false);
        setIsGeoError(true);
      },
    );
  };
  const handleTravelInputFromClick = (e) => {
    e.preventDefault();
  };
  const handleTravelInputToClick = (e) => {
    e.preventDefault();
  };

  // handler callbacks
  const updateActiveTravelModeCallBack = (newMode) => {
    if (isStringEmpty(newMode)) {
      return;
    }
    updateMapDirStates({
      mode: newMode,
    });
  };
  const updateTravelOptionsCallBack = (optionKey, optionValue) => {
    if (isStringEmpty(optionKey) || typeof optionValue !== 'boolean') {
      return;
    }
    const newTravelOptions = {
      ...mapDirStates.options,
      [optionKey]: optionValue, // overwrite new value here
    };
    updateMapDirStates({
      options: newTravelOptions,
    });
  };

  // view models
  const inputFromValue = transformPointToDisplayText(mapDirStates.from);
  const inputFromVariant = transformPointToDisplayVariant(mapDirStates.from);
  const inputToValue = transformPointToDisplayText(mapDirStates.to);

  const geoBtnFromState = isGeoLoading
    ? 'loading'
    : inputFromValue === POINT_DISPLAY_TEXT.USER_LOCATION
    ? 'active'
    : '';

  return (
    <div
      className={`${s.DirectionsSearch} ${customClassName}`}
      data-instance={instance}
    >
      <div className={s.Aside}>
        <IconBtnBack
          label={BTN_BACK_TITLE}
          isDisabled={isBackBtnDisabled()}
          onClickHandler={handleBtnBackClick}
        />
      </div>
      {/* main controls UI */}
      <div className={s.Main}>
        {/* Travel inputs */}
        <section className={s.TravelInputs}>
          <div className={s.TravelInputWrapper} data-for="from">
            <label className={s.TravelInputLabel} htmlFor="directionsInputFrom">
              From:
            </label>
            <input
              id="directionsInputFrom"
              type="text"
              className={s.TravelInput}
              value={inputFromValue}
              onClick={handleTravelInputFromClick}
              readOnly={true}
              placeholder={inputFromPlaceholder}
              data-variant={inputFromVariant}
            />
            <button
              id="directionsGeoBtnFrom"
              className={s.TravelInputGeoBtn}
              aria-label="Use geolocation as start point"
              onClick={handleGeoBtnFromClick}
              data-state={geoBtnFromState}
              disabled={isGeoLoading || isGeoError}
            >
              <IconWrapper>{Icons.Geolocate()}</IconWrapper>
            </button>
          </div>
          <div className={s.TravelInputWrapper} data-for="to">
            <label className={s.TravelInputLabel} htmlFor="directionsInputTo">
              To:
            </label>
            <input
              id="directionsInputTo"
              type="text"
              className={s.TravelInput}
              value={inputToValue}
              onClick={handleTravelInputToClick}
              readOnly={true}
              placeholder="Choose end point"
            />
          </div>
          {/* button swap */}
          <button
            className={s.BtnSwap}
            title={BTN_SWAP_TITLE}
            aria-label={BTN_SWAP_TITLE}
            onClick={handleBtnSwapClick}
          >
            <IconWrapper>{Icons.Swap()}</IconWrapper>
          </button>
        </section>
        {/* Travel modes */}
        <section className={s.TravelModes}>
          <TravelModeGroup
            activeMode={mapDirStates.mode}
            updateActiveTravelModeCallBack={updateActiveTravelModeCallBack}
          />
        </section>
        {/* Travel options */}
        <section className={s.TravelOptions}>
          <TravelOptionGroup
            travelOptions={mapDirStates.options}
            updateTravelOptionsCallBack={updateTravelOptionsCallBack}
          />
        </section>
      </div>
    </div>
  );
};

export default DirectionsSearch;
