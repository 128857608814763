import { isNumber } from './number';

// Note:
// - Google Maps uses earth radius from NASA as: 6378 km
// - we use globally average value: https://en.wikipedia.org/wiki/Earth_radius
const EARTH_RADIUS_KM = 6371;

/**
 * degreesToRadians
 * @param degrees
 * @returns {null|number}
 */
export const degreesToRadians = (degrees) => {
  if (!isNumber(degrees)) {
    return null;
  }
  return (degrees * Math.PI) / 180;
};

/**
 * computeGeoDistanceInKmBetween
 * Note:
 * - calculation algo verified against Google Maps:
 * https://developers.google.com/maps/documentation/javascript/reference/geometry#spherical.computeDistanceBetween
 * @param {*} latA
 * @param {*} lngA
 * @param {*} latB
 * @param {*} lngB
 * @returns {null|number}
 */
export const computeGeoDistanceInKmBetween = (lngA, latA, lngB, latB) => {
  if (
    !isNumber(latA) ||
    !isNumber(lngA) ||
    !isNumber(latB) ||
    !isNumber(lngB)
  ) {
    return null;
  }

  // No need to null check following values as inputs are all checked
  const latARadians = degreesToRadians(latA);
  const latBRadians = degreesToRadians(latB);
  const dLat = degreesToRadians(latB - latA);
  const dLon = degreesToRadians(lngB - lngA);

  // magics
  const a =
    Math.pow(Math.sin(dLat / 2), 2) +
    Math.pow(Math.sin(dLon / 2), 2) *
      Math.cos(latARadians) *
      Math.cos(latBRadians);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return EARTH_RADIUS_KM * c;
};
