import * as _ from 'lodash-es';
import React, { useContext, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import mw from '../../utils/maps';
import { MapContext } from '../../contexts/MapContext';
import { BOTTOM_SHEET_VIEW_STATE } from '../../constants/constants';
import { MAP_ACTIONS, UI_ACTIONS } from '../../reducers/mapReducer';
import { isObject } from '../../utils/utils';
import { getDirectionsPointDataFromSearchResult } from '../../utils/directions';
import ShareMapDeepLink from '../utils/ShareMapDeepLink';
import ShareQRCode from '../utils/ShareQRCode';

import s from './PoiDetails.module.scss';

export const POI_DETAILS_TYPE = {
  CATEGORY: 'CATEGORY', // category details view
  POI: 'POI', // Poi and Point details view
  BUILDING: 'BUILDING', // Building type Poi details view
};

/**
 * PoiDetailsCTAs
 */
const PoiDetailsCTAs = ({
  shareLinkEnabled = true,
  shareQREnabled = true,
  dataType = POI_DETAILS_TYPE.POI,
}) => {
  const isPoiType = dataType === POI_DETAILS_TYPE.POI;
  const isCategoryType = dataType === POI_DETAILS_TYPE.CATEGORY;

  const { map, dispatch } = useContext(MapContext);
  const [showShareContent, setShowShareContent] = useState(false);

  // reset UI if original POI details data updated
  const dataPathPoi = 'searchResults.selectedPoi.poiDetail.originalPoiDetails';
  const dataPathCategory = 'searchResults.selectedCategory';
  const dataDetails = _.get(
    map,
    isCategoryType ? dataPathCategory : dataPathPoi,
    {},
  );
  useDeepCompareEffect(() => {
    setShowShareContent(false);
  }, [dataDetails]);

  // handlers
  const handleBtnShareClick = (e) => {
    e.preventDefault();

    setShowShareContent(true);
    dispatch({
      type: UI_ACTIONS.UPDATE_UI_BOTTOM_SHEET,
      payload: {
        bottomSheetState: BOTTOM_SHEET_VIEW_STATE.EXPANDED,
      },
    });
  };
  const handleGetDirectionClick = (e) => {
    e.preventDefault();
    if (isCategoryType) {
      return;
    }

    // Notes: API data are of 'Point' type only, not 'Poi' type
    const poiDetails = _.get(map, 'searchResults.selectedPoi.poiDetail', null);
    if (!isObject(poiDetails)) {
      return;
    }
    const pointToPayload = getDirectionsPointDataFromSearchResult(poiDetails);
    if (!isObject(pointToPayload)) {
      return;
    }

    // update map UI
    mw.clearCategoryMarkers();

    // finally, update app UI with data
    dispatch({
      type: MAP_ACTIONS.UPDATE_DIRECTION_POINT_TO,
      payload: {
        to: {
          ...pointToPayload,
        },
      },
    });
    // hide bottom sheet if expanded
    dispatch({
      type: UI_ACTIONS.UPDATE_UI_BOTTOM_SHEET,
      payload: {
        bottomSheetState: BOTTOM_SHEET_VIEW_STATE.HIDDEN,
      },
    });
  };

  return (
    <>
      <div className={s.PoiDetailsCTAs}>
        <button
          className="btnCTA"
          data-variant="secondary"
          onClick={handleBtnShareClick}
          disabled={showShareContent}
          data-test-id="poiDetailCTAs-share"
        >
          Share
        </button>
        {isPoiType && (
          <button
            className="btnCTA"
            data-variant="primary"
            onClick={handleGetDirectionClick}
            data-test-id="poiDetailCTAs-getDirection"
          >
            Get Directions
          </button>
        )}
      </div>
      {/* share content */}
      {showShareContent && (
        <>
          {shareLinkEnabled && <ShareMapDeepLink />}
          {shareQREnabled && <ShareQRCode />}
        </>
      )}
    </>
  );
};

export default PoiDetailsCTAs;
