import * as _ from 'lodash-es';
import React, { useContext, useEffect, useState } from 'react';
import FEATURES from '../../constants/features';
import mw from '../../utils/maps';
import BIData from '../../api/BIData';
import {
  DEFAULT_SEARCH_RESULTS_STATE,
  MapContext,
} from '../../contexts/MapContext';
import { BACK_BTN_TARGET } from '../../constants/constants';
import { isObject, typeDelay } from '../../utils/utils';
import { isNumber, roundToPrecision } from '../../utils/number';
import { isStringEmpty } from '../../utils/string';
import { formatPoiInfosNames } from './SearchResults.mapper';
import {
  isSearchItemTypeBuilding,
  isSearchItemTypeCategory,
  sortSearchResults,
} from './SearchResults.utils';
import { applyPoiPointDetails } from './PoiDetails.utils';
import CampusSearchFilter from '../CampusFilters/CampusSearchFilter';
import IconWrapper, { getSearchResultIcon } from '../icons/Icons';
import { getCategoryIcon } from '../lists/CategoryResults';

import s from './SearchControl.module.scss';
import OpenDay from '../../utils/openday';

export const SEARCH_RESULTS_DATA_SOURCE = {
  KEYWORD: 'KEYWORD', // search input with mixed results
  CATEGORY: 'CATEGORY', // category/type pois results
};

/**
 * SearchResults
 *
 * Usages:
 * 1. mixed keyword search results
 * 2. category/type pois search results
 *
 * @param dataSource
 * @param instance
 * @param campusIdSelected
 * @returns {JSX.Element|null}
 * @constructor
 */
const SearchResults = ({
  dataSource = SEARCH_RESULTS_DATA_SOURCE.KEYWORD,
  instance = '',
}) => {
  const { map, dispatch } = useContext(MapContext);

  // get results data based on data source
  const isKeywordSearchResultsView =
    dataSource === SEARCH_RESULTS_DATA_SOURCE.KEYWORD;
  const isCategorySearchResultsView =
    dataSource === SEARCH_RESULTS_DATA_SOURCE.CATEGORY;
  const results = _.get(
    map,
    isKeywordSearchResultsView
      ? 'searchResults.results'
      : 'searchResults.selectedCategory.pois',
    isKeywordSearchResultsView
      ? DEFAULT_SEARCH_RESULTS_STATE.results
      : DEFAULT_SEARCH_RESULTS_STATE.selectedCategory.pois,
  );
  const num_results = _.get(
    map,
    isKeywordSearchResultsView
      ? 'searchResults.num_results'
      : 'searchResults.selectedCategory.poisCount',
    isKeywordSearchResultsView
      ? DEFAULT_SEARCH_RESULTS_STATE.num_results
      : DEFAULT_SEARCH_RESULTS_STATE.selectedCategory.poisCount,
  );
  const categoriesIcons = map.categoriesIcons || {};

  // get geolocation data
  const geolocatePosition = map.mapGeolocateData.position;

  // result item click handlers
  // note:
  // - this only applicable to SEARCH_RESULTS_DATA_SOURCE.KEYWORD
  // - SEARCH_RESULTS_DATA_SOURCE.CATEGORY has no category type items, only POI type
  const categoryClicked = (item) => {
    console.debug('resultItemClicked: categoryClicked', item);
    const poiTypeTitle = item.title;
    const poiTypeId = `${item.id}`;
    const campusId = item.campusId;
    mw.loadTypePoisForCampus(
      {
        poiTypeTitle,
        poiTypeId,
        campusId,
        customProps: {
          dataTrigger: 'searchResult', // category result item can only be from 'searchResult'
          dataIsMobile: instance === 'mobile',
        },
      },
      dispatch,
    );
  };
  const poiClicked = async (item, isBuildingType = false) => {
    console.debug(
      `resultItemClicked: ${isBuildingType ? 'building' : 'poi'}Clicked`,
      item,
    );
    const lngLat = item.coordinates || null;
    const itemHasPoiData = isObject(item) && isObject(item.originalPoiDetails);
    const poi = itemHasPoiData ? { ...item.originalPoiDetails } : null;
    const backBtnTarget = isCategorySearchResultsView
      ? BACK_BTN_TARGET.CATEGORY_DETAILS
      : BACK_BTN_TARGET.MIXED_SEARCH_RESULT;

    // get full poi data from
    const fullPoiDetails = itemHasPoiData
      ? isBuildingType
        ? await mw.getFullBuildingPoiDetails(item.originalPoiDetails)
        : await mw.getFullPoiDetails(item.originalPoiDetails)
      : null;

    applyPoiPointDetails(
      {
        lngLat,
        poi:
          itemHasPoiData && !!poi
            ? {
                ...poi,
                // add extra info for display
                building: item.building || '',
                campusId: item.campusId,
                campusName: item.campusName || '',
                floor: item.floor || '',
                hasBuilding: item.hasBuilding,
                hasZLevel: item.hasZLevel,
                resultId: item.resultId, // poiId | buildingId
                title: item.title || item.originalPoiDetails.title || 'Point',
                formattedDispPoiNames:
                  item.formattedDispPoiNames ||
                  formatPoiInfosNames(fullPoiDetails) ||
                  '',
                searchResultDataType: 'poi',
                searchResultBackBtnTarget: backBtnTarget,
                // merge in full POI data
                geometry: {
                  ...((poi && poi.geometry) || {}),
                  ...((fullPoiDetails && fullPoiDetails.geometry) || {}),
                },
                properties: {
                  ...((poi && poi.properties) || {}),
                  ...((fullPoiDetails && fullPoiDetails.properties) || {}),
                },
              }
            : false,
      },
      dispatch,
    );
  };
  const resultItemClicked = (item) => {
    // analytics
    BIData.push(BIData.TAG_SEARCH_RESULT_SELECTED, { ...item });

    if (isSearchItemTypeCategory(item)) {
      categoryClicked(item);
    } else if (isSearchItemTypeBuilding(item)) {
      poiClicked(item, true).finally();
    } else {
      poiClicked(item, false).finally();
    }
  };

  // local states
  const [resultsToRender, setResultsToRender] = useState(results);
  useEffect(() => {
    setResultsToRender(
      sortSearchResults(results, {
        shouldSortByDistanceToGeolocation:
          FEATURES.DISPLAY_SEARCH_RESULT_DISTANCE_TO_GEOLOCATION,
        geolocatePosition,
      }),
    );
  }, [geolocatePosition, results]);

  // view models
  const hasResults = Array.isArray(resultsToRender) && isNumber(num_results);

  return hasResults ? (
    <div
      className={`search-suggestions default ${s.SearchSuggestions}`}
      data-instancce={instance}
    >
      {isKeywordSearchResultsView && <CampusSearchFilter instance={instance} />}
      <ul className="search-suggestions-list default">
        <div className="search-results-num">
          <span>
            {num_results} place{num_results > 1 ? 's' : ''} found
            {isCategorySearchResultsView ? ' in category' : ''}
          </span>
        </div>
        {resultsToRender.map((item, index) => {
          const shouldDisplayIcon = isKeywordSearchResultsView;
          const isCategoryType = item.type === 'category';
          const categoryId = isCategoryType ? item.id : null;
          const categoryTypeIconId = isNumber(categoryId)
            ? categoriesIcons[categoryId]
            : null;
          const hasCategoryTypeIcon = isNumber(categoryTypeIconId);

          // has valid category id but cannot find category type icon id in existing map
          if (
            isNumber(categoryId) &&
            !Object.keys(categoriesIcons).includes(`${categoryId}`) &&
            categoriesIcons[categoryId] !== 'LOADING'
          ) {
            // Note: use delay debounce, as React will do multiple renders
            typeDelay(() => {
              mw.getCategoryTypeInfo(categoryId, dispatch).finally();
            }, 30);
          }

          // distance to campus centre
          const distanceToCampusNum = !isCategoryType
            ? item.distanceToCampus.km
            : null;
          const distanceToCampusDisplayText = isNumber(distanceToCampusNum)
            ? `${roundToPrecision(
                distanceToCampusNum,
                2,
              )} km (from campus center)`
            : '';

          // distance to geolocation
          const distanceToGeolocationNum = !isCategoryType
            ? item.distanceToGeolocation.km
            : null;
          const distanceToGeolocationDisplayText = isNumber(
            distanceToGeolocationNum,
          )
            ? `${roundToPrecision(
                distanceToGeolocationNum,
                2,
              )} km (from current location)`
            : '';

          // accessibility
          let ariaLabel = item.title;
          if (isCategoryType) {
            ariaLabel += isCategoryType ? `, category` : '';
            ariaLabel += !isStringEmpty(item.campusName)
              ? `, ${item.campusName}`
              : '';
          } else {
            ariaLabel += !isStringEmpty(item.floor)
              ? `, Floor ${item.floor}`
              : '';
            ariaLabel += !isStringEmpty(item.building)
              ? `, ${item.building}`
              : '';
            ariaLabel += !isStringEmpty(item.campusName)
              ? `, ${item.campusName}`
              : '';
          }

          // Open Day 2022
          const btnOpenDayStyle = OpenDay.makeOpenDayButtonStyle(shouldDisplayIcon, item.resultId)

          return (
            <button
              key={index}
              className={`item ${btnOpenDayStyle.className}`}
              title={item.title}
              role="listitem"
              aria-label={ariaLabel}
              onClick={() => resultItemClicked(item)}
              data-has-icon={shouldDisplayIcon ? 'true' : 'false'}
              style={btnOpenDayStyle.style}
            >
              {/* icon */}
              {shouldDisplayIcon && (
                <IconWrapper>
                  {hasCategoryTypeIcon
                    ? getCategoryIcon(categoryTypeIconId)
                    : getSearchResultIcon(item.type)}
                </IconWrapper>
              )}
              {/* content */}
              <span className="title">{item.title}</span>
              {isCategoryType && (
                <span className="context detail-secondary">
                  <span className="context category">Category</span>
                  {item.campusName && (
                    <span className="context campus">, {item.campusName}</span>
                  )}
                </span>
              )}
              {!isCategoryType && (
                <>
                  {item.floor ? ',' : null}
                  {item.floor ? (
                    <span className="context floor">Floor {item.floor}</span>
                  ) : null}
                  <span className="context detail-secondary">
                    {item.building ? (
                      <span className="context building">{item.building}</span>
                    ) : null}
                    {item.building ? ', ' : null}
                    <span className="context campus">{item.campusName}</span>
                  </span>
                  {!isStringEmpty(distanceToCampusDisplayText) && (
                    <span className="context detail-dispDistanceToCampus">
                      {distanceToCampusDisplayText}
                    </span>
                  )}
                  {!isStringEmpty(distanceToGeolocationDisplayText) && (
                    <span className="context detail-dispDistanceToGeolocation">
                      {distanceToGeolocationDisplayText}
                    </span>
                  )}
                  {!isStringEmpty(item.formattedDispPoiNames) && (
                    <span className="context detail-dispPoiNames">
                      {`(${item.formattedDispPoiNames})`}
                    </span>
                  )}
                </>
              )}
            </button>
          );
        })}
      </ul>
    </div>
  ) : null;
};
export default SearchResults;
