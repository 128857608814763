/**
 * isInstanceMatching
 * - used to check reusable components' instances
 * @param isMobile
 * @param instance
 * @returns {boolean}
 */
export const isInstanceMatching = (isMobile, instance) => {
  const isMobileView = isMobile;
  const isDesktopView = !isMobile;

  return (
    (isMobileView && instance === 'mobile') ||
    (isDesktopView && instance === 'desktop')
  );
};

/**
 * isObject
 * @param object
 * @returns {boolean}
 */
export const isObject = (object) => {
  return object !== null && typeof object === 'object';
};

/**
 * deepEqualObj
 * @param object1
 * @param object2
 * @returns {boolean}
 */
export const deepEqualObj = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqualObj(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
};

/**
 * isDefined
 * @param val
 * @returns {boolean}
 */
export const isDefined = (val) => {
  return typeof val !== 'undefined';
};

/**
 * isBoolean
 * @param bool
 * @returns {boolean}
 */
export const isBoolean = (bool) => {
  return typeof bool === 'boolean';
};

/**
 * isFunction
 * @param fn
 * @returns {boolean}
 */
export const isFunction = (fn) => {
  return typeof fn === 'function';
};

/**
 * typeDelay
 * Non-repeating delay function
 */
export const typeDelay = (function () {
  let timer = 0;
  return function (callback, ms) {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();

/**
 * promiseDelay
 * @param delay
 * @returns {Promise<*|Promise<unknown>>}
 */
export const promiseDelay = async (delay = 0) => {
  return new Promise((resolve) => setTimeout(resolve, delay));
};
