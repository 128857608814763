import React from 'react';
import { isObject } from '../../../utils/utils';
import { DISPLAY } from '../../../constants/constants';
import MsgBox from '../../utils/MsgBox';

import s from '../DirectionsResult.module.scss';

/**
 * ResultError
 */
const ResultError = ({ resultData, instance = '' }) => {
  if (!isObject(resultData)) {
    return null;
  }

  const { errorMsg = DISPLAY.ROUTE_SEARCH_DEFAULT_ERROR } = resultData;
  return (
    <div className={s.DirectionsResultError} data-instancce={instance}>
      <MsgBox msg={errorMsg} />
    </div>
  );
};

export default ResultError;
