import React from 'react';
import { isFunction } from '../../utils/utils';
import s from './Icons.module.scss';

/**
 * Icons Wrapper
 */
const IconWrapper = ({ children }) => {
  return (
    <div className={`icon-svg ${s.SvgIcon}`} aria-hidden="true">
      {children}
    </div>
  );
};
export default IconWrapper;

/**
 * Icons
 * - all SVG icons contents
 */
export const Icons = {};

Icons.Arrow = (fillColor = '#1a1a1a') => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fillColor}
    >
      <path d="m4.47 11.47-.03.03a.64.64 0 0 0-.08.11l-.02.04-.02.03v.02H4.3v.02l-.01.02v.01l-.01.01v.02l-.02.03v.01a.74.74 0 0 0 0 .36l.01.04.01.03.02.03v.01l.01.03.02.03.01.03.02.03.02.03.03.03.02.03.03.03 6 6a.75.75 0 1 0 1.06-1.06l-4.72-4.72H19a.75.75 0 0 0 0-1.5H6.81l4.72-4.72a.75.75 0 1 0-1.06-1.06l-6 6Z" />
    </svg>
  );
};
Icons.ArrowFat = (fillColor = '#777777') => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="6"
      viewBox="0 0 27 6"
      fill={fillColor}
    >
      <path d="m1.82 5.95 11.68-3.9 11.68 3.9a1 1 0 0 0 .64-1.9l-12-4a1 1 0 0 0-.64 0l-12 4a1 1 0 0 0 .64 1.9Z" />
    </svg>
  );
};
Icons.Tick = (fillColor = '#ffffff') => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={fillColor}
    >
      <path d="m2.8 8.53 3.34 3.33a.75.75 0 0 0 1.06 0l6.66-6.66a.75.75 0 1 0-1.06-1.06l-6.13 6.13-2.8-2.8A.75.75 0 0 0 2.8 8.53Z" />
    </svg>
  );
};
Icons.CrossCircle = (fillColor = '#ee0220') => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill={fillColor}
    >
      <path d="M10 .85C4.5.85 0 5.28 0 10.7c0 5.41 4.5 9.85 10 9.85s10-4.44 10-9.85C20 5.28 15.5.85 10 .85Zm4.38 12.43-1.76 1.73L10 12.42l-2.63 2.59-1.75-1.73 2.63-2.58L5.62 8.1 7.38 6.4 10 8.97l2.63-2.58 1.74 1.72-2.62 2.59 2.63 2.58Z" />
    </svg>
  );
};
Icons.Chevron = (fillColor = '#ffffff') => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill={fillColor}
    >
      <path d="m3.47 7.03 4 4c.3.3.77.3 1.06 0l4-4a.75.75 0 1 0-1.06-1.06L8 9.44 4.53 5.97a.75.75 0 1 0-1.06 1.06Z" />
    </svg>
  );
};
Icons.FsOn = (fillColor = '#ffffff') => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill={fillColor}
    >
      <path d="M4.08 15.14v-1.52a.75.75 0 0 0-1.5 0v3.33a.74.74 0 0 0 .2.5l.01.02.01.01.02.02.02.01a.74.74 0 0 0 .5.19h3.33a.75.75 0 0 0 0-1.5H5.14l3.72-3.72a.75.75 0 0 0-1.06-1.06l-3.72 3.72ZM14.86 4.37l-3.72 3.72a.75.75 0 0 0 1.06 1.06l3.72-3.72v1.52a.75.75 0 0 0 1.5 0V3.62a.75.75 0 0 0-.75-.75h-3.34a.75.75 0 0 0 0 1.5h1.53Z" />
    </svg>
  );
};
Icons.FsOff = (fillColor = '#ffffff') => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill={fillColor}
    >
      <path d="m6.19 13.25-3.72 3.72a.75.75 0 1 0 1.06 1.06l3.72-3.72v1.52a.75.75 0 0 0 1.5 0V12.5a.75.75 0 0 0-.3-.6l-.02-.02h-.01a.74.74 0 0 0-.42-.13H4.67a.75.75 0 0 0 0 1.5h1.52Zm7.56-7.56V4.17a.75.75 0 0 0-1.5 0V7.5c0 .13.03.26.1.36l.07.12.02.02.01.01.04.04.02.02.02.01.1.07c.1.06.24.1.37.1h3.33a.75.75 0 0 0 0-1.5h-1.52l3.72-3.72a.75.75 0 1 0-1.06-1.06l-3.72 3.72Z" />
    </svg>
  );
};
Icons.Swap = (fillColor = '#2D343E') => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill={fillColor}
    >
      <path d="M1.55 9.32c-.53 0-.67.39-.36.82l1.64 2.3c.25.35.63.35.88 0l1.64-2.3c.3-.43.15-.82-.36-.82H3.97V4.28c0-1.13.7-1.86 1.66-1.86s1.67.71 1.67 1.86v4.68c0 2.04 1.29 3.38 3.07 3.38 1.82 0 3.06-1.34 3.06-3.38v-5h1.02c.52 0 .67-.38.37-.8L13.17.83c-.24-.34-.62-.34-.88 0l-1.64 2.3c-.3.44-.16.83.36.83h1.02v5.1c0 1.13-.7 1.86-1.66 1.86S8.7 10.22 8.7 9.07V4.38c0-2.03-1.29-3.37-3.07-3.37-1.82 0-3.06 1.34-3.06 3.37v4.94H1.55Z" />
    </svg>
  );
};
Icons.Walk = (fillColor = '#777777') => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill={fillColor}
    >
      <path d="m8.3 4.6-2.65.67a.75.75 0 0 0-.44.31l-1.33 2a.75.75 0 0 0 1.24.84L6.3 6.66l1.27-.32L7.1 9.2c-.04.24.04.48.21.65l1.92 1.92 1.28 2.55a.75.75 0 0 0 1.34-.67L10.5 11a.73.73 0 0 0-.14-.2L8.64 9.08 9 6.89l.97.97c.08.09.18.15.3.18l2 .67a.75.75 0 1 0 .47-1.42l-1.84-.61L9.03 4.8l-.05-.04a.75.75 0 0 0-.33-.16H8.6l-.03-.01a.74.74 0 0 0-.27.02Zm-2.53 9.85 2-2.67a.75.75 0 0 0-1.2-.9l-2 2.67a.75.75 0 0 0 1.2.9Zm3.4-10.37a1.42 1.42 0 1 0 0-2.83 1.42 1.42 0 0 0 0 2.83Z" />
    </svg>
  );
};
Icons.Cycle = (fillColor = '#777777') => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill={fillColor}
    >
      <path d="M3.83 14.75a2.75 2.75 0 1 0 0-5.5 2.75 2.75 0 0 0 0 5.5Zm9.34 0a2.75 2.75 0 1 0 0-5.5 2.75 2.75 0 0 0 0 5.5Zm-3.92-2.08V10c0-.2-.08-.39-.22-.53l-1.4-1.4 2.03-1.64.88 1.32c.14.2.38.33.63.33h2a.75.75 0 0 0 0-1.5h-1.6l-1.11-1.66a.75.75 0 0 0-1.1-.17L6.04 7.4a.75.75 0 0 0-.06 1.12l1.78 1.78v2.36a.75.75 0 0 0 1.5 0Zm-5.42.58a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5Zm9.34 0a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5Zm-1.34-8.5a1.42 1.42 0 1 0 0-2.83 1.42 1.42 0 0 0 0 2.83Z" />
    </svg>
  );
};
Icons.Car = (fillColor = '#777777') => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill={fillColor}
    >
      <path d="M2.52 13.51h.7c.46 0 .8-.34.8-.79v-.95a90.13 90.13 0 0 0 8.95 0v.95c0 .45.35.8.8.8h.7c.45 0 .8-.35.8-.8V11.2c.19-.23.29-.53.29-.9V9.24c0-1.02-.18-1.6-.74-2.3l-.51-.66a14.32 14.32 0 0 0-.81-2.6 2.17 2.17 0 0 0-1.77-1.2c-.42-.06-1.68-.1-3.23-.1-1.56 0-2.82.04-3.23.1-.82.1-1.45.52-1.77 1.2-.2.43-.6 1.54-.81 2.6l-.52.65c-.55.7-.74 1.29-.74 2.31v1.03c0 .38.1.68.3.9v1.54c0 .44.35.8.8.8Zm5.98-2.55c-2.01 0-4.51-.1-5.46-.2-.5-.07-.69-.32-.69-.77v-.8c0-.78.15-1.16.57-1.7l.63-.83c.14-.79.52-2 .77-2.53.19-.41.56-.65 1.08-.71.4-.06 1.55-.1 3.1-.1s2.72.05 3.09.1c.53.06.89.3 1.09.72.25.51.62 1.73.77 2.52l.63.83c.41.54.56.92.56 1.7v.8c0 .45-.19.7-.69.76-.94.11-3.45.2-5.45.2Zm-4.24-.77c.55 0 .98-.42.98-.97a.96.96 0 0 0-.98-.98.96.96 0 0 0-.98.98c0 .55.42.97.98.97Zm8.48 0c.55 0 .98-.42.98-.97a.96.96 0 0 0-.98-.98.96.96 0 0 0-.98.98c0 .55.42.97.98.97Zm-5.82-.23h3.16c.41 0 .7-.3.7-.71 0-.42-.29-.71-.7-.71H6.92c-.42 0-.71.29-.71.7 0 .42.3.72.7.72ZM4.34 6.08c-.05.24.05.34.3.33.87-.05 1.95-.1 3.86-.1 1.9 0 2.99.05 3.86.1.25.01.35-.1.3-.33a7.53 7.53 0 0 0-.55-1.6c-.16-.3-.35-.42-.7-.46-.4-.06-1.35-.1-2.91-.1-1.57 0-2.52.04-2.92.1-.34.04-.53.16-.7.45-.16.3-.4 1.05-.54 1.61Z" />
    </svg>
  );
};
Icons.Train = (fillColor = '#777777') => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill={fillColor}
    >
      <path d="M4.27 15.55h.36c.18 0 .3-.06.39-.22l.33-.53h6.23l.33.53c.1.16.2.22.39.22h.34c.32 0 .47-.31.3-.59l-1.13-1.82c1.36-.05 2.16-.98 2.16-2.34V7.01c0-1.6-.18-3.05-.43-4.07-.3-1.43-1.2-2.27-2.52-2.43a24.45 24.45 0 0 0-5.08 0C4.6.67 3.72 1.5 3.4 2.94a17.78 17.78 0 0 0-.42 4.02v3.84c0 1.35.8 2.28 2.14 2.34l-1.15 1.84c-.16.26-.02.57.3.57Zm1.68-1.72.43-.68h4.17l.43.68H5.95ZM5.4 12.2c-1.02 0-1.5-.6-1.5-1.45v-3.8c0-1.55.19-2.87.42-3.85.22-1.01.81-1.58 1.73-1.68.43-.05 1.28-.11 2.42-.11 1.05 0 2 .05 2.42.1.92.11 1.52.68 1.74 1.69.23.98.41 2.34.41 3.9v3.75c0 .85-.47 1.45-1.49 1.45H5.4Zm5.82-.87a.8.8 0 0 0 .8-.82.8.8 0 0 0-.8-.8.8.8 0 0 0-.82.8c0 .47.35.82.82.82Zm-5.5 0a.8.8 0 0 0 .82-.82c0-.46-.35-.8-.81-.8-.47 0-.8.34-.8.8 0 .48.33.82.8.82Zm2.75-2.42a32.3 32.3 0 0 0 3.21-.15c.37-.04.53-.28.53-.63v-1.4c0-1.15-.13-2.13-.21-2.52-.06-.27-.24-.47-.53-.5-.64-.08-1.37-.13-3-.14-1.63.01-2.36.06-3.01.13-.27.04-.45.24-.5.51-.1.4-.23 1.37-.23 2.53v1.4c0 .34.16.58.53.62.62.08 1.74.14 3.21.15ZM6.97 2.9h3c.24 0 .4-.16.4-.4 0-.24-.16-.4-.4-.4h-3c-.24 0-.4.16-.4.4 0 .24.16.4.4.4Z" />
    </svg>
  );
};
Icons.Building = (fillColor = '#2c3e50') => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={fillColor}
    >
      <path d="M5.75 5.5V4a.58.58 0 0 1 .58-.58H11a.58.58 0 0 1 .58.58v8a.58.58 0 0 1-.58.58H6.33a.58.58 0 0 1-.58-.58v-1.33a.75.75 0 0 0-1.5 0V12a2.08 2.08 0 0 0 2.08 2.08H11A2.08 2.08 0 0 0 13.08 12V4A2.08 2.08 0 0 0 11 1.92H6.33A2.08 2.08 0 0 0 4.25 4v1.5a.75.75 0 0 0 1.5 0Z" />
      <path d="M8.52 7.25H1a.75.75 0 0 0 0 1.5h7.52l-.72.72a.75.75 0 1 0 1.06 1.06l2-2 .03-.03.03-.03.02-.03.02-.03.02-.03.02-.03.01-.03.01-.02v-.02h.01v-.02l.01-.01v-.01l.01-.02.01-.03v-.01a.74.74 0 0 0 0-.36l-.01-.04-.01-.04v-.01l-.01-.01V7.7l-.01-.01v-.02H11v-.01L11 7.65l-.02-.03-.02-.03-.02-.03-.03-.03-.02-.03-.03-.03-2-2A.75.75 0 0 0 7.8 6.53l.72.72Z" />
    </svg>
  );
};
Icons.Elevator = (fillColor = '#2c3e50') => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={fillColor}
    >
      <path d="M2.85 3.11v9.78c0 1.01.77 1.83 1.72 1.83h6.86c.95 0 1.72-.82 1.72-1.83V3.1c0-1.01-.77-1.83-1.72-1.83H4.57c-.95 0-1.72.82-1.72 1.83Zm4.58-.61v11H4.57c-.32 0-.57-.27-.57-.61V3.1c0-.34.25-.61.57-.61h2.86Zm1.14 0h2.86c.32 0 .57.27.57.61v9.78c0 .34-.25.61-.57.61H8.57v-11Z" />
    </svg>
  );
};
Icons.Stair = (fillColor = '#2c3e50') => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill={fillColor}
    >
      <path d="M.67 9.5h2.66a.5.5 0 0 0 .5-.5V6.83H6a.5.5 0 0 0 .5-.5V4.17h2.17a.5.5 0 0 0 .5-.5V1.5h2.16a.5.5 0 0 0 0-1H8.67a.5.5 0 0 0-.5.5v2.17H6a.5.5 0 0 0-.5.5v2.16H3.33a.5.5 0 0 0-.5.5V8.5H.67a.5.5 0 0 0 0 1Z" />
    </svg>
  );
};
Icons.Geolocate = (fillColor = '#2c3e50') => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill={fillColor}
    >
      <path d="M79 39.2a2 2 0 0 0-2-2h-5.7A31.48 31.48 0 0 0 42.8 8.7V3a2 2 0 0 0-2-2h-1.6a2 2 0 0 0-2 2v5.7A31.48 31.48 0 0 0 8.7 37.2H3a2 2 0 0 0-2 2v1.6c0 1.1.9 2 2 2h5.7a31.48 31.48 0 0 0 28.5 28.5V77c0 1.1.9 2 2 2h1.6a2 2 0 0 0 2-2v-5.7a31.48 31.48 0 0 0 28.5-28.5H77a2 2 0 0 0 2-2v-1.6ZM42.8 65.7v-5.4a2 2 0 0 0-2-2h-1.6a2 2 0 0 0-2 2v5.4a25.89 25.89 0 0 1-22.9-22.9h5.41a2 2 0 0 0 2-2v-1.6a2 2 0 0 0-2-2h-5.4a25.89 25.89 0 0 1 22.9-22.89v5.4c0 1.1.89 2 2 2h1.59a2 2 0 0 0 2-2v-5.4a25.89 25.89 0 0 1 22.9 22.9h-5.41a2 2 0 0 0-2 2v1.59c0 1.1.9 2 2 2h5.4a25.89 25.89 0 0 1-22.9 22.9ZM40 26.05A13.95 13.95 0 0 0 26.07 40c0 7.68 6.25 13.94 13.93 13.94S53.94 47.68 53.94 40 47.68 26.07 40 26.07Zm0 22.28a8.35 8.35 0 0 1 0-16.68 8.35 8.35 0 0 1 0 16.68Z" />
    </svg>
  );
};

/**
 * SearchResultIcons
 * - SVG icons for mixed keyword search result items
 */
const SearchResultIcons = {};
SearchResultIcons.poi = (fillColor = '#333333') => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fillColor}
    >
      <path d="M20.25 10.25c0-4.98-3.65-8.5-8.25-8.5s-8.25 3.52-8.25 8.5c0 3.05 2.46 7.15 7.74 11.98l.51.46.5-.46c5.29-4.83 7.75-8.93 7.75-11.98Zm-1.5 0c0 1.07-.37 2.27-1.1 3.6-1.08 2-2.96 4.29-5.65 6.8-2.69-2.51-4.57-4.8-5.66-6.8a7.73 7.73 0 0 1-1.09-3.6c0-4.1 2.97-7 6.75-7s6.75 2.9 6.75 7ZM12 7.75a2.25 2.25 0 1 1 0 4.5 2.25 2.25 0 0 1 0-4.5Z" />
    </svg>
  );
};
SearchResultIcons.category = (fillColor = '#333333') => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fillColor}
      fillRule="evenodd"
    >
      <path d="M7.44 4.32a6.17 6.17 0 0 0-7.19 6.34c0 2.26 1.8 5.31 5.72 8.9l.5.46.51-.47c.63-.57 1.2-1.13 1.73-1.67.8.9 1.73 1.83 2.78 2.8l.51.45.5-.46a39.5 39.5 0 0 0 2.77-2.77 37.3 37.3 0 0 0 1.7 1.65l.5.47.51-.47c3.92-3.58 5.72-6.63 5.72-8.9a6.17 6.17 0 0 0-7.14-6.34A7.22 7.22 0 0 0 12 2.75a7.2 7.2 0 0 0-4.56 1.57Zm10.31 5.9c0 .9-.32 1.93-.93 3.06-.94 1.73-2.54 3.67-4.82 5.82a24.92 24.92 0 0 1-4.82-5.82 6.6 6.6 0 0 1-.93-3.06c0-3.49 2.53-5.97 5.75-5.97s5.75 2.48 5.75 5.97Zm.16-4.45a7.71 7.71 0 0 1 1.34 4.45c0 1.79-.97 4-3 6.53.38.4.79.8 1.23 1.23a20.32 20.32 0 0 0 3.95-4.8c.5-.93.77-1.77.77-2.52a4.7 4.7 0 0 0-4.29-4.9Zm-11.82 0a4.7 4.7 0 0 0-4.34 4.89c0 .75.27 1.6.77 2.53a20.35 20.35 0 0 0 3.96 4.79c.45-.43.86-.85 1.25-1.25C5.71 14.2 4.75 12 4.75 10.22c0-1.72.5-3.24 1.34-4.46ZM12 8a2 2 0 1 1 0 4 2 2 0 0 1 0-4Z" />
    </svg>
  );
};
SearchResultIcons.building = (fillColor = '#333333') => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fillColor}
      fillRule="evenodd"
    >
      <path d="M13.75 6.25v-3H2.25v17.5h19.5V6.25h-8Zm-1.5-1.5v14.5h-8.5V4.75h8.5Zm1.5 3v11.5h6.5V7.75h-6.5ZM7 15.5H5.5V17H7v-1.5Zm3.5 0H9V17h1.5v-1.5Zm6 0H15V17h1.5v-1.5Zm2.5 0h-1.5V17H19v-1.5Zm-12-3H5.5V14H7v-1.5Zm3.5 0H9V14h1.5v-1.5Zm6 0H15V14h1.5v-1.5Zm2.5 0h-1.5V14H19v-1.5Zm-12-3H5.5V11H7V9.5Zm3.5 0H9V11h1.5V9.5Zm6 0H15V11h1.5V9.5Zm2.5 0h-1.5V11H19V9.5Zm-12-3H5.5V8H7V6.5Zm3.5 0H9V8h1.5V6.5Z" />
    </svg>
  );
};
export const getSearchResultIcon = (itemType = 'poi') => {
  const iconToken = isFunction(SearchResultIcons[itemType]) ? itemType : 'poi';
  return SearchResultIcons[iconToken]();
};
