import * as _ from 'lodash-es';
import mw, { Z_LEVEL_UPDATER_MIN_ZOOM } from './maps';
import { UI_COLOURS } from '../constants/constants';
import { MAP_ACTIONS } from '../reducers/mapReducer';
import {
  GEOLOCATE_CONFIG,
  isGeolocateStateInvalid,
  compareGeolocatePosition,
} from './geolocate';
import { getHashValuesObject, updateDeepLinkFromApp } from './deepLink';
import { isFunction, isObject } from './utils';
import { isNumber } from './number';
import BIData from '../api/BIData';

export const TRACK_USER_LOCATION_STATUS = {
  STARTED: 'STARTED', // initiated but could be still waiting for device's location data
  IN_PROGRESS: 'IN_PROGRESS', // received first location data from device and keep tracking in progress
  END: 'END', // stopped tracking location
  ERROR: 'ERROR',
};

/**
 * loadNavigationControl
 */
export const loadNavigationControl = () => {
  const { map, store } = mw.getMap();
  const navigationControl = new window.Mazemap.mapboxgl.NavigationControl({
    visualizePitch: true, // enables built-in 'pitch' control along with 'bearing' control icon
  });
  map.addControl(navigationControl, 'bottom-right');
  store.navigationControl = navigationControl;
};

/**
 * loadGeolocateControl
 * @param dispatch
 * Note: API ref: https://docs.mapbox.com/mapbox-gl-js/api/markers/#geolocatecontrol
 */
export const loadGeolocateControl = (dispatch = null) => {
  const { map, store } = mw.getMap();
  const geolocateControl = new window.Mazemap.mapboxgl.GeolocateControl({
    ...GEOLOCATE_CONFIG,
  });
  map.addControl(geolocateControl, 'bottom-right');
  store.geolocateControl = geolocateControl;

  // util
  const dispatchPositionToAppState = (position = null) => {
    if (isFunction(dispatch)) {
      const storedGeolocatePosition = store.geolocateData.prevPosition;
      const isGeolocatePositionUpdated = !compareGeolocatePosition(
        storedGeolocatePosition,
        position,
        ['coords.latitude', 'coords.longitude'],
      );
      isGeolocatePositionUpdated &&
        dispatch({
          type: MAP_ACTIONS.MAP_GEOLOCATE,
          payload: {
            mapGeolocateData: {
              position,
            },
          },
        });
    }
  };

  // setup control event callbacks
  geolocateControl.on('geolocate', (position) => {
    console.debug('[geolocateCtrl]: geolocate: ', position);
    store.geolocateData.prevPosition = store.geolocateData.position;
    store.geolocateData.position = position;
    dispatchPositionToAppState(position);

    // analytics
    // Notes:
    // - only track once each time the geolocation service is started by user
    // - subsequent events are not tracking while user location tracking is in progress
    if (
      store.geolocateData.trackUserLocationStatus ===
      TRACK_USER_LOCATION_STATUS.STARTED
    ) {
      BIData.push(BIData.TAG_LOCATION_ACTIVATION, {
        lng: position.coords.longitude,
        lat: position.coords.latitude,
        accuracy: position.coords.accuracy,
      });
      // update status to prevent tracking on further location update events
      store.geolocateData.trackUserLocationStatus =
        TRACK_USER_LOCATION_STATUS.IN_PROGRESS;
    }
  });
  geolocateControl.on('error', (positionError) => {
    console.warn('[geolocateCtrl]: error: ', positionError);
    store.geolocateData.positionError = positionError;
    store.geolocateData.trackUserLocationStatus =
      TRACK_USER_LOCATION_STATUS.ERROR;
  });
  geolocateControl.on('trackuserlocationstart', () => {
    console.debug('[geolocateCtrl]: trackuserlocationstart');
    store.geolocateData.trackUserLocationStatus =
      TRACK_USER_LOCATION_STATUS.STARTED;
  });
  geolocateControl.on('trackuserlocationend', () => {
    console.debug('[geolocateCtrl]: trackuserlocationend');
    store.geolocateData.trackUserLocationStatus =
      TRACK_USER_LOCATION_STATUS.END;
    // only update null position when geolocate status is 'OFF'
    if (isGeolocateStateInvalid()) {
      store.geolocateData.prevPosition = store.geolocateData.position;
      store.geolocateData.position = null;
      dispatchPositionToAppState(null);
    }
  });
  geolocateControl.on('outofmaxbounds', (position) => {
    // Note: cannot replicate this event
    console.debug('[geolocateCtrl]: outofmaxbounds: ', position);
  });
};

/**
 * initZLevelUpdateListener
 * Notes:
 * - this method is NOT officially documented,
 * - a workaround util vendor provides better recommendations
 * - latest 'campus id' value is updated by ZLevelUpdater and reflected here
 */
const _muMapZLevelUpdaterListener = function muMapZLevelUpdaterListener() {
  const { store } = mw.getMap();

  // CampusId mismatch: hash
  const latestCampusId = mw.getLatestCampusId();
  const hashObject = getHashValuesObject();
  const isLatestCampusIdMismatchHashCampusId =
    isObject(hashObject) &&
    isNumber(hashObject.campusid) &&
    isNumber(latestCampusId) &&
    hashObject.campusid !== latestCampusId;
  if (isLatestCampusIdMismatchHashCampusId) {
    console.debug(
      '_muMapZLevelUpdaterListener:',
      'hashCampusId:',
      hashObject.campusid,
      'latestCampusId:',
      latestCampusId,
    );
  }
  // CampusId mismatch: selected category / type pois
  const storedSelectedPoiTypeCampusId = store.selectedPoiTypeCampusId;
  const isLatestCampusIdMismatchSelectedPoiTypeCampusId =
    isNumber(storedSelectedPoiTypeCampusId) &&
    isNumber(latestCampusId) &&
    storedSelectedPoiTypeCampusId !== latestCampusId;
  if (isLatestCampusIdMismatchSelectedPoiTypeCampusId) {
    console.debug(
      '_muMapZLevelUpdaterListener:',
      'storedSelectedPoiTypeCampusId:',
      storedSelectedPoiTypeCampusId,
      'latestCampusId:',
      latestCampusId,
    );
    mw.clearSelectedPoiTypeValues();
    // TODO: might also need to clear react app state away from CategoryDetails view depends on UX requirement
  }

  updateDeepLinkFromApp({ dataCaller: 'appOnMapZLevelUpdate' });
};
export const initZLevelUpdateListener = () => {
  const { map } = mw.getMap();
  const zLevelUpdaterListeners = _.get(
    map,
    'zLevelUpdater.events._listeners.zlevelsupdate',
    null,
  );
  if (Array.isArray(zLevelUpdaterListeners)) {
    zLevelUpdaterListeners.push(_muMapZLevelUpdaterListener);
  }
};

/**
 * updateZLevelUpdaterOptions
 * @param customOptions
 */
export const updateZLevelUpdaterOptions = ({
  minZoom = Z_LEVEL_UPDATER_MIN_ZOOM,
} = {}) => {
  const { map } = mw.getMap();
  const zLevelUpdaterOptions = _.get(map, 'zLevelUpdater.options', null);
  if (!isObject(zLevelUpdaterOptions)) {
    return;
  }
  zLevelUpdaterOptions.minZoom = minZoom;
  zLevelUpdaterOptions.isCustomised = true;
};

/**
 * updateZLevelControlOptions
 * @param customOptions
 */
export const updateZLevelControlOptions = ({
  activeColor = UI_COLOURS.BLUE_DEFAULT,
} = {}) => {
  const { map } = mw.getMap();
  const zLevelControl = map.zLevelControl;
  if (!isObject(zLevelControl)) {
    return;
  }

  // set customised activeColor
  if (isFunction(zLevelControl.setActiveColor)) {
    zLevelControl.setActiveColor(activeColor);
  }

  // set customised options values
  const zLevelControlOptions = zLevelControl.options;
  if (!isObject(zLevelControlOptions)) {
    return;
  }
  zLevelControlOptions.isCustomised = true;
};
