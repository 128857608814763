import React, { useContext } from 'react';
import { OVERLAY_VIEW_STATE } from '../../constants/constants';
import { MapContext } from '../../contexts/MapContext';

const PanelMapOverlay = ({ footer = null, children = null }) => {
  const { map } = useContext(MapContext);
  const { overlayState } = map.ui;
  const isOverlayHidden = overlayState === OVERLAY_VIEW_STATE.HIDDEN;

  const hasFooter = footer !== null;

  return (
    <div
      className={`panel-map-area${isOverlayHidden ? ' hidden' : ''}`}
      id="mapOverlay"
      data-area="overlay"
    >
      <div className="panel-map-overlay__content">{children}</div>
      {hasFooter && <div className="panel-map-overlay__footer">{footer}</div>}
    </div>
  );
};

export default PanelMapOverlay;
