import React, { createContext, useReducer } from 'react';
import { mapReducer } from '../reducers/mapReducer';
import {
  DIRECTIONS_RESULT_VIEW_STATES,
  SEARCH_CONTROL,
  TRAVEL_MODES,
  TRAVEL_OPTIONS,
  SEARCH_DISPLAY,
  BOTTOM_SHEET_VIEW_STATE,
  OVERLAY_VIEW_STATE,
  BACK_BTN_TARGET,
} from '../constants/constants';

export const DEFAULT_DIRECTIONS_STATE = {
  from: {
    lngLat: null, // {}
    poi: null, // {}
  },
  to: {
    lngLat: null, // {}
    poi: null, // {}
  },
  mode: TRAVEL_MODES.PEDESTRIAN,
  options: {
    [TRAVEL_OPTIONS.AVOID_STAIRS_OBSTACLES.keyText]: true,
  },
};
export const DEFAULT_DIRECTIONS_RESULT_STATE = {
  viewState: DIRECTIONS_RESULT_VIEW_STATES.NEVER_LOADED,
  metrics: null, // {}
  steps: null, // []
  instructions: null, // []
  errorMsg: null, // ''
};
export const DEFAULT_SEARCH_RESULTS_STATE = {
  query: '',
  results: null,
  num_results: null,
  defaultSearchResultsState: null, // null - not searching, loading - loading, finished - finished loading - currently not used
  selectedPoi: {
    poiDetail: null,
  }, // null - not selected, poiDetails - selected, poiMarker - selected
  selectedCategory: {
    typePoisTitle: '',
    typePoisId: null,
    campusId: null,
    poisCount: null,
    pois: null, // []
  },
};
export const DEFAULT_SEARCH_DISPLAY_STATE = {
  component: SEARCH_DISPLAY.SEARCH_CATEGORIES,
  backBtnTarget: BACK_BTN_TARGET.NONE,
  isSearching: false,
  isFetchingCategoryPois: false // used when calling getPoisByTypeIdAndCampusIdAsGeoJSONWithPagination as there can be 400+ as oppose to 100 limit with getPoisByTypeIdAndCampusIdAsGeoJSON
};

export const MapContext = createContext({
  map: {},
  dispatch: () => {},
});

const initialState = {
  monashMap: null,
  mapLoaded: false,
  // map area click data
  mapClickEventData: {
    e: null,
    lngLat: null,
    poi: null,
    count: 0,
  },
  // map geolocate update data
  mapGeolocateData: {
    position: null,
  },
  // UI states
  ui: {
    bottomSheetState: BOTTOM_SHEET_VIEW_STATE.HIDDEN,
    overlayState: OVERLAY_VIEW_STATE.HIDDEN,
    searchControl: SEARCH_CONTROL.SEARCH,
  },
  // location search
  campus: {
    //selected campus from map
    key: null, //campus name
    id: null,
    name: '',
    categories: {},
  },
  // categories
  categoriesIcons: {},
  // directions search
  directions: {
    ...DEFAULT_DIRECTIONS_STATE,
  },
  // directions search result
  directionsResult: {
    ...DEFAULT_DIRECTIONS_RESULT_STATE,
  },
  // Search result
  searchResults: {
    ...DEFAULT_SEARCH_RESULTS_STATE,
  },
  // Search content display
  searchDisplay: {
    ...DEFAULT_SEARCH_DISPLAY_STATE,
  },
};

const MapContextProvider = (props) => {
  const [map, dispatch] = useReducer(mapReducer, initialState);
  return (
    <MapContext.Provider value={{ map, dispatch }}>
      {props.children}
    </MapContext.Provider>
  );
};

export default MapContextProvider;
