import React from 'react';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import QRCodeDisplay from '../qrCodeDisplay/qrCodeDisplay';

import s from './ShareQRCode.module.scss';

const ShareQRCode = () => {
  const isMobile = useMediaQuery();
  if (isMobile) {
    return null; // desktop only
  }

  const deepLink = window.location.href;
  return (
    <div className={s.ShareQRCodeContainer} aria-hidden={true}>
      <div className={s.ShareQRCode} data-test-id="shareQRCode">
        <QRCodeDisplay dataString={deepLink} />
      </div>
      <p className={s.ShareQRCodeText}>
        Scan the QR code to open it on your mobile device
      </p>
    </div>
  );
};

export default ShareQRCode;
