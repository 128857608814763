import * as _ from 'lodash-es';
import mw from './maps';
import { isFunction, isObject } from './utils';
import { isStringEmpty } from './string';
import { isNumber } from './number';
import { BOTTOM_SHEET_VIEW_STATE } from '../constants/constants';
import { MAP_ACTIONS, UI_ACTIONS } from '../reducers/mapReducer';

// constants
export const GEOLOCATE_CONFIG = {
  fitBoundsOptions: {
    maxZoom: 18, // how far to zoom in for accurate locations
  },
  positionOptions: {
    enableHighAccuracy: true,
    timeout: 10000, // for getting a response from device's location services
  },
  showAccuracyCircle: true,
  showUserLocation: true, // dot for user's location
  showUserHeading: true, // arrow for device's heading
  trackUserLocation: true, // if map will track location when active
};
export const GEOLOCATE_WATCH_STATE = {
  OFF: 'OFF', // not watching location at all, no geo tracking data updates
  WAITING_ACTIVE: 'WAITING_ACTIVE',
  BACKGROUND: 'BACKGROUND', // has geo tracking data updates, no active tracking, nor map camera follow
  BACKGROUND_ERROR: 'BACKGROUND_ERROR',
  ACTIVE_LOCK: 'ACTIVE_LOCK', // has geo tracking data updates, active tracking and map camera follow
  ACTIVE_ERROR: 'ACTIVE_ERROR',
};

// utils
export const isGeolocateState = (state) => {
  const { store } = mw.getMap();
  return store.geolocateControl._watchState === state;
};
export const isGeolocateOff = () => {
  const { store } = mw.getMap();
  return store.geolocateControl._watchState === GEOLOCATE_WATCH_STATE.OFF;
};
export const isGeolocateBackground = () => {
  const { store } = mw.getMap();
  return (
    store.geolocateControl._watchState === GEOLOCATE_WATCH_STATE.BACKGROUND
  );
};
export const isGeolocateActive = () => {
  const { store } = mw.getMap();
  return (
    store.geolocateControl._watchState === GEOLOCATE_WATCH_STATE.ACTIVE_LOCK
  );
};
export const isGeolocateStateInvalid = () => {
  return !isGeolocateActive() && !isGeolocateBackground();
};
export const getGeolocateState = () => {
  const { store } = mw.getMap();
  return store.geolocateControl._watchState;
};
export const isNavCtaAvailable = ({ from, to }) => {
  if (!isObject(from) || !isObject(to)) {
    return false;
  }
  // make sure from point data is from geolocation
  return from.isGeolocateData === true;
};
export const compareGeolocatePosition = (
  posA,
  posB,
  compareValuesList = [],
) => {
  if (isObject(posA) && isObject(posB)) {
    // both are objects, need further deep compare
    const valuesToCompare = Array.isArray(compareValuesList)
      ? [...compareValuesList]
      : [];
    let result = true;
    for (let i = 0; i < valuesToCompare.length; i += 1) {
      const valuePath = valuesToCompare[i];
      if (isStringEmpty(valuePath)) {
        continue;
      }
      const valueA = _.get(posA, valuePath);
      const valueB = _.get(posB, valuePath);
      if (valueA !== valueB) {
        result = false;
        break;
      }
    }
    return result;
  } else {
    // Notes: both null: pass; mixed null: no pass
    return posA === null && posB === null;
  }
};

/**
 * updateGeolocateControlFitBoundsOptions
 * Notes:
 * - need to provide current bearing and pitch since v2.0.92 (Mapbox GL version 2.12.0)
 * - https://github.com/mapbox/mapbox-gl-js/pull/12367
 */
export const updateGeolocateControlFitBoundsOptions = () => {
  const { map, store } = mw.getMap();
  const { geolocateControl } = store;

  if (
    isObject(geolocateControl) &&
    isObject(geolocateControl.options) &&
    isObject(geolocateControl.options.fitBoundsOptions)
  ) {
    const mapBearing = map.getBearing();
    const mapPitch = map.getPitch();

    // only updates option when there's value updates
    if (
      geolocateControl.options.fitBoundsOptions.bearing !== mapBearing ||
      geolocateControl.options.fitBoundsOptions.pitch !== mapPitch
    ) {
      geolocateControl.options.fitBoundsOptions = {
        ...geolocateControl.options.fitBoundsOptions,
        bearing: mapBearing,
        pitch: mapPitch,
      };
      console.debug(
        '[updateGeolocateControlFitBoundsOptions]: ',
        geolocateControl.options.fitBoundsOptions,
      );
    }
  }
};

/**
 * toggleGeolocate
 * Note:
 * - uses geolocateControl.trigger from mapBox
 * - geolocateControl.trigger toggles mapBox geolocate watch state
 */
export const toggleGeolocate = () => {
  const { store } = mw.getMap();
  const { geolocateControl } = store;
  if (isObject(geolocateControl) && isFunction(geolocateControl.trigger)) {
    geolocateControl.trigger();
  }
};

/**
 * sendGeoAsRoutePoint
 * @param dispatch
 * @param isFrom
 * @returns {Function} a success callback for geolocation data
 */
export const sendGeoAsRoutePoint =
  (dispatch, isFrom = true) =>
  (lng, lat) => {
    if (!isFunction(dispatch) || !isNumber(lng) || !isNumber(lat)) {
      return;
    }
    dispatch({
      type: isFrom
        ? MAP_ACTIONS.UPDATE_DIRECTION_POINT_FROM
        : MAP_ACTIONS.UPDATE_DIRECTION_POINT_TO,
      payload: {
        [isFrom ? 'from' : 'to']: {
          lngLat: {
            lng,
            lat,
          },
          poi: false,
          isGeolocateData: true, // this marks the Point data is from Geolocation
        },
      },
    });
  };

/**
 * getGeoLocationAsRoutePoint
 */
export const getGeoLocationAsRoutePoint = (successCallback, errorCallback) => {
  if (!isFunction(successCallback) || !isFunction(errorCallback)) {
    return;
  }
  const { store } = mw.getMap();
  const { geolocateControl } = store;
  if (!isObject(geolocateControl)) {
    return;
  }

  // get lng lat
  if (isObject(geolocateControl._lastKnownPosition)) {
    const lng = _.get(
      geolocateControl,
      '_lastKnownPosition.coords.longitude',
      null,
    );
    const lat = _.get(
      geolocateControl,
      '_lastKnownPosition.coords.latitude',
      null,
    );
    successCallback(lng, lat);
  } else {
    geolocateControl.trigger();
    geolocateControl.once('geolocate', (position) => {
      if (!isObject(position)) return;
      const lng = _.get(position, 'coords.longitude', null);
      const lat = _.get(position, 'coords.latitude', null);
      successCallback(lng, lat);
    });
    geolocateControl.once('error', () => {
      errorCallback();
    });
  }
};

/**
 * startDirectionsNavigation
 */
export const startDirectionsNavigation = (dispatch) => {
  if (isGeolocateActive()) {
    return;
  }

  // update UI first
  const hasDispatch = isFunction(dispatch);
  if (hasDispatch) {
    dispatch({
      type: UI_ACTIONS.UPDATE_UI_BOTTOM_SHEET,
      payload: {
        bottomSheetState: BOTTOM_SHEET_VIEW_STATE.COLLAPSED,
      },
    });
  }

  // call geolocation after UI settles
  setTimeout(
    () => {
      if (!isGeolocateActive()) {
        toggleGeolocate();
      }
    },
    hasDispatch ? 50 : 0, // only apply delay is dispatch UI updates
  );
};
