import * as _ from 'lodash-es';
import mw from '../utils/maps';
import {
  DEFAULT_SEARCH_DISPLAY_STATE,
  DEFAULT_SEARCH_RESULTS_STATE,
} from '../contexts/MapContext';
import {
  BACK_BTN_TARGET,
  BOTTOM_SHEET_VIEW_STATE,
  SEARCH_DISPLAY,
} from '../constants/constants';
import {
  transformPoiDetailsToPoint,
  updateDeepLinkFromApp,
} from '../utils/deepLink';
import { isObject } from '../utils/utils';
import { isStringEmpty } from '../utils/string';

const updateSearchResult = (state, action) => {
  return {
    ...state,
    searchResults: {
      ...state.searchResults,
      results: Array.isArray(action.payload.results)
        ? [...action.payload.results]
        : null,
      num_results: action.payload.num_results,
    },
  };
};

const updateSearchResultQuery = (state, action) => {
  return {
    ...state,
    searchResults: {
      ...state.searchResults,
      query: action.payload.query,
    },
  };
};

const searchPoiSelected = (state, action) => {
  const poiDetail = _.get(action, 'payload.poiDetail', null);

  // persist to map store so can be used by deeplink
  const { store } = mw.getMap();
  store.selectedPoiPoint = transformPoiDetailsToPoint(poiDetail);
  updateDeepLinkFromApp({ dataCaller: 'searchResultPoiSelect' });

  return {
    ...state,
    searchResults: {
      ...state.searchResults,
      selectedPoi: {
        ...state.searchResults.selectedPoi,
        poiDetail,
      },
    },
  };
};

const searchCategorySelected = (state, action) => {
  const categoryDetail = _.get(action, 'payload', null);
  if (!isObject(categoryDetail)) {
    return state;
  }

  // persist to map store so can be used by deeplink
  const { store } = mw.getMap();
  store.selectedPoiTypeId = categoryDetail.typePoisId || null;
  store.selectedPoiTypeCampusId = categoryDetail.campusId || null;
  updateDeepLinkFromApp({ dataCaller: 'searchResultCategorySelect' });

  return {
    ...state,
    searchResults: {
      ...state.searchResults,
      selectedCategory: {
        ...state.searchResults.selectedCategory,
        ...categoryDetail,
      },
    },
  };
};

const resetMapSearchContextToInitialState = (state, action) => {
  // update map store as well for deep link
  mw.clearSelectedPoiValues();
  // need to manually call map resize here as this affects map canvas size
  mw.resizeMap();

  return {
    ...state,
    searchResults: {
      ...state.searchResults,
      ...DEFAULT_SEARCH_RESULTS_STATE,
    },
    ui: {
      ...state.ui,
      bottomSheetState: BOTTOM_SHEET_VIEW_STATE.HIDDEN,
      // overlay is controlled outside of this due to multiple scenarios
    },
    searchDisplay: {
      ...state.searchDisplay,
      ...DEFAULT_SEARCH_DISPLAY_STATE,
    },
  };
};

const updateUiSearchDisplay = (state, action) => {
  const targetSearchDisplay = action.payload.component;
  const targetBackBtn = !isStringEmpty(action.payload.backBtnTarget)
    ? action.payload.backBtnTarget
    : BACK_BTN_TARGET.NONE;
  const shouldClearSelectedPoi =
    targetSearchDisplay !== SEARCH_DISPLAY.SEARCH_RESULT_POI_DETAIL;
  const shouldClearSelectedCategory =
    targetSearchDisplay !== SEARCH_DISPLAY.SEARCH_RESULT_CATEGORY_DETAIL &&
    targetSearchDisplay !== SEARCH_DISPLAY.SEARCH_RESULT_POI_DETAIL && // need to go back to category selected view
    targetSearchDisplay !== SEARCH_DISPLAY.SEARCH_MIXED; // need to go back to mixed search result view (desktop only)

  // update map store as well for deep link
  if (shouldClearSelectedPoi) {
    mw.clearSelectedPoiValues();
  }
  if (shouldClearSelectedCategory) {
    mw.clearSelectedPoiTypeValues();
  }

  return {
    ...state,
    searchDisplay: {
      ...state.searchDisplay,
      component: targetSearchDisplay,
      backBtnTarget: targetBackBtn,
      isSearching: action.payload.isSearching || false,
      isFetchingCategoryPois: action.payload.isFetchingCategoryPois || false,
    },
    searchResults: {
      ...state.searchResults,
      selectedPoi: {
        ...state.searchResults.selectedPoi,
        poiDetail: shouldClearSelectedPoi
          ? null
          : {
              ...state.searchResults.selectedPoi.poiDetail,
            },
      },
      selectedCategory: shouldClearSelectedCategory
        ? {
            ...DEFAULT_SEARCH_RESULTS_STATE.selectedCategory,
          }
        : {
            ...state.searchResults.selectedCategory,
          },
    },
  };
};

const resetMapSearchContextForMobileCategorySelection = (state) => {
  // update map store as well for deep link
  mw.clearSelectedPoiValues();
  // need to manually call map resize here as this affects map canvas size
  mw.resizeMap();

  return {
    ...state,
    searchResults: {
      ...state.searchResults,
      ...DEFAULT_SEARCH_RESULTS_STATE,
    },
    searchDisplay: {
      ...state.searchDisplay,
      ...DEFAULT_SEARCH_DISPLAY_STATE,
    },
  };
};

const searchResultsReducers = {
  updateSearchResult,
  resetMapSearchContextToInitialState,
  updateSearchResultQuery,
  searchPoiSelected,
  searchCategorySelected,
  updateUiSearchDisplay,
  resetMapSearchContextForMobileCategorySelection,
};

export default searchResultsReducers;
