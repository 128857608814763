import * as _ from 'lodash-es';
import BIData from '../../api/BIData';
import React, { useState, useContext } from 'react';
import mw from '../../utils/maps';
import { MAP_ACTIONS, UI_ACTIONS } from '../../reducers/mapReducer';
import { MapContext } from '../../contexts/MapContext';
import { isNumber } from '../../utils/number';
import { isObject } from '../../utils/utils';
import { isStringEmpty } from '../../utils/string';
import { removeFromURLHash } from '../../utils/deepLink';
import { SEARCH_DISPLAY } from '../../constants/constants';

import s from './CampusCategoryFilterMobile.module.scss';

export default function CampusCategoryFilterMobile() {
  const { map, dispatch } = useContext(MapContext);
  const campusDetails = mw.CAMPUS_DETAILS;
  const campusSelected = map.campus.key;
  const campusCategoryData = map.campus.categories;
  const { searchResults, searchDisplay } = map;
  const [selectedCampusCategoryId, setSelectedCampusCategoryId] = useState('');

  React.useEffect(() => {
    // WEBSP-455: search via text box, clear categories dropdown
    // console.debug("searchDisplay.component", searchDisplay)
    if (
      searchResults.query !== '' &&
      (searchDisplay.component ===
        SEARCH_DISPLAY.SEARCH_RESULT_CATEGORY_DETAIL ||
        searchDisplay.component === SEARCH_DISPLAY.SEARCH_RESULT_POI_DETAIL)
    ) {
      // console.debug('searched via query - reset campus categories selection')
      if (selectedCampusCategoryId !== '') setSelectedCampusCategoryId('');
    }

    return () => {
      // cleanup
    };
  }, [searchDisplay, searchResults.query, selectedCampusCategoryId]);

  const campusChangeHandler = (e) => {
    const campusKey = e.target.val || e.target.value;

    if (campusKey && campusKey !== '') {
      const campusId = campusDetails[campusKey].id;
      const name = campusDetails[campusKey].name;
      dispatch({
        type: MAP_ACTIONS.UPDATE_CAMPUS,
        payload: { campusKey: campusKey, id: campusId, name },
      });

      mw.switchCampus(campusKey);
      if (!campusCategoryData.hasOwnProperty(campusKey)) {
        mw.getCampusCategories(campusId, dispatch).then((data) => {
          const sorted = _.sortBy(data, 'title');
          dispatch({
            type: MAP_ACTIONS.UPDATE_CAMPUS_CATEGORIES,
            payload: {
              categories: {
                ...campusCategoryData,
                [campusKey]: sorted,
              },
            },
          });
          console.debug('campusCategoryData:', data);
        });
      }
    } else {
      console.error(`Campus key not found: ${campusKey}`);
    }
  };

  const handleCategorySelect = (e) => {
    e.preventDefault();
    const selectNode = e && e.target;
    if (!isObject(selectNode)) {
      return false;
    }

    const poiTypeTitle =
      selectNode.options[selectNode.selectedIndex].text || 'Category';
    const poiTypeId = selectNode.value || '';
    const campusId = campusDetails[campusSelected].id;
    if (
      isStringEmpty(poiTypeTitle) ||
      isStringEmpty(poiTypeId) ||
      !isNumber(campusId)
    ) {
      // WEBSP-455: allow to select no category
      if (selectedCampusCategoryId !== '') {
        mw.clearSelectedPoiValues();
        removeFromURLHash('typepois');
        setSelectedCampusCategoryId('');
      }

      return false;
    }

    // WEBSP-455: reset search query when categories are selected
    if (searchResults.query !== '') {
      dispatch({
        // reset mobile search for category
        type: UI_ACTIONS.RESET_MAP_SEARCH_CONTEXT_TO_INITIAL_STATE_FOR_MOBILE_CATEGORY_SELECTION,
        payload: null,
      });
    }

    // analytics
    BIData.push(BIData.TAG_SEARCH_CATEGORY_SELECTED, {
      campusId,
      title: poiTypeTitle,
      poiTypeId: parseInt(poiTypeId),
    });

    setSelectedCampusCategoryId(poiTypeId);
    mw.loadTypePoisForCampus(
      {
        poiTypeTitle,
        poiTypeId,
        campusId,
        customProps: {
          dataTrigger: 'campusFilterResult',
          dataIsMobile: true,
        },
      },
      dispatch,
    );
  };

  return (
    <div className={s.campusContainer}>
      {/* campus selector */}
      <div className={s.selectWrapper}>
        <select
          className={s.selectCss}
          onChange={campusChangeHandler}
          value={campusSelected || ''}
          aria-label="select campus"
        >
          <option value="" disabled>
            Select campus
          </option>
          {Object.keys(campusDetails).map(function (key) {
            return (
              <option key={key} value={key}>
                {campusDetails[key].name}
              </option>
            );
          })}
        </select>
      </div>
      {/* Category POIs */}
      {campusSelected &&
        campusCategoryData &&
        campusCategoryData[campusSelected] && (
          <div className={s.pills}>
            <select
              className={`${s.selectCss} ${s.selectCategory}`}
              onChange={handleCategorySelect}
              value={selectedCampusCategoryId || ''}
              aria-label="select campus location category"
            >
              <option value="">-- Campus Categories --</option>
              {campusCategoryData[campusSelected]?.map((el, index) => {
                return (
                  <option className={s.pill} key={index} value={el.id}>
                    {el.title}
                  </option>
                );
              })}
            </select>
          </div>
        )}
    </div>
  );
}
