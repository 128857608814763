import * as _ from 'lodash-es';
import BIData from '../../api/BIData';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import {
  BOTTOM_SHEET_VIEW_STATE,
  OVERLAY_VIEW_STATE,
  SEARCH_CONTROL,
  SEARCH_DISPLAY,
} from '../../constants/constants';
import { isCampusDataAvailableForId } from '../../utils/data/campusData';
import { isMediaQuery, useMediaQuery } from '../../hooks/useMediaQuery';
import { MAP_ACTIONS, UI_ACTIONS } from '../../reducers/mapReducer';
import { MapContext } from '../../contexts/MapContext';
import mw from '../../utils/maps';
import { getHashValuesObject } from '../../utils/deepLink';
import { isStringEmpty } from '../../utils/string';
import { isNumber } from '../../utils/number';
import { isObject } from '../../utils/utils';
import CategoryResults, {
  formatCategoryResultDataList,
} from '../lists/CategoryResults';
import CampusCategoryFilterDesktop from '../CampusFilters/CampusCategoryFilterDesktop';
import CampusCategoryFilterMobile from '../CampusFilters/CampusCategoryFilterMobile';
import SearchControlSection from './SearchControlSection';
import DirectionsSearch from '../directions/DirectionsSearch';
import DirectionsResult from '../directions/DirectionsResult';
import SearchResults from './SearchResults';
import PoiDetails from './PoiDetails';
import CategoryDetails from './CategoryDetails';
import SearchControlInputField from './SearchControlInputField';
import SearchControlSearchIcon from './SeachControlSearchIcon';
import SearchControlBackButton from './SeachControlBackButton';
import SearchControlClearButton from './SeachControlClearButton';
import Loader from '../loader/Loader';
import ReportIssueCTA from '../buttons/ReportIssueCTA';
import LoadingPanel from './LoadingPanel'

import s from './SearchControl.module.scss';

export default function SearchControl({
  hasMapLoaded = false,
  categoryResultsEnabled = true,
}) {
  // app states
  const { map, dispatch } = useContext(MapContext);
  const { searchControl, bottomSheetState, overlayState } = map.ui;
  const isOverlayVisible = overlayState === OVERLAY_VIEW_STATE.VISIBLE;
  const isBottomSheetExpanded =
    bottomSheetState === BOTTOM_SHEET_VIEW_STATE.EXPANDED;
  const searchDisplay = _.get(map, 'searchDisplay.component');
  const isSearching = _.get(map, 'searchDisplay.isSearching');
  const isFetchingCategoryPois = _.get(map, 'searchDisplay.isFetchingCategoryPois');

  // search field
  const searchInputTerm = map.searchResults.query;

  // campus selection
  const campusDetails = mw.CAMPUS_DETAILS;
  const [campusKeySelected, setCampusKeySelected] = useState('');
  const [campusIdSelected, setCampusIdSelected] = useState('');
  const mapCampusId = _.get(map, 'campus.id');
  const mapCampusCategoryData = _.get(map, 'campus.categories');

  // View
  const isMobile = useMediaQuery();

  // campus radio selector handler
  const campusRadioChangeHandler = useCallback(
    (e, campusId, key, moveToCampusBoundary = true) => {
      console.debug('Switch campusRadioChangeHandler: ', campusId, key);

      if (isCampusDataAvailableForId(campusId) && e.target.checked) {
        setCampusIdSelected(e.target.val || e.target.value);
        setCampusKeySelected(key);

        dispatch({
          // missing from desktop, consistent with mobile.
          type: MAP_ACTIONS.UPDATE_CAMPUS,
          payload: {
            campusKey: key,
            id: campusId,
            name: isObject(campusDetails[key]) ? campusDetails[key].name : '',
          },
        });

        // move map if required
        if (moveToCampusBoundary && !isStringEmpty(key)) {
          mw.switchCampus(key);
        }

        // saves the category data in state to avoid subsequent requests for same campus
        if (!mapCampusCategoryData.hasOwnProperty(key)) {
          mw.getCampusCategories(campusId, dispatch).then((data) => {
            const sorted = _.sortBy(data, 'title');
            dispatch({
              type: MAP_ACTIONS.UPDATE_CAMPUS_CATEGORIES,
              payload: {
                categories: {
                  ...mapCampusCategoryData,
                  [key]: sorted,
                },
              },
            });
            console.debug('Switch mapCampusCategoryData:', data);
          });
        }
      }
    },
    [mapCampusCategoryData, campusDetails, dispatch],
  );

  // switches campus context for categories without moving the map
  const switchCampusContextAndLoadCategories = useCallback(
    (newCampusId) => {
      // switch campus via campus id and details
      if (isNumber(newCampusId)) {
        const campusDetails = mw.getCampusById(newCampusId);
        const campusId = newCampusId;
        const campusKey =
          isObject(campusDetails) && typeof campusDetails.name === 'string'
            ? campusDetails.name.toLowerCase()
            : '';
        campusRadioChangeHandler(
          {
            target: {
              value: campusId,
              checked: true,
            },
          },
          campusId,
          campusKey,
          false, // without moving the map
        );
      }
    },
    [campusRadioChangeHandler],
  );

  // map initial load category display
  useEffect(() => {
    if (hasMapLoaded) {
      // work out target campus id
      const targetCampusId =
        mapCampusId ||
        mw.getCampusInfoFromMap()?.id ||
        getHashValuesObject()?.campusid ||
        null;

      // switch and load
      switchCampusContextAndLoadCategories(targetCampusId);
    }
  }, [hasMapLoaded, mapCampusId, switchCampusContextAndLoadCategories]);

  // category item handler
  const categoryResultsItemClickHandler = (data) => {
    const poiTypeTitle = `${data.title || 'Category'}`;
    const poiTypeId = `${data.id}`;
    const campusId = campusDetails[campusKeySelected].id;

    dispatch({
      type: UI_ACTIONS.UPDATE_UI_SEARCH_DISPLAY,
      payload: {
        
        isFetchingCategoryPois: true,
      },
    });    


    // analytics
    BIData.push(BIData.TAG_SEARCH_CATEGORY_SELECTED, {
      campusId,
      title: poiTypeTitle,
      poiTypeId: data.id,
    });

    mw.loadTypePoisForCampus(
      {
        poiTypeTitle,
        poiTypeId,
        campusId,
        customProps: {
          dataTrigger: 'campusFilterResult',
          dataIsMobile: false,
        },
      },
      dispatch,
    );
  };

  // search section display flags
  const isInitialSearch = searchControl === SEARCH_CONTROL.SEARCH;
  const isDirectionsSearch = searchControl === SEARCH_CONTROL.DIRECTIONS;
  const isSearchResults = searchDisplay === SEARCH_DISPLAY.SEARCH_MIXED;
  const isPoiDetails =
    searchDisplay === SEARCH_DISPLAY.SEARCH_RESULT_POI_DETAIL;
  const isCategoryDetails =
    searchDisplay === SEARCH_DISPLAY.SEARCH_RESULT_CATEGORY_DETAIL;

  const shouldDisplayCategorySearchControl = isMobile
    ? !isBottomSheetExpanded && !isOverlayVisible
    : searchInputTerm === '' &&
      !isOverlayVisible &&
      !isPoiDetails &&
      !isCategoryDetails;
  const shouldHideSearchInputWrapper = () => {
    // only hide the search control on mobile when bottom sheet expanded
    return isBottomSheetExpanded && isMediaQuery();
  };
  
  return (
    hasMapLoaded && (
      <>
        {/* initial search control */}
        <SearchControlSection
          section={SEARCH_CONTROL.SEARCH}
          isHidden={!isInitialSearch}
          footer={<ReportIssueCTA />}
          mobilePassThrough={true}
        >
          <section
            className={`${s.SearchWrapper}${isInitialSearch ? '' : ' hidden'}`}
          >
            {/*Loading control*/}
            {isFetchingCategoryPois && <LoadingPanel />}            
          
            {/*default control*/}
            <div className={s.SearchControlDefault}>
              <div
                className={`${s.SearchInputWrapper} ${
                  shouldHideSearchInputWrapper() ? 'hidden' : ''
                }`}
              >
                <SearchControlInputField />
                <SearchControlSearchIcon />
                <SearchControlBackButton />
                <SearchControlClearButton />
                {isSearching && (
                  <div className={s.SearchLoaderWrapper}>
                    <Loader />
                  </div>
                )}
              </div>
            </div>
            {/*extra control*/}
            <div
              className={`${s.SearchControlExtra} ${
                shouldDisplayCategorySearchControl ? '' : 'hidden'
              }`}
            >
              {/* desktop */}
              {!isMobile && (
                <CampusCategoryFilterDesktop
                  campusIdSelected={campusIdSelected}
                  campusRadioChangeHandler={campusRadioChangeHandler}
                />
              )}
              {/* mobile */}
              {isMobile && <CampusCategoryFilterMobile />}
            </div>
            {/* Mixed search results */}
            {!isMobile && isSearchResults && (
              <SearchResults instance="desktop" />
            )}
            {/* Campus Category POI results */}
            {categoryResultsEnabled && shouldDisplayCategorySearchControl && (
              <CategoryResults
                dataList={formatCategoryResultDataList(
                  mapCampusCategoryData[campusKeySelected],
                )}
                onItemClickHandler={categoryResultsItemClickHandler}
              />
            )}
            {/* POI Details*/}
            {!isMobile && isPoiDetails && <PoiDetails instance="desktop" />}
            {/* Category Details*/}
            {!isMobile && isCategoryDetails && (
              <CategoryDetails instance="desktop" />
            )}
          </section>
        </SearchControlSection>

        {/* search control section - Directions */}
        <SearchControlSection
          section={SEARCH_CONTROL.DIRECTIONS}
          isHidden={!isDirectionsSearch}
          footer={<ReportIssueCTA />}
        >
          <DirectionsSearch
            instance="desktop"
            customClassName="sub-sec-border"
          />
          <div className="padded-container">
            <DirectionsResult instance="desktop" />
          </div>
        </SearchControlSection>
      </>
    )
  );
}
