const updateMapLoaded = (state, action) => {
  return {
    ...state,
    mapLoaded: action.payload.mapLoaded,
    monashMap: action.payload.monashMap || false,
  };
};

const updateMapClick = (state, action) => {
  const newCount = state.mapClickEventData.count + 1;
  return {
    ...state,
    mapClickEventData: {
      ...state.mapClickEventData,
      ...action.payload.mapClickEventData,
      count: newCount,
    },
  };
};

const updateMapGeolocate = (state, action) => {
  return {
    ...state,
    mapGeolocateData: {
      ...action.payload.mapGeolocateData,
    },
  };
};

const mapReducers = {
  updateMapLoaded,
  updateMapClick,
  updateMapGeolocate,
};
export default mapReducers;
