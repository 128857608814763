import React from 'react';
import { TRAVEL_STEP_TYPES } from '../../../constants/constants';
import { isStringEmpty } from '../../../utils/string';
import { isNumber } from '../../../utils/number';
import IconWrapper, { Icons } from '../../icons/Icons';

/**
 * getTravelStepTypeIcon
 * @param type
 * @returns {null|*}
 */
export const getTravelStepTypeIcon = (type = '') => {
  let icon;

  switch (type) {
    case TRAVEL_STEP_TYPES.FOOT:
      icon = Icons.Walk();
      break;
    case TRAVEL_STEP_TYPES.ROAD:
      icon = Icons.Cycle();
      break;
    case TRAVEL_STEP_TYPES.CAR:
      icon = Icons.Car();
      break;
    case TRAVEL_STEP_TYPES.BUS:
    case TRAVEL_STEP_TYPES.TRAM:
    case TRAVEL_STEP_TYPES.RAIL:
      icon = Icons.Train();
      break;
    case TRAVEL_STEP_TYPES.BUILDING_ENTER:
    case TRAVEL_STEP_TYPES.BUILDING_EXIT:
      icon = Icons.Building();
      break;
    case TRAVEL_STEP_TYPES.ELEVATOR_UP:
    case TRAVEL_STEP_TYPES.ELEVATOR_DOWN:
      icon = Icons.Elevator();
      break;
    case TRAVEL_STEP_TYPES.STAIR:
    case TRAVEL_STEP_TYPES.STAIR_UP:
    case TRAVEL_STEP_TYPES.STAIR_DOWN:
      icon = Icons.Stair();
      break;
    default:
      icon = null;
  }

  return icon === null ? null : <IconWrapper>{icon}</IconWrapper>;
};

/**
 * getTravelStepTypeLabel
 * @param type
 * @returns {null|string}
 */
export const getTravelStepTypeLabel = (type = '') => {
  let label;

  switch (type) {
    case TRAVEL_STEP_TYPES.FOOT:
      label = 'Walk';
      break;
    case TRAVEL_STEP_TYPES.ROAD:
      label = 'Cycle';
      break;
    case TRAVEL_STEP_TYPES.CAR:
      label = 'Drive';
      break;
    case TRAVEL_STEP_TYPES.BUS:
    case TRAVEL_STEP_TYPES.TRAM:
    case TRAVEL_STEP_TYPES.RAIL:
      label = 'Public Transport';
      break;
    case TRAVEL_STEP_TYPES.BUILDING_ENTER:
      label = 'Enter Building';
      break;
    case TRAVEL_STEP_TYPES.BUILDING_EXIT:
      label = 'Exit Building';
      break;
    case TRAVEL_STEP_TYPES.ELEVATOR_UP:
      label = 'Elevator Up';
      break;
    case TRAVEL_STEP_TYPES.ELEVATOR_DOWN:
      label = 'Elevator Down';
      break;
    case TRAVEL_STEP_TYPES.STAIR:
      label = 'Stair';
      break;
    case TRAVEL_STEP_TYPES.STAIR_UP:
      label = 'Stair Up';
      break;
    case TRAVEL_STEP_TYPES.STAIR_DOWN:
      label = 'Stair Down';
      break;
    default:
      label = null;
  }

  return isStringEmpty(label) ? '' : label;
};

/**
 * getDurationString
 * @param duration
 * @param isDurationInSeconds
 * @param unit
 * @returns {string}
 */
export const getDurationString = (
  duration = null,
  isDurationInSeconds = false,
  unit = 'min',
) => {
  if (!isNumber(duration)) {
    return '';
  }
  const intDuration = Math.ceil(isDurationInSeconds ? duration / 60 : duration);
  const isPlural = intDuration > 1;
  return `${intDuration} ${unit}${isPlural ? 's' : ''}`;
};

/**
 * getDistanceString
 * @param distance
 * @param unit
 * @returns {string}
 */
export const getDistanceString = (distance = null, unit = 'm') => {
  if (!isNumber(distance)) {
    return '';
  }
  const intDistance = Math.ceil(distance);
  let intDistanceKm;
  if (intDistance > 1000) {
    intDistanceKm = (intDistance / 1000).toFixed(1);
  }
  return !!intDistanceKm ? `${intDistanceKm}km` : `${intDistance}${unit}`;
};
