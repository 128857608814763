import React from 'react';
import { isStringEmpty } from '../../utils/string';
import Feedback, { FEEDBACK_FORM_URL } from '../form/feedback/Feedback';

import s from './ReportIssueCTA.module.scss';

const ReportIssueCTA = ({
  labelText = 'Report an issue',
  targetUrl = FEEDBACK_FORM_URL,
  targetBlank = true,
}) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const isCTAInvalid = isStringEmpty(labelText) || isStringEmpty(targetUrl);
  if (isCTAInvalid) return null;

  // prep CTA render props
  const ctaProps = {
    className: s.BtnReportIssue,
    href: targetUrl,
  };
  if (targetBlank) {
    ctaProps.target = '_blank';
    ctaProps.rel = 'noopener noreferrer';
  }

  // CTA click handler
  const handleOnReportCTAClick = (e) => {
    e.preventDefault();
    setModalIsOpen(true);
  };

  // modal handler
  const onFeedbackModalClose = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <Feedback isOpen={modalIsOpen} onCloseHandler={onFeedbackModalClose} />
      <a {...ctaProps} onClick={handleOnReportCTAClick}>
        {labelText}
      </a>
    </>
  );
};

export default ReportIssueCTA;
