import React from 'react';
import {
  getDistanceString,
  getDurationString,
  getTravelStepTypeIcon,
  getTravelStepTypeLabel,
} from './TravelSteps.utils';

import s from './TravelSteps.module.scss';

/**
 * TravelSteps
 */
const TravelSteps = ({ steps = null }) => {
  if (!Array.isArray(steps)) {
    return null;
  }
  const hasData = !!steps.length;

  return hasData ? (
    <div
      className={`travel-result-steps ${s.TravelSteps}`}
      data-dir-result-sec="steps"
    >
      <div className={`travel-result-steps__list ${s.TravelStepsList}`}>
        {steps.map((item, index) => {
          const step = index + 1;
          const id = `travel-result-steps-item${step}`;
          const {
            type = '',
            durationMinutes = null, // optional
            distanceMeters = null, // optional
          } = item;
          const typeLabel = getTravelStepTypeLabel(type);
          const itemSRLabel = `
            Step ${step}:
            ${typeLabel}
            ${durationMinutes ? `${durationMinutes} minutes` : ''}
            ${distanceMeters ? `${distanceMeters} meters` : ''}
          `;

          return (
            <button
              key={id}
              className={`travel-result-steps__item ${s.TravelStepItem}`}
              data-step={step}
              data-step-marker-type={type}
              aria-label={itemSRLabel}
            >
              <div className="travel-result-steps__item-marker">
                {getTravelStepTypeIcon(type)}
              </div>
              <div className="travel-result-steps__item-content">
                {
                  <span className="travel-result-steps__item-label">
                    {typeLabel}
                  </span>
                }
                {distanceMeters && (
                  <span className="travel-result-steps__item-distance">
                    {getDistanceString(distanceMeters)}
                  </span>
                )}
                {durationMinutes && (
                  <span className="travel-result-steps__item-duration">
                    {getDurationString(durationMinutes)}
                  </span>
                )}
              </div>
            </button>
          );
        })}
      </div>
    </div>
  ) : null;
};

export default TravelSteps;
