import React from 'react';
import isObject from 'lodash-es/isObject';
import { isStringEmpty } from '../../../utils/string';
import TravelMetrics from './TravelMetrics';
import SimpleAccordion from '../../utils/SimpleAccordion';
import TravelSteps from './TravelSteps';
import TravelInstructions from './TravelInstructions';
import TravelCTAs from './TravelCTAs';

import s from '../DirectionsResult.module.scss';

/**
 * ResultContents
 */
const ResultContents = ({ mode, resultData, instance = '' }) => {
  if (isStringEmpty(mode) || !isObject(resultData)) {
    return null;
  }

  const { metrics, steps, instructions } = resultData;
  return (
    <div className={s.DirectionsResult} data-instancce={instance}>
      <TravelMetrics mode={mode} {...metrics} />
      <SimpleAccordion
        labelExpanded="Close instruction"
        labelCollapsed="Open instruction"
      >
        <TravelSteps steps={steps} />
        <TravelInstructions instructions={instructions} />
      </SimpleAccordion>
      <TravelCTAs />
    </div>
  );
};

export default ResultContents;
