import * as _ from 'lodash-es';
import React, { useContext } from 'react';
import { MapContext } from '../../contexts/MapContext';
import mw from '../../utils/maps';
import PoiDetailsCTAs, { POI_DETAILS_TYPE } from './PoiDetailsCTAs';
import SearchResults, { SEARCH_RESULTS_DATA_SOURCE } from './SearchResults';
import { isStringEmpty } from '../../utils/string';
import { isNumber } from '../../utils/number';
import { isObject } from '../../utils/utils';

// Notes: so far sharing POI details styles
import s from './PoiDetails.module.scss';

const CategoryDetails = ({ instance = '', resultsListEnabled = true }) => {
  const { map } = useContext(MapContext);
  const category = _.get(map, 'searchResults.selectedCategory', null);
  if (!isObject(category)) {
    return null;
  }
  const categoryTitle = _.get(category, 'typePoisTitle', '');
  const campusId = _.get(category, 'campusId', null);
  if (isStringEmpty(categoryTitle) || !isNumber(campusId)) {
    return null;
  }

  // prep view models
  const campusInfo = mw.getCampusById(campusId) || {};
  const campusText = _.get(campusInfo, 'name', '');
  const poisCount = _.get(category, 'poisCount', null);

  return (
    <div
      className={s.PoiDetails}
      data-instancce={instance}
      data-test-id="searchControl-categoryDetails"
    >
      <div className={s.PoiDetailsPrimary}>
        {categoryTitle}
        {isNumber(poisCount) && ` (${poisCount})`}
      </div>
      <div className={s.PoiDetailsSecondary}>
        Category
        {campusText && `, ${campusText} campus`}
      </div>
      <PoiDetailsCTAs dataType={POI_DETAILS_TYPE.CATEGORY} />
      {resultsListEnabled && (
        <SearchResults
          instance={instance}
          dataSource={SEARCH_RESULTS_DATA_SOURCE.CATEGORY}
        />
      )}
    </div>
  );
};

export default CategoryDetails;
