import * as _ from 'lodash-es';
import FEATURES from '../constants/features';
import mw from './maps';
import { MAP_ACTIONS } from '../reducers/mapReducer';
import { isNumber, roundToPrecision } from './number';
import { isStringEmpty } from './string';
import { isFunction, isObject } from './utils';
import { isMediaQuery } from '../hooks/useMediaQuery';

// constants
export const GEO_DECIMALS = 6;

// helpers
const getValidZLevel = (val) => {
  return isNumber(val) ? val : mw.MAP_INIT_DEFAULT_CONFIGS.zLevel;
};
const getValidLng = (val) => {
  return isNumber(val)
    ? _.round(val, GEO_DECIMALS)
    : mw.MAP_INIT_DEFAULT_CONFIGS.center.lng;
};
const getValidLat = (val) => {
  return isNumber(val)
    ? _.round(val, GEO_DECIMALS)
    : mw.MAP_INIT_DEFAULT_CONFIGS.center.lat;
};
const getValidZoom = (val) => {
  return isNumber(val)
    ? roundToPrecision(val, 2)
    : mw.MAP_INIT_DEFAULT_CONFIGS.zoom;
};
const getMapCenterFromString = (str) => {
  if (isStringEmpty(str)) {
    return null;
  }
  const lngLatList = str.split(',');
  const lng = lngLatList[0] || '';
  const lat = lngLatList[1] || '';
  if (isStringEmpty(lng) || isStringEmpty(lat)) {
    return null;
  }
  return [parseFloat(lng), parseFloat(lat)];
};
const getPointFromString = (str) => {
  if (isStringEmpty(str)) {
    return null;
  }
  // point data string was encoded like https://use.mazemap.com/
  const pointStr = decodeURIComponent(str);
  const pointList = pointStr.split(',');
  const data1 = pointList[0] || '';
  const data2 = pointList[1] || '';

  if (pointList.length === 1 && !isStringEmpty(data1)) {
    // poi id value
    return parseInt(data1);
  }
  if (
    pointList.length === 2 &&
    !isStringEmpty(data1) &&
    !isStringEmpty(data2)
  ) {
    // point lng lat value
    return {
      lng: parseFloat(data1),
      lat: parseFloat(data2),
    };
  }
  return null;
};
const getPointFromDataAndType = async (data = null, type = '') => {
  if (isStringEmpty(type)) {
    return null;
  }
  if (type === 'poi' && isNumber(data)) {
    // get extra data for POI display
    const poiData = await window.Mazemap.Data.getPoi(data);
    const poiDataProps = _.get(poiData, 'properties', {});
    const lngLat = window.Mazemap.Util.getPoiLngLat(poiData); // no API request here
    return {
      lngLat: {
        lng: lngLat.lng || null,
        lat: lngLat.lat || null,
      },
      poi: {
        ...poiData,
        properties: {
          ...poiDataProps,
          poiId: data,
          title: _.get(poiData, 'properties.title', ''),
          zLevel: _.get(
            poiData,
            'properties.zLevel',
            mw.MAP_INIT_DEFAULT_CONFIGS.zLevel,
          ),
        },
      },
    };
  } else if (
    type === 'point' &&
    isObject(data) &&
    isNumber(data.lng) &&
    isNumber(data.lat)
  ) {
    return {
      lngLat: {
        lng: data.lng,
        lat: data.lat,
      },
      poi: false,
    };
  } else {
    return null;
  }
};

/**
 * transformPointToHashString
 * @param point
 * @param pointKey
 * @returns {string}
 */
export const transformPointToHashString = (point = null, pointKey = '') => {
  if (!isObject(point) || isStringEmpty(pointKey)) {
    return '';
  }

  const { lngLat, poi } = point;
  const { lng, lat } = lngLat || {};
  if (!isNumber(lng) || !isNumber(lat)) {
    // lng and lat data should always be present
    return '';
  }

  const poiId = _.get(poi, 'properties.poiId', null);
  const isPoi = isNumber(poiId);
  const typeKey = `${pointKey}type`;
  const typeVal = isPoi ? 'poi' : 'point';
  const dataKey = pointKey;
  const dataVal = isPoi
    ? poiId
    : encodeURIComponent(
        // use encoded format here to match https://use.mazemap.com/
        `${_.round(lng, GEO_DECIMALS)},${_.round(lat, GEO_DECIMALS)}`,
      );

  return `&${typeKey}=${typeVal}&${dataKey}=${dataVal}`;
};

/**
 * transformPoiDetailsToPoint
 * @param poiDetails
 */
export const transformPoiDetailsToPoint = (poiDetails = null) => {
  if (!isObject(poiDetails) || !isObject(poiDetails.originalPoiDetails)) {
    return null;
  }
  const poiData = poiDetails.originalPoiDetails;
  const lngLat = _.get(poiData, 'lngLat', null);
  if (!Array.isArray(lngLat) || !isNumber(lngLat[0]) || !isNumber(lngLat[1])) {
    return null;
  }

  // get data
  const lngLatObj = {
    lng: lngLat[0],
    lat: lngLat[1],
  };
  const poiId = _.get(poiData, 'properties.poiId', null);
  const title = mw.getTitleFromPoi(poiData);
  const zLevel = mw.getZLevelFromPoi(poiData);

  // return in Point data format
  return isNumber(poiId)
    ? {
        lngLat: {
          ...lngLatObj,
        },
        poi: {
          properties: {
            poiId,
            title,
            zLevel,
          },
        },
      }
    : {
        lngLat: {
          ...lngLatObj,
        },
        poi: false,
      };
};

/**
 * hasLocationHash
 * @returns {boolean}
 */
export const hasLocationHash = () => {
  return window.location.hash.trim() !== '';
};

/**
 * hasLocationSearch
 * @returns {boolean}
 */
export const hasLocationSearch = () => {
  return window.location.search.trim() !== '';
};

/**
 * getHashValuesObject
 */
export const getHashValuesObject = () => {
  const hashString = window.location.hash.trim();
  if (hashString === '') {
    return null;
  }

  // prep values from hash string
  const hashValuesList = hashString.split('&');
  const hashValuesObject = {};
  hashValuesList.forEach((pairStr = '') => {
    const pairList = pairStr.split('=');
    const pairKey = pairList[0];
    const pairVal = pairList[1];
    if (!isStringEmpty(pairKey) && !isStringEmpty(pairVal)) {
      // note:
      // - no need to set 'v' for our app
      switch (pairKey) {
        case 'zlevel':
        case 'zoom':
        case 'campusid':
        case 'typepois':
          hashValuesObject[pairKey] = parseFloat(pairVal);
          break;
        case 'center':
          hashValuesObject[pairKey] = getMapCenterFromString(pairVal);
          break;
        case 'starttype':
        case 'desttype':
        case 'sharepoitype':
          hashValuesObject[pairKey] = pairVal;
          break;
        case 'start':
        case 'dest':
        case 'sharepoi':
          hashValuesObject[pairKey] = getPointFromString(pairVal);
          break;
        default:
      }
    }
  });
  return hashValuesObject;
};

/**
 * getSearchValuesObject
 */
export const getSearchValuesObject = () => {
  let searchString = window.location.search.trim();
  if (searchString === '') {
    return null;
  }

  if (searchString.startsWith('?')) {
    searchString = searchString.substring(1);
  }

  // prep values from search string
  const searchValuesList = searchString.split('&');
  const searchValuesObject = {};
  searchValuesList.forEach((pairStr = '') => {
    const pairList = pairStr.split('=');
    const pairKey = pairList[0];
    const pairVal = pairList[1];
    if (!isStringEmpty(pairKey) && !isStringEmpty(pairVal)) {
      switch (pairKey) {
        case 'campusid':
          searchValuesObject[pairKey] = parseFloat(pairVal);
          break;
        case 'campuses': // 'monashuni'
        case 'sharepoi': // numeric or text strings: '00092001138', 'MA-AS-MCJ'
        case 'sharepoitype': // 'identifier'
          searchValuesObject[pairKey] = pairVal;
          break;
        default:
      }
    }
  });
  return searchValuesObject;
};

/**
 * updateDeepLinkFromApp
 * @returns {string}
 */
export const updateDeepLinkFromApp = ({ dataCaller = '' }) => {
  console.debug(`🔗 DeepLink Updated: ${dataCaller}`);
  const { map, store } = mw.getMap();
  const {
    routeSearchPointFrom = null,
    routeSearchPointTo = null,
    selectedPoiPoint = null,
  } = store;
  const hasRouteFrom = isObject(routeSearchPointFrom);
  const hasRouteTo = isObject(routeSearchPointTo);
  const hasSelectedPoiPoint = isObject(selectedPoiPoint);

  let hashString = '';

  // Notes: collect deep link vales one by one by order as MazeMap references

  // 1. version - fixed value
  hashString += 'v=1';

  // 2. floor control level - from map
  hashString += `&zlevel=${getValidZLevel(map.getZLevel())}`;

  // 3. center lng lat - from map
  const { lng, lat } = map.getCenter();
  hashString += `&center=${getValidLng(lng)},${getValidLat(lat)}`;

  // 4. zoom level - from map
  hashString += `&zoom=${getValidZoom(map.getZoom())}`;

  // 5. campus id - from map
  const latestCampusId = mw.getLatestCampusId();
  hashString += `&campusid=${latestCampusId}`;

  // 6. route points - from react state
  if (hasRouteFrom) {
    hashString += transformPointToHashString(routeSearchPointFrom, 'start');
  }
  if (hasRouteTo) {
    hashString += transformPointToHashString(routeSearchPointTo, 'dest');
  }

  // 7. selected POI / Point
  // Only add selected POI deep link when there is no route search points
  if (hasSelectedPoiPoint && !hasRouteFrom && !hasRouteTo) {
    hashString += transformPointToHashString(selectedPoiPoint, 'sharepoi');
  }

  // 8. selected poiTypeId / category id
  // only add selected poiTypeId deep link when there is no route search points, and no selected POI
  const { selectedPoiTypeId } = store;
  const hasSelectedPoiTypeId = isNumber(selectedPoiTypeId);
  if (
    hasSelectedPoiTypeId &&
    !hasRouteFrom &&
    !hasRouteTo &&
    !hasSelectedPoiPoint
  ) {
    hashString += `&typepois=${selectedPoiTypeId}`;
  }

  // update browser hash and return
  window.location.hash = hashString;
  return hashString;
};

/**
 * updateAppFromDeepLink
 */
export const updateAppFromDeepLink = async (dispatch = null) => {
  const hashValuesObject = getHashValuesObject();
  console.debug(`Apply DeepLink`, hashValuesObject);
  if (isObject(hashValuesObject)) {
    console.debug('Deeplink Hash Found:', hashValuesObject);
  } else {
    return;
  }

  // apply hash values to map
  const { map } = mw.getMap();
  if (map === null) {
    return;
  }
  const { zlevel = null, center = null, zoom = null } = hashValuesObject;
  isNumber(zlevel) && map.setZLevel(zlevel);
  Array.isArray(center) && map.setCenter(center);
  isNumber(zoom) && map.setZoom(zoom);

  // apply hash values to app
  if (!isFunction(dispatch)) {
    return;
  }

  // 1. apply directions route points
  const {
    start = null,
    starttype = null,
    dest = null,
    desttype = null,
  } = hashValuesObject;
  const pointFrom = await getPointFromDataAndType(start, starttype);
  const pointTo = await getPointFromDataAndType(dest, desttype);
  const hasPointFrom = isObject(pointFrom);
  const hasPointTo = isObject(pointTo);
  if (hasPointFrom) {
    dispatch({
      type: MAP_ACTIONS.UPDATE_DIRECTION_POINT_FROM,
      payload: {
        from: pointFrom,
      },
    });
  }
  if (hasPointTo) {
    dispatch({
      type: MAP_ACTIONS.UPDATE_DIRECTION_POINT_TO,
      payload: {
        to: pointTo,
      },
    });
  }

  // 2. apply shared POI details
  const { sharepoi = null, sharepoitype = null } = hashValuesObject;
  const sharedPoiPoint = await getPointFromDataAndType(sharepoi, sharepoitype);
  const hasSharedPoiPoint = isObject(sharedPoiPoint);
  if (hasSharedPoiPoint && !hasPointFrom && !hasPointTo) {
    mw.handlePoiPointDetails(
      {
        // data
        lngLat: sharedPoiPoint.lngLat,
        poi: sharedPoiPoint.poi,
        // config
        dataTypeKey: 'sharePoiDataType',
      },
      dispatch,
    );
  }

  // 3. apply shared category / type pois details
  const { campusid = null, typepois = null } = hashValuesObject;
  if (
    isNumber(campusid) &&
    isNumber(typepois) &&
    !hasSharedPoiPoint &&
    !hasPointFrom &&
    !hasPointTo
  ) {
    const typeInfo = await mw.getCategoryTypeInfo(typepois);
    mw.loadTypePoisForCampus(
      {
        poiTypeTitle: typeInfo.title || 'Category',
        poiTypeId: `${typepois}`, // string
        campusId: campusid,
        customProps: {
          dataTrigger: 'deepLinkShare',
          dataIsMobile: isMediaQuery(),
        },
      },
      dispatch,
    );
  }
};

/**
 * removeFromURLHash
 * @param {string} strStartsWith
 * @returns
 */
export const removeFromURLHash = (strStartsWith) => {
  const hashString = window.location.hash.trim();
  const hashValuesList = hashString.split('&');
  window.location.hash = hashValuesList
    .filter((value) => !value.startsWith(strStartsWith))
    .join('&');
};

/**
 * applyHashDeepLinkFromSearch
 * @returns {Promise<void>}
 */
export const applyHashDeepLinkFromSearch = async () => {
  if (!FEATURES.SEARCH_DEEP_LINK_SUPPORT || !hasLocationSearch()) {
    return;
  }

  let newHash;
  const searchValuesObject = getSearchValuesObject();

  // Notes: only makes API call and deep link translation if:
  // 1. 'searchValuesObject' is NOT null
  // 2. 'sharepoitype' is 'identifier' type
  // 3. 'sharepoi' value available
  if (
    isObject(searchValuesObject) &&
    searchValuesObject.sharepoitype === 'identifier' &&
    !isStringEmpty(searchValuesObject.sharepoi)
  ) {
    // making identifier API call
    const identifierPois = await window.Mazemap.Data.getPois({
      identifier: searchValuesObject.sharepoi,
      campuscollectiontag: 'monashuni',
    });
    // translate identifier POI data into new hash
    if (Array.isArray(identifierPois) && isObject(identifierPois[0])) {
      const poi = identifierPois[0];
      const zLevel = _.get(poi, 'properties.zLevel', 1);
      const campusId = _.get(poi, 'properties.campusId', null);
      const poiId = _.get(poi, 'properties.poiId', null);
      if (isNumber(campusId) && isNumber(poiId)) {
        newHash = `v=1&zlevel=${zLevel}&campusid=${campusId}&sharepoitype=poi&sharepoi=${poiId}`;
        console.debug('[searchDeepLink]: translated hash deep link:', newHash);
      }
    } else {
      console.warn(
        '[searchDeepLink]: Invalid identifier POI data',
        identifierPois,
      );
    }
  } else {
    console.warn(
      '[searchDeepLink]: Invalid search deep link values',
      searchValuesObject,
    );
  }

  // Apply new hash without reloading page
  window.history.replaceState(null, '', '/'); // removes residue search query
  if (!isStringEmpty(newHash)) {
    window.location.hash = newHash;
  }
};
