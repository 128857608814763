import React from 'react';
import { getDistanceString, getDurationString } from './TravelSteps.utils';
import { getTravelModeIcon } from '../directionsSearch/TravelModeBtn';

import s from './TravelMetrics.module.scss';

/**
 * TravelMetrics
 */
const TravelMetrics = ({
  durationMinutes = null,
  distanceMeters = null,
  mode = '',
}) => {
  const durationText = getDurationString(durationMinutes);
  const distanceText = getDistanceString(distanceMeters);
  const modeIcon = getTravelModeIcon(mode);

  return (
    <div
      className={`travel-result-metrics ${s.TravelMetrics}`}
      data-dir-result-sec="metrics"
    >
      {/* duration */}
      {!!durationText && (
        <div
          className={`travel-result-metrics__duration ${s.TravelMetricsDuration}`}
        >
          {durationText}
        </div>
      )}
      {/* mode */}
      <div className={`travel-result-metrics__mode ${s.TravelMetricsMode}`}>
        {modeIcon}
      </div>
      {/* distance */}
      {!!distanceText && (
        <div
          className={`travel-result-metrics__distance ${s.TravelMetricsDistance}`}
        >
          {distanceText}
        </div>
      )}
    </div>
  );
};

export default TravelMetrics;
