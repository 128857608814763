import * as _ from 'lodash-es';
import {
  BACK_BTN_TARGET,
  BOTTOM_SHEET_VIEW_STATE,
  DIRECTIONS_RESULT_VIEW_STATES,
  DISPLAY,
  MM_LAYERS,
  POINT_DISPLAY_TEXT,
  SEARCH_CONTROL,
  SEARCH_DISPLAY,
  UI_COLOURS,
} from '../constants/constants';
import { campusData } from './data/campusData';
import HTML_TEMPLATES from '../constants/templates';
import { MAP_ACTIONS, UI_ACTIONS } from '../reducers/mapReducer';
import { isMediaQuery } from '../hooks/useMediaQuery';
import { isDefined, isFunction, isObject } from './utils';
import { isNumber } from './number';
import { isStringEmpty } from './string';
import { applyPoiPointDetails } from '../components/search/PoiDetails.utils';
import { loadGeolocateControl, loadNavigationControl } from './maps.controls';
import {
  hasLocationHash,
  updateDeepLinkFromApp,
  getHashValuesObject,
} from './deepLink';
import { formatCategoryPoiResult } from '../components/search/CategoryPoisResults.mapper';
import { formatPoiInfosNames } from '../components/search/SearchResults.mapper';
import { isBuildingId } from '../components/search/SearchResults.utils';
import OpenDay from '../utils/openday';

export const GLOBAL_MAP_NS = 'muMap'; // Global namespace
export const DEFAULT_CAMPUS_ID = 159; // clayton
export const Z_LEVEL_UPDATER_MIN_ZOOM = 12; // native zLevel updater will be active when map is above this zoom level

const CAMPUS_DETAILS = campusData;
const MAP_INIT_DEFAULT_CONFIGS = {
  center: {
    lng: CAMPUS_DETAILS.clayton.lng,
    lat: CAMPUS_DETAILS.clayton.lat,
  },
  zoom: CAMPUS_DETAILS.clayton.zoom,
  zLevel: 1,
};
const MAP_INIT_DEFAULT_CONFIGS_MOBILE = {
  center: {
    lng: CAMPUS_DETAILS.clayton.lng,
    lat: CAMPUS_DETAILS.clayton.lat,
  },
  zoom: CAMPUS_DETAILS.clayton.zoom,
  zLevel: 1,
};

// Notes: 3D Maps
// - requires pitch value for 3D visual
// - map.enable3d()|map.disable3d()
const MAP_INIT_DEFAULT_CONFIGS_3D = {
  threeD: true,
  pitch: 45, // in degrees away from the plane of the screen: 0 ~ 60
  // bearing: -28.8, // in degrees compass direction angle 'up': -180 ~ 180
};

const getDefaultMapConfig = () => {
  const DEFAULT_SETTINGS = isMediaQuery()
    ? MAP_INIT_DEFAULT_CONFIGS_MOBILE
    : MAP_INIT_DEFAULT_CONFIGS;

  // use campusId hash if present
  if (hasLocationHash()) {
    const hashValuesObject = getHashValuesObject();
    if (isObject(hashValuesObject)) {
      const { campusid = null } = hashValuesObject;
      if (isNumber(campusid)) {
        const campus = getCampusById(campusid);
        if (isObject(campus)) {
          DEFAULT_SETTINGS.center = {
            lng: campus.lng,
            lat: campus.lat,
          };
          DEFAULT_SETTINGS.campusName = campus.name;
        }
      }
    }
  }
  console.debug('DEFAULT_MAP_INIT_SETTINGS', DEFAULT_SETTINGS);
  return DEFAULT_SETTINGS;
};

/**
 * Map Utils
 */

const initMap = (
  mapContainer,
  dispatch,
  { onLoadCallback, onClickCallback, onMoveEndCallback, onIdleCallback },
) => {
  const muMap = new window.Mazemap.Map({
    container: mapContainer, // container id, or DOM node in the HTML
    campuses: 'monashuni',
    // control configs
    zLevelUpdater: true,
    zLevelControl: true,
    doubleClickZoom: true,
    touchZoomRotate: true,
    dragPan: true,
    scrollZoom: true,
    // map view configs
    ...getDefaultMapConfig(),
    // 3D map view configs
    ...MAP_INIT_DEFAULT_CONFIGS_3D,
  });

  muMap.muStore = {
    // UI
    uiSearchControl: SEARCH_CONTROL.SEARCH,
    // directions route search
    routePathMarkers: [],
    routeSearchPointsPopup: null,
    routeSearchPointFrom: null, // used by deep link
    routeSearchPointTo: null, // used by deep link
    // selected POI/POINT
    selectedPoiPoint: null, // Point data type, used by deep link
    // selected poiTypeId
    selectedPoiTypeId: null, // number, used by deep link
    selectedPoiTypeCampusId: null, // number, used by deep link updates
    // geolocate
    geolocateControl: null, // from mapBox
    geolocateData: {
      prevPosition: null, // keep copy for comparison
      position: null, // https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPosition
      positionError: null, // https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError
      trackUserLocationStatus: null, // used to determine the first 'geolocate' event after 'trackuserlocationstart'
    },
    // markers
    marker: null, // for poi/point single marker
    categoryMarkers: [], // for categories markers
    // map feature layers
    activeBuildingLabelHoverId: null, // number
    // searchController,
    // routeController,
    // navigationControl, // from mapBox
    // highlighter,
  };

  window[GLOBAL_MAP_NS] = muMap;

  // load maze map
  muMap.on('load', (e) => {
    console.debug('[MU MazeMap]: Ready!!!');
    const map = e.target || window[GLOBAL_MAP_NS];

    // Initialize a Highlighter for POIs
    // Storing the object on the map just makes it easy to access for other things
    map.muStore.highlighter = new window.Mazemap.Highlighter(map, {
      showOutline: true,
      showFill: true,
      outlineColor: UI_COLOURS.BLUE_DEFAULT, //window.Mazemap.Util.Colors.MazeColors.MazeBlue
      fillColor: '#F0F8FF', //window.Mazemap.Util.Colors.MazeColors.MazeBlue
    });

    // search controllers
    map.muStore.searchController = new window.Mazemap.Search.SearchController({
      // campusid: 159, // campusid will be automatically updated based on current map location
      campuscollectiontag: 'monashuni',
      rows: 400, // use a max number to avoid pagination
      withpois: true, // return pois
      withbuilding: true, // return buildings
      withtype: true, // return categories
      withcampus: true, // return campus
      resultsFormat: 'geojson',
      // __cleanResults: false, // disable to receive raw API response with full data
    });

    // directions route search controllers
    map.muStore.routeController = new window.Mazemap.RouteController(map, {
      showDirectionArrows: true,
    });

    // other map controllers
    loadNavigationControl();
    loadGeolocateControl(dispatch);

    // custom map on load callback
    if (isFunction(onLoadCallback)) {
      onLoadCallback(true);

      // custom layer enhancements
      drawCampusOutline();
      enableMapBuildingLabelHoverState();
    }
  });

  // maze map click handler
  muMap.on('click', (e) => {
    const mapClickResults = getMapClickResult(e);
    const lngLat = e.lngLat;
    if (isDefined(lngLat.lng) && isDefined(lngLat.lat)) {
      window.Mazemap.Data.getPoiAt(lngLat, getMapZLevel()).then((poi) => {
        // 'poi' might return as false with a 404
        if (isFunction(onClickCallback)) {
          onClickCallback({ e, lngLat, poi, mapClickResults });
        }
      });
    }
  });

  // maze map move end handler
  muMap.on('moveend', (e) => {
    if (isFunction(onMoveEndCallback)) {
      onMoveEndCallback(e);
    }
  });

  muMap.on('idle', (e) => {
    if (isFunction(onIdleCallback)) {
      onIdleCallback(e);
    }
  });

  return muMap;
};

const getMap = () => {
  // available after initMap
  const map = window[GLOBAL_MAP_NS];
  return {
    map: map || null,
    store: (map && map.muStore) || {},
  };
};

const resizeMap = (timeout = 10) => {
  const { map } = getMap();
  if (!!map && typeof map.resize === 'function') {
    setTimeout(() => {
      map.resize();
    }, timeout);
  }
};

const centerWithZoom = (lngLat, zLevel) => {
  const { map } = getMap();
  map.setCenter(lngLat);
  map.setZLevel(zLevel);
};

const clearSelectedPoiValues = () => {
  const { store } = getMap();
  store.selectedPoiPoint = null;
};

const clearSelectedPoiTypeValues = () => {
  const { store } = getMap();
  store.selectedPoiTypeId = null;
  store.selectedPoiTypeCampusId = null;
};

/**
 * findMapClickFeatureViaLabel
 * Note:
 * - re-written based on MazeMap demo react app source from 2022.03
 * @param features - list of map click features obj
 * @param featureLabels - list of feature layer ids
 * @returns {null|{}}
 */
const findMapClickFeatureViaLabel = (features, featureLabels) => {
  for (let i = 0; i < features.length; i++) {
    const feature = features[i];
    const featureLayerId = _.get(feature, 'layer.id', '');
    for (let j = 0; j < featureLabels.length; j++) {
      if (featureLabels[j] === featureLayerId) {
        return feature;
      }
    }
  }
  return null;
};

/**
 * getMapClickResult
 * Note:
 * - re-written based on MazeMap demo react app source from 2022.03
 * - used to determine what features (layers) the map click result has
 * @param e click event obj
 * @returns {null|{}}
 */
const getMapClickResult = (e = {}) => {
  const { map } = getMap();
  if (!isObject(map)) {
    return null;
  }

  // check lnglat
  const lngLat = e.lngLat;
  if (!isObject(lngLat) || !isNumber(lngLat.lng) || !isNumber(lngLat.lat)) {
    console.warn('getMapClickResult: no lngLat');
    return null;
  }

  // get map click features
  const mapClickFeatures = map.queryRenderedFeatures(e.point);
  const floorFeature = findMapClickFeatureViaLabel(mapClickFeatures, [
    MM_LAYERS.FLOOR_OUTLINE,
  ]);
  const buildingLabel = findMapClickFeatureViaLabel(mapClickFeatures, [
    MM_LAYERS.BUILDING_LABEL,
  ]);
  const buildingFeature = findMapClickFeatureViaLabel(mapClickFeatures, [
    MM_LAYERS.BUILDING_FILL,
  ]);
  const campusFeature = findMapClickFeatureViaLabel(mapClickFeatures, [
    MM_LAYERS.CAMPUS_AREA,
    MM_LAYERS.CAMPUS_LABEL,
    MM_LAYERS.CAMPUS_DOT,
  ]);

  // get other values
  const zLevel = !!buildingFeature || !!floorFeature ? map.getZLevel() : 0;
  const floorId =
    floorFeature && floorFeature.properties && floorFeature.properties.id;
  const buildingId =
    buildingFeature &&
    buildingFeature.properties &&
    buildingFeature.properties.id;
  const campusId =
    (campusFeature &&
      campusFeature.properties &&
      campusFeature.properties.id) ||
    (buildingFeature &&
      buildingFeature.properties &&
      buildingFeature.properties.campusId) ||
    (floorFeature &&
      floorFeature.properties &&
      floorFeature.properties.campusId);

  return {
    originalEvent: { ...e },
    lngLat: { ...lngLat },
    zLevel,
    floorId,
    floorFeature: isObject(floorFeature) ? { ...floorFeature } : null,
    buildingId,
    buildingLabel: isObject(buildingLabel) ? { ...buildingLabel } : null,
    campusId,
    campusFeature: isObject(campusFeature) ? { ...campusFeature } : null,
  };
};

/**
 * Map building label feature layer hover effect
 * Note: re-written based on MazeMap demo react app source from 2022.03
 */
const onMapBuildingLabelMouseLeave = () => {
  const { map, store } = getMap();
  // ignore if in directions UI mode
  if (store.uiSearchControl === SEARCH_CONTROL.DIRECTIONS) {
    return;
  }
  const { activeBuildingLabelHoverId } = store;
  if (!isNumber(activeBuildingLabelHoverId)) {
    return;
  }
  // update building label feature state
  map.setFeatureState(
    {
      source: 'mm-tiles',
      sourceLayer: 'buildings',
      id: activeBuildingLabelHoverId,
    },
    {
      hover: false,
    },
  );
  // reset hover id
  store.activeBuildingLabelHoverId = null;
};

const onMapBuildingLabelMouseMove = (e) => {
  const { map, store } = getMap();
  // ignore if in directions UI mode
  if (store.uiSearchControl === SEARCH_CONTROL.DIRECTIONS) {
    return;
  }
  // get building label feature id
  const buildingLabelFeatObj =
    isObject(e) && Array.isArray(e.features) && e.features[0];
  if (!isObject(buildingLabelFeatObj)) {
    return;
  }
  const buildingLabelId = buildingLabelFeatObj.id;
  if (!isNumber(buildingLabelId)) {
    return;
  }
  // reset any existing active building label state
  onMapBuildingLabelMouseLeave();
  // update building label feature state
  store.activeBuildingLabelHoverId = buildingLabelId;
  map.setFeatureState(
    {
      source: 'mm-tiles',
      sourceLayer: 'buildings',
      id: buildingLabelId,
    },
    {
      hover: true,
    },
  );
};

const enableMapBuildingLabelHoverState = () => {
  // note: this method should only be called once on map ready
  const { map } = getMap();
  if (!isObject(map)) {
    return;
  }
  // register interaction
  map.on('mousemove', MM_LAYERS.BUILDING_LABEL, onMapBuildingLabelMouseMove);
  map.on('mouseleave', MM_LAYERS.BUILDING_LABEL, onMapBuildingLabelMouseLeave);
  // register hover feature state with initial state
  map.setPaintProperty(MM_LAYERS.BUILDING_LABEL, 'text-color', [
    'case',
    ['boolean', ['feature-state', 'hover'], false],
    UI_COLOURS.BLUE_HOVER,
    UI_COLOURS.BLACK,
  ]);
};

/**
 * Campus Utils
 */

const switchCampus = (campusName = 'clayton') => {
  if (isStringEmpty(campusName) || !isObject(CAMPUS_DETAILS[campusName])) {
    return;
  }
  const { lat = 0, lng = 0, zoom = 17 } = CAMPUS_DETAILS[campusName];
  const { map } = getMap();
  if (map !== null) {
    map.flyTo({
      center: [lng, lat],
      speed: 1,
      zoom,
    });
  }
};

const jumpCampus = (campusName = 'clayton') => {
  if (isStringEmpty(campusName) || !isObject(CAMPUS_DETAILS[campusName])) {
    return;
  }
  const { lat = 0, lng = 0, zoom = 17 } = CAMPUS_DETAILS[campusName];
  const { map } = getMap();
  if (map !== null) {
    map.jumpTo({
      center: [lng, lat],
      zoom,
    });
  }
};

const getCampusById = (id) => {
  const campusKey = _.findKey(CAMPUS_DETAILS, (o) => o.id === id);
  return CAMPUS_DETAILS[campusKey] || null;
};

const getCampusCategories = (campusId, dispatch = null) => {
  if (!(campusId && campusId > 0)) {
    return new Promise(function (resolve) {
      resolve([]);
    });
  }

  return new Promise(function (resolve, reject) {
    window.Mazemap.Data.getSelectablePoiTypesForCampusId(campusId)
      .then((categoryIds) => {
        if (!Array.isArray(categoryIds) || categoryIds.length < 1) {
          return resolve([]);
        }

        const allPromises = categoryIds.map((id) => {
          return new Promise((resolve, reject) => {
            window.Mazemap.Data.getTypeById(id)
              .then((type) => {
                resolve(type);
              })
              .catch((e) => reject(new Error(e.message)));
          });
        });

        Promise.all(allPromises)
          .then((values) => {
            resolve(values);
            if (isFunction(dispatch)) {
              dispatch({
                type: MAP_ACTIONS.UPDATE_CATEGORIES_ICON_MAP,
                payload: {
                  typeInfos: [...values],
                },
              });
            }
          })
          .catch((e) => reject(new Error(e.message)));
      })
      .catch((e) => reject(new Error(e.message)));
  });
};

const getValidCampusId = (val) => {
  return isNumber(val) ? val : DEFAULT_CAMPUS_ID;
};

/**
 * getLatestCampusId
 * Notes:
 * This method will only return correct value when the map instance
 * is above the minimum zoom level set in 'muMap.zLevelUpdater.options.minZoom'
 * @returns {*|number}
 */
const getLatestCampusId = () => {
  const { map } = getMap();
  return getValidCampusId(_.get(map, 'zLevelUpdater._currentCampusId', null));
};

const drawCampusOutline = (opacity = 0.75) => {
  const { map } = getMap();
  map.setPaintProperty(MM_LAYERS.CAMPUS_AREA, 'fill-opacity', opacity);
  map.setPaintProperty(MM_LAYERS.CAMPUS_AREA, 'fill-outline-color', '#000000');
  map.setPaintProperty(
    MM_LAYERS.BUILDING_FILL,
    'fill-outline-color',
    '#000000',
  );
};

const getCampusInfoFromMap = () => {
  const { map } = getMap();

  // Notes:
  // - returns arrays of current map features being rendered e.g.: campus, building, floor
  // - used here as a workaround to retrieve current map campus id
  // - returns empty data if called right after app load before features data is rendered, same issue replicated on https://use.mazemap.com/
  const mapClickData = window.Mazemap.Util.getMapClickData(
    map,
    map.getCenter(),
  );

  // find campus id
  const campusId =
    Array.isArray(mapClickData.campusIds) && mapClickData.campusIds[0];
  if (!isNumber(campusId)) {
    return null;
  }

  // find and return campus data
  return getCampusById(campusId);
};

/**
 * Markers Utils
 */
const placePoiMarker = (poi) => {
  const { map, store } = getMap();
  // Get a center point for the POI, because the data can return a polygon instead of just a point sometimes
  const lngLat = window.Mazemap.Util.getPoiLngLat(poi);

  // add marker
  store.marker = new window.Mazemap.MazeMarker({
    color: UI_COLOURS.BLUE_DEFAULT,
    innerCircle: true,
    innerCircleColor: UI_COLOURS.WHITE,
    size: 34,
    innerCircleScale: 0.5,
    zLevel: poi.properties.zLevel,
  })
    .setLngLat(lngLat)
    .addTo(map);

  // add highlight
  highlightPoi(poi, lngLat).finally();

  map.flyTo({ center: lngLat, speed: 1, zLevel: poi.properties.zLevel });
};
const placeLngLatMarker = (lngLat) => {
  const { map, store } = getMap();
  store.marker = new window.Mazemap.MazeMarker({
    color: UI_COLOURS.BLUE_DEFAULT,
    innerCircle: true,
    innerCircleColor: UI_COLOURS.WHITE,
    size: 34,
    innerCircleScale: 0.5,
  })
    .setLngLat(lngLat)
    .addTo(map);

  map.flyTo({ center: lngLat, speed: 1 });
};
const clearClickMarker = () => {
  const { store } = getMap();
  if (store && store.marker) {
    store.marker.remove();
    store.marker = null;
  }
  if (store && store.highlighter) {
    store.highlighter.clear();
  }
};
const placeClickMarker = ({ lngLat, poi }, timeout = 10) => {
  if (!isObject(lngLat) || !isDefined(poi)) {
    return;
  }
  setTimeout(() => {
    clearClickMarker();
    if (poi) {
      placePoiMarker(poi);
    } else {
      placeLngLatMarker(lngLat);
    }
  }, timeout);
};

/**
 * Category / Type pois
 */

const updateCategoryMarkersSelectedState = (selectedCategoryMarker = null) => {
  const { store } = getMap();
  store.categoryMarkers.forEach((categoryMarker) => {
    categoryMarker.getElement().removeAttribute('data-user-selected');
  });

  if (isObject(selectedCategoryMarker)) {
    selectedCategoryMarker
      .getElement()
      .setAttribute('data-user-selected', 'true');
  }
};

const getCategoryTypeInfo = async (typeId = null, dispatch = null) => {
  if (!isNumber(typeId)) {
    return null;
  }
  if (isFunction(dispatch)) {
    dispatch({
      type: MAP_ACTIONS.UPDATE_CATEGORIES_ICON_MAP,
      payload: {
        typeInfos: [
          {
            id: typeId,
            icon_id: 'LOADING',
          },
        ],
      },
    });
  }
  const typeInfo = await window.Mazemap.Data.getTypeById(typeId);
  if (isFunction(dispatch)) {
    dispatch({
      type: MAP_ACTIONS.UPDATE_CATEGORIES_ICON_MAP,
      payload: {
        typeInfos: [
          {
            ...typeInfo,
          },
        ],
      },
    });
  }
  return typeInfo;
};

const clearCategoryMarkers = ({ shouldUpdateDeepLink = true } = {}) => {
  const { store } = getMap();

  for (let i = 0; i < store.categoryMarkers.length; i++) {
    store.categoryMarkers[i].remove();
  }
  store.categoryMarkers = [];

  if (store.highlighter) {
    store.highlighter.clear();
  }

  // clear poiTypeId from store
  clearSelectedPoiTypeValues();
  // update deep link
  if (shouldUpdateDeepLink) {
    updateDeepLinkFromApp({ dataCaller: 'clearCategoryMarkers' });
  }
};

const getPoiDetailsWithCallback = (poi = {}, cb) => {
  const poiId = _.get(poi, 'properties.poiId', null);
  if (!isNumber(poiId)) {
    return;
  }
  // get full details from API and callback
  window.Mazemap.Data.getPoi(poiId).then((poiDetails) => {
    // Prep poi details data with custom POI data props
    const { customProps } = poi;
    const finalPoiDetails = isObject(customProps)
      ? {
          ...poiDetails,
          ...customProps,
        }
      : {
          ...poiDetails,
        };
    // callback with final details data
    if (isFunction(cb)) {
      cb(finalPoiDetails);
    }
  });
};

const getTypePoiMarkerClickHandler =
  ({ dispatch, customProps }) =>
  (poi) => {
    console.debug('getTypePoiMarkerClickHandler:', 'customProps:', customProps);
    if (!isFunction(dispatch) || !isObject(poi)) {
      return;
    }
    if (!isObject(poi.properties)) {
      return;
    }

    // pass custom props into handler POI details
    if (isObject(customProps)) {
      poi.customProps = { ...customProps };
    }

    // pass poi data with callback
    getPoiDetailsWithCallback(poi, (poiDetails) => {
      dispatch({
        type: MAP_ACTIONS.SEARCH_POI_SELECTED,
        payload: {
          poiDetail: {
            originalPoiDetails: {
              ...formatPOIDetails(poiDetails),
              lngLat: [poi.lngLat.lng, poi.lngLat.lat],
              typePoisDataType: 'poi', // type pois type is fixed as 'poi'
            },
          },
        },
      });
      dispatch({
        type: UI_ACTIONS.UPDATE_UI_SEARCH_DISPLAY,
        payload: {
          component: SEARCH_DISPLAY.SEARCH_RESULT_POI_DETAIL,
          backBtnTarget: BACK_BTN_TARGET.CATEGORY_DETAILS,
        },
      });
      dispatch({
        type: UI_ACTIONS.UPDATE_UI_BOTTOM_SHEET,
        payload: {
          bottomSheetState: BOTTOM_SHEET_VIEW_STATE.COLLAPSED,
        },
      });
    });
  };

const placeCategoryMarkers = (
  pois,
  categoryMarkerClickHandler,
  {
    poiTypeTitle = '',
    poiTypeId = null,
    campusId = null,
    shouldUpdateDeepLink = false,
    shouldShowCategoryDetails = false,
    hasSearchResultBackBtnTarget = false,
  } = {},
  dispatch,
) => {
  console.info('placeCategoryMarkers:', pois);
  if (!isObject(pois) || !Array.isArray(pois.features)) {
    return;
  }

  // clear single POI/Point marker
  clearClickMarker();

  // Open Day 2022
  const isOpenDayCategory = OpenDay.isOpenDayCategory(campusId, poiTypeId);

  // add category POI markers
  const { map, store } = getMap();
  store.categoryMarkers = store.categoryMarkers || [];

  const poisCount = pois.features.length;
  for (let i = 0; i < poisCount; i++) {
    const poi = convertPoiGeometryFromPolygonToPoint(pois.features[i]);
    const lngLat = window.Mazemap.Util.getPoiLngLat(poi);
    const poiId = _.get(poi, 'properties.poiId', null);

    const markerOptions = OpenDay.makeOpenDayMarker(
      isOpenDayCategory,
      poiId,
      poiTypeId,
      {
        color: UI_COLOURS.BLUE_DEFAULT,
        size: 24,
        shape: 'circle',
        includeShadow: false,
        zLevel: poi.properties.zLevel,
        // innerCircle: false,
        // innerCircleColor: UI_COLOURS.BLUE_DEFAULT,
        // innerCircleScale: 0.65,
      },
    );

    const categoryMarker = new window.Mazemap.MazeMarker({
      ...markerOptions,
      zLevel: poi.properties.zLevel,
    })
      .setLngLat(lngLat)
      .addTo(map);

    // adding categoryMarker custom attributes
    if (isNumber(poiId)) {
      categoryMarker.getElement().setAttribute('data-poi', poiId);
      categoryMarker
        .getElement()
        .setAttribute(
          'data-type',
          !isOpenDayCategory ? 'category' : 'open-day',
        );
      if (isOpenDayCategory)
        categoryMarker.getElement().setAttribute('title', markerOptions?.title);
    }

    // adding categoryMarker click handler
    if (typeof categoryMarkerClickHandler === 'function') {
      categoryMarker.on('click', () => {
        categoryMarkerClickHandler({ ...poi, lngLat });
        updateCategoryMarkersSelectedState(categoryMarker);
        placeClickMarker(
          {
            lngLat,
            poi,
          },
          0,
        );
        const currentZoom = map.getZoom();
        map.flyTo({
          center: lngLat,
          speed: 1,
          zoom: currentZoom < 16 ? 17 : currentZoom,
        });
      });
    }

    // also keep categoryMarker in map store
    store.categoryMarkers.push(categoryMarker);
  }

  // update map
  if (poisCount > 0) {
    const bbox = window.Mazemap.Util.Turf.bbox(pois);
    const bounds = new window.Mazemap.mapboxgl.LngLatBounds(bbox);
    // Note:
    // - padding value here needs to consider map 'pitch' value
    // - MazeMap 'fitBounds' calculates paddings assuming 0 'pitch'
    map.fitBounds(
      bounds,
      // options
      {
        padding: isMediaQuery()
          ? {
              top: 136, // cater for mobile top search UI
              bottom: 32,
              left: 80, // cater for 'pitch'
              right: 80, // cater for 'pitch'
            }
          : {
              top: 64,
              bottom: 64,
              left: 128, // cater for 'pitch'
              right: 128, // cater for 'pitch'
            },
        // Notes:
        // - need to provide current bearing and pitch since v2.0.92 (Mapbox GL version 2.12.0)
        // - https://github.com/mapbox/mapbox-gl-js/pull/12367
        bearing: map.getBearing(),
        pitch: map.getPitch(),
      },
      // event data
      {
        dataCaller: 'placeCategoryMarkers',
      },
    );
    // fit bounds end event handler
    map.once('moveend', ({ dataCaller } = {}) => {
      const isCallerMatched = dataCaller === 'placeCategoryMarkers';
      const isCampusIdMatched = campusId === getLatestCampusId();
      if (!isCallerMatched || !isCampusIdMatched || !isNumber(poiTypeId)) {
        return;
      }
      // persist poiTypeId into store
      store.selectedPoiTypeId = poiTypeId;
      store.selectedPoiTypeCampusId = campusId;
      // update deep link
      if (shouldUpdateDeepLink) {
        updateDeepLinkFromApp({ dataCaller: 'placeCategoryMarkers' });
      }
    });
  }

  // update APP ui
  if (shouldShowCategoryDetails && isFunction(dispatch)) {
    dispatch({
      type: MAP_ACTIONS.SEARCH_CATEGORY_SELECTED,
      payload: {
        typePoisTitle: poiTypeTitle,
        typePoisId: poiTypeId, // number
        campusId: campusId,
        poisCount,
        pois: formatCategoryPoiResult(pois.features),
      },
    });

    const uiDisplayPayload = {
      component: SEARCH_DISPLAY.SEARCH_RESULT_CATEGORY_DETAIL,
    };
    if (hasSearchResultBackBtnTarget) {
      uiDisplayPayload.backBtnTarget = BACK_BTN_TARGET.MIXED_SEARCH_RESULT;
    }
    dispatch({
      type: UI_ACTIONS.UPDATE_UI_SEARCH_DISPLAY,
      payload: uiDisplayPayload,
    });
  }
};

/**
 * loadTypePoisForCampus
 * @param {string} poiTypeTitle
 * @param {string} poiTypeId
 * @param {number} campusId
 * @param {object} customProps
 * @param {function} dispatch
 */
const loadTypePoisForCampus = (
  { poiTypeTitle, poiTypeId, campusId, customProps },
  dispatch,
) => {
  console.debug(
    'loadTypePoisForCampus:',
    'poiTypeId:',
    poiTypeId,
    'campusId:',
    campusId,
    'poiTypeTitle:',
    poiTypeTitle,
    'customProps:',
    customProps,
  );

  if (
    !isFunction(dispatch) ||
    isStringEmpty(poiTypeId) ||
    !isNumber(campusId) ||
    isStringEmpty(poiTypeTitle)
  ) {
    return;
  }

  function getAllPoisOfType(poiTypeId, campusId) {
    let pois = []; // Array to store POIs
    function recursivelyAddPois(response) {
      response.geojson.features.forEach((feature) => pois.push(feature)); // Push each POI feature to the array
      if (!response.getNextPage || pois.length >= 600) {
        if (pois.length > 600) {
          return pois.slice(0, 600);
        }
        return pois; // Return all POIs if there's no next page
      }
      // Fetch the next page of POIs if available
      return response.getNextPage().then(recursivelyAddPois).catch(e => {
        //console.error(e)
        if (pois.length >= 600) {
          return pois.slice(0, 600)
        };
        return pois
      });
    }
    // First API call using separate parameters
    return window.Mazemap.Data.getPoisByTypeIdAndCampusIdAsGeoJSONWithPagination(
      poiTypeId,
      campusId,
    ).then(recursivelyAddPois);
  }

    // get and prep type pois data and behaviours
    // 1. used getPoisByTypeIdAndCampusIdAsGeoJSON before with max results of 100 (API limitation): see WEBUW-4065
    // 2. need to convert geo data for all 'polygon' type returned
    // 3. but does provide category POI basic details for UI list view
    // - Previous API: window.Mazemap.Data.getPoisOfTypeAsGeoJSON({ poiTypeId, campusId })
    // 4. previous API only contains category POI id without display data
    getAllPoisOfType(poiTypeId, campusId)
      .then((poisX) => {
        var pois = {
          type: 'FeatureCollection',
          features: [],
        };

        poisX.forEach(function (feature, index) {

          let data = {
            ...feature,
            ['originalGeometry']: feature.geometry,
          };

          if (feature.geometry.type == 'Point') {
            data.geometry = feature.geometry;
          } else if (feature.geometry.type == 'Polygon') {
            data.geometry = {
              type: 'Point',
              coordinates: feature.geometry.coordinates[0][0],
            };
          }

          pois.features.push(data);
        });

        dispatch({
          type: UI_ACTIONS.UPDATE_UI_BOTTOM_SHEET,
          payload: {
            bottomSheetState: BOTTOM_SHEET_VIEW_STATE.COLLAPSED,
            isFetchingCategoryPois: false,
          },
        });

        // update map
        setTimeout(() => {
          const hasSearchResultBackBtnTarget =
            isObject(customProps) &&
            // each data trigger needs to be whitelisted here for back btn
            (customProps.dataTrigger === 'searchResult' ||
              customProps.dataTrigger === 'searchControlBackBtn' ||
              customProps.dataTrigger === 'campusFilterResult' ||
              customProps.dataTrigger === 'deepLinkShare');

          clearCategoryMarkers();
          placeCategoryMarkers(
            pois,
            getTypePoiMarkerClickHandler({
              dispatch,
              customProps,
            }),
            {
              poiTypeTitle,
              poiTypeId: parseInt(poiTypeId),
              campusId,
              shouldUpdateDeepLink: true,
              shouldShowCategoryDetails: true,
              hasSearchResultBackBtnTarget,
            },
            dispatch,
          );
        }, 100); // manual delay to wait for the map.resize() from bottom sheet update
      })
      .catch((err) => {
        console.warn('getAllPoisOfType: ', err);
      });

};

/**
 * Map search Utils
 */

const updateSearchController = ({ campusid }) => {
  const { store } = getMap();
  if (
    !isObject(store.searchController) ||
    !isObject(store.searchController.options)
  ) {
    return;
  }

  // campusid
  if (isNumber(campusid)) {
    store.searchController.options.campusid = campusid;
  }
};

/**
 * Highlighter Utils
 */

const getHighlighter = () => {
  const { store } = getMap();
  return store.highlighter;
};

/**
 * highlightPoi
 * Notes:
 * - draw POI highlight if data is available, or will try to get data and draw
 * @param poi
 * @param lngLat
 * @returns {Promise<void>}
 */
const highlightPoi = async (poi, lngLat) => {
  if (!isObject(poi) || !isObject(lngLat)) {
    return;
  }

  const { store } = getMap();
  const geoType = _.get(poi, 'geometry.type', '');
  const originalGeoType = _.get(poi, 'originalGeometry.type', '');

  // prep data to highlight
  // Note: ideally requires 'polygon' type POI geometry data to render correct highlighting
  let poiToHighlight = null;
  if (geoType === 'Polygon') {
    poiToHighlight = poi;
  } else if (originalGeoType === 'Polygon') {
    poiToHighlight = {
      ...poi,
      // overwrite with original geometry for correct highlighting with polygon type data
      geometry: {
        ...poi.originalGeometry,
      },
    };
  } else {
    const newPoi = await window.Mazemap.Data.getPoiAt(
      lngLat,
      getZLevelFromPoi(poi),
    );
    const newGeoType = _.get(newPoi, 'geometry.type', '');
    if (newGeoType === 'Polygon') {
      poiToHighlight = newPoi;
    }
  }
  // Note: building kind POI must have zLevel = 0 to be able to highlight properly
  // secret workaround found in MazeMap demo source
  if (
    poiToHighlight !== null &&
    _.get(poiToHighlight, 'properties.kind') === 'building'
  ) {
    poiToHighlight.properties.zLevel = 0;
  }

  // If we have a polygon, use the default 'highlight' function to draw a marked outline around the POI.
  // Note: highlight is added to related zlevel only, might be invisible due to current map is on different zLevel
  if (isObject(poiToHighlight)) {
    store.highlighter && store.highlighter.highlight(poiToHighlight);
  }
};

/**
 * Directions search, routing Utils
 */
const getRoutePathEndCoordinates = (features) => {
  if (!Array.isArray(features)) {
    return {
      from: null,
      to: null,
    };
  }

  const allCoordinates = [];
  features.forEach((feature) => {
    const coordinates = _.get(feature, 'geometry.coordinates', null);
    if (!Array.isArray(coordinates)) {
      return;
    }
    coordinates.forEach((lngLatArray) => {
      if (!Array.isArray(lngLatArray)) {
        return;
      }
      const lng = lngLatArray[0];
      const lat = lngLatArray[1];
      if (!isNumber(lng) || !isNumber(lat)) {
        return;
      }
      allCoordinates.push({
        lng,
        lat,
      });
    });
  });
  return {
    from: allCoordinates[0] || null,
    to: allCoordinates[allCoordinates.length - 1] || null,
  };
};
const removeRoutePathMarkers = () => {
  const { store } = getMap();
  if (!isObject(store) || !Array.isArray(store.routePathMarkers)) {
    return;
  }
  store.routePathMarkers.forEach((marker) => {
    if (isObject(marker) && isFunction(marker.remove)) {
      marker.remove();
    }
  });
};
const addRoutePathMarker = (coordinates = {}, isFrom = true) => {
  const { map, store } = getMap();
  const routePathMarker = new window.Mazemap.MazeMarker({
    glyph: isFrom ? 'A' : 'B',
  })
    .setLngLat({
      lng: coordinates.lng,
      lat: coordinates.lat,
    })
    .addTo(map);
  store.routePathMarkers.push(routePathMarker);
};
const clearMapRoutePath = () => {
  const { store } = getMap();
  const routeController = store.routeController;
  !!routeController &&
    isFunction(routeController.clear) &&
    routeController.clear();
  removeRoutePathMarkers();
};
const setMapRoutePath = (geoJson) => {
  const { map, store } = getMap();
  const routeController = store.routeController;
  if (!isObject(geoJson) || !isObject(routeController) || !isObject(map)) {
    return;
  }

  // clear any click marker on map
  clearClickMarker();

  // set new path markers
  removeRoutePathMarkers();
  const routePathEnds = getRoutePathEndCoordinates(geoJson.features);
  addRoutePathMarker(routePathEnds.from);
  addRoutePathMarker(routePathEnds.to, false);

  // set new path
  routeController.clear();
  routeController.setPath(geoJson);

  // fit map to bounds
  const bounds = window.Mazemap.Util.Turf.bbox(geoJson);
  const isMobileBP = isMediaQuery();
  // Note:
  // - padding value here needs to consider map 'pitch' value
  // - MazeMap 'fitBounds' calculates paddings assuming 0 'pitch'
  map.fitBounds(bounds, {
    padding: isMobileBP
      ? {
          top: 48,
          bottom: 48,
          left: 72, // cater for 'pitch'
          right: 72, // cater for 'pitch'
        }
      : {
          top: 80,
          bottom: 80,
          left: 128, // cater for 'pitch'
          right: 128, // cater for 'pitch'
        },
    // Notes:
    // - need to provide current bearing and pitch since v2.0.92 (Mapbox GL version 2.12.0)
    // - https://github.com/mapbox/mapbox-gl-js/pull/12367
    bearing: map.getBearing(),
    pitch: map.getPitch(),
  });
};

/**
 * setMapRoute
 * @param dispatch
 * @param from
 * @param to
 * @param options
 * @param cb
 */
const setMapRoute = ({
  dispatch = null,
  from = null,
  to = null,
  options = null,
  cb = null,
}) => {
  if (
    !isFunction(dispatch) ||
    !isObject(from) ||
    !isObject(to) ||
    !isObject(options)
  ) {
    return;
  }

  // set loading in progress
  dispatch({
    type: MAP_ACTIONS.UPDATE_DIRECTION_RESULT,
    payload: {
      viewState: DIRECTIONS_RESULT_VIEW_STATES.LOADING,
      errorMsg: null,
    },
  });
  dispatch({
    type: UI_ACTIONS.UPDATE_UI_BOTTOM_SHEET,
    payload: {
      bottomSheetState: BOTTOM_SHEET_VIEW_STATE.COLLAPSED,
    },
  });

  window.Mazemap.Data.getRouteJSON(from, to, options)
    .then((geoJson) => {
      setMapRoutePath(geoJson);
      if (isFunction(cb)) {
        cb(geoJson);
      }
    })
    .catch((err) => {
      console.warn('Mazemap.Data.getRouteJSON: ', err);
      clearMapRoutePath(); // clear any existing path

      const errorMsg = isObject(err)
        ? err.message || DISPLAY.ROUTE_SEARCH_DEFAULT_ERROR
        : DISPLAY.ROUTE_SEARCH_DEFAULT_ERROR;

      dispatch({
        type: MAP_ACTIONS.UPDATE_DIRECTION_RESULT,
        payload: {
          viewState: DIRECTIONS_RESULT_VIEW_STATES.ERROR,
          errorMsg,
          metrics: null,
          steps: null,
          instructions: null,
        },
      });
      dispatch({
        type: UI_ACTIONS.UPDATE_UI_BOTTOM_SHEET,
        payload: {
          bottomSheetState: BOTTOM_SHEET_VIEW_STATE.COLLAPSED,
        },
      });
    });
};

/**
 * bindRouteSearchPointsPopupCTA
 * @param dispatch
 * @param container
 * @param isStartPoint
 * @param point
 */
const bindRouteSearchPointsPopupCTA = (
  dispatch,
  container,
  point,
  isStartPoint = true,
) => {
  if (!isFunction(dispatch) || !container || !isObject(point)) {
    return;
  }

  const ctaSelector = `button.map-route-popup__cta[data-point="${
    isStartPoint ? 'start' : 'end'
  }"]`;
  const type = isStartPoint
    ? MAP_ACTIONS.UPDATE_DIRECTION_POINT_FROM
    : MAP_ACTIONS.UPDATE_DIRECTION_POINT_TO;
  const payload = isStartPoint
    ? {
        from: {
          ...point,
        },
      }
    : {
        to: {
          ...point,
        },
      };

  const ctaPoint = container.querySelector(ctaSelector);
  !!ctaPoint &&
    ctaPoint.addEventListener('click', () => {
      clearRouteSearchPointsPopup();
      dispatch({
        type,
        payload,
      });
      // need to manually call map resize here
      resizeMap(10);
    });
};

/**
 * clearRouteSearchPointsPopup
 */
const clearRouteSearchPointsPopup = () => {
  const { store } = getMap();
  if (
    store &&
    store.routeSearchPointsPopup &&
    isFunction(store.routeSearchPointsPopup.remove)
  ) {
    store.routeSearchPointsPopup.remove();
    store.routeSearchPointsPopup = null; // destroy each time
  }
};

/**
 * setRouteSearchPointsPopup
 */
const setRouteSearchPointsPopup = ({ point, dispatch }) => {
  if (!isObject(point) || !isObject(point.lngLat) || !isFunction(dispatch)) {
    return;
  }
  const { lng, lat } = point.lngLat;
  if (!isNumber(lng) || !isNumber(lat)) {
    return;
  }

  const { map, store } = getMap();

  // adding new popup
  store.routeSearchPointsPopup = new window.Mazemap.Popup({
    closeOnClick: false,
  })
    .setLngLat([lng, lat])
    .setHTML(HTML_TEMPLATES.ROUTE_POINT_POPUP)
    .addTo(map);
  store.routeSearchPointsPopup.on('close', () => {
    store.routeSearchPointsPopup = null;
  });

  // bind actions to popup CTAs
  const popupContainerNode = store.routeSearchPointsPopup.getElement();
  if (!!popupContainerNode) {
    bindRouteSearchPointsPopupCTA(dispatch, popupContainerNode, point, true);
    bindRouteSearchPointsPopupCTA(dispatch, popupContainerNode, point, false);
  }
};

/**
 * Handlers
 */
const handlePoiPointDetails = (
  {
    lngLat,
    poi,
    shouldClearRoutePath = true,
    shouldPlaceMarker = true,
    dataTypeKey = 'dataType',
  },
  dispatch,
) => {
  // optional map updates
  if (shouldClearRoutePath) {
    clearMapRoutePath();
  }
  if (shouldPlaceMarker) {
    placeClickMarker({ lngLat, poi });
  }

  // update to App POI details view
  if (isFunction(dispatch) && isObject(lngLat)) {
    // Note: we support POI and non-POI data as much as possible
    const hasPoiData = isObject(poi);
    const basePayload = {
      lngLat: [lngLat.lng, lngLat.lat],
      poi: {
        ...poi,
        [dataTypeKey]: hasPoiData ? 'poi' : 'point', // to differentiate from search result data
      },
    };

    const extraPoiDetailsPayload = hasPoiData
      ? getExtraPoiDetails(poi)
      : getExtraPointDetails();

    applyPoiPointDetails(
      {
        ...basePayload,
        poi: {
          ...basePayload.poi,
          ...extraPoiDetailsPayload,
        },
      },
      dispatch,
    );
  }
};
const onMapAreaClick = ({ lngLat, poi }, dispatch) => {
  const { store } = getMap();
  if (store.uiSearchControl === SEARCH_CONTROL.DIRECTIONS) {
    return;
  }
  handlePoiPointDetails(
    {
      // data
      lngLat,
      poi,
      // config
      dataTypeKey: 'mapClickDataType',
    },
    dispatch,
  );
};

/**
 * POI Utils
 */
const getFullPoiDetails = async (poi = {}) => {
  const poiId = _.get(poi, 'properties.poiId', null) || poi.poiId || null;
  if (!isNumber(poiId)) {
    return null;
  }
  // get full details from API
  return await window.Mazemap.Data.getPoi(poiId);
};
const getFullBuildingPoiDetails = async (poi = {}) => {
  const buildingId = _.get(poi, 'properties.id', null);
  if (!isBuildingId(buildingId)) {
    return null;
  }
  // get building POI for proper POI id
  const buildingIdPoi = await window.Mazemap.Data.getBuildingPoiJSON(
    buildingId,
  );
  return await getFullPoiDetails(buildingIdPoi);
};
const getExtraPoiDetails = (poi) => {
  const campusData = getCampusById(_.get(poi, 'properties.campusId', '')) || {};
  const floorText = _.get(poi, 'properties.floorName', '');
  return {
    title: _.get(poi, 'properties.title', ''),
    campusId: _.get(poi, 'properties.campusId', ''),
    campusName: campusData.name || '',
    building: _.get(poi, 'properties.buildingName', ''),
    floor: floorText,
    hasZLevel: !isStringEmpty(floorText),
    formattedDispPoiNames: formatPoiInfosNames(poi),
  };
};
const getExtraPointDetails = () => {
  const nonPoiCampusData = getCampusInfoFromMap();
  return {
    title: 'Point',
    campusName:
      (isObject(nonPoiCampusData) && `${nonPoiCampusData.name} Campus`) ||
      POINT_DISPLAY_TEXT.NON_CAMPUS,
    campusId: (isObject(nonPoiCampusData) && nonPoiCampusData.id) || null,
    building: '',
    floor: '',
    hasZLevel: false,
  };
};
const formatPOIDetails = (poi) => {
  return {
    ...poi,
    ...getExtraPoiDetails(poi),
  };
};
const getZLevelFromPoi = (poi) => {
  const currentZLevel = getMapZLevel();
  if (!isObject(poi)) {
    return currentZLevel;
  }

  const zLevel = _.get(poi, 'properties.zLevel', null);
  const zValue = _.get(poi, 'properties.zValue', null);

  let finalZLevel;
  if (isNumber(zLevel)) {
    finalZLevel = zLevel;
  } else if (isNumber(zValue)) {
    finalZLevel = zValue;
  } else {
    finalZLevel = currentZLevel;
  }
  return finalZLevel;
};
const getTitleFromPoi = (poi) => {
  if (!isObject(poi)) {
    return '';
  }

  const titlePlain = _.get(poi, 'title', null);
  const titleRich = _.get(poi, 'properties.title', null);

  let finalTitle;
  if (!isStringEmpty(titlePlain)) {
    finalTitle = titlePlain;
  } else if (!isStringEmpty(titleRich)) {
    finalTitle = titleRich;
  } else {
    finalTitle = '';
  }
  return finalTitle;
};

/**
 * convertPoiGeometryFromPolygonToPoint
 * Notes:
 * - required for older version of MazeMap API where 'Polygon' type pois missing 'Point' geometry coords data
 * @param poi
 * @returns {*}
 */
const convertPoiGeometryFromPolygonToPoint = (poi) => {
  if (!isObject(poi)) {
    return poi;
  }

  // if poi is not of polygon type, return original
  const poiGeoType = _.get(poi, 'geometry.type', '');
  if (poiGeoType !== 'Polygon') {
    return poi;
  }

  // Get a center point for the POI polygon type
  const lngLat = window.Mazemap.Util.getPoiLngLat(poi);
  if (!isObject(lngLat)) {
    return poi;
  }

  // copy original geometry data as backup
  poi.originalGeometry = {
    ...poi.geometry,
  };
  // write 'point' type data back into Poi item for any 'polygon' type
  poi.geometry = {
    coordinates: [lngLat.lng, lngLat.lat],
    type: 'Point',
  };
  return poi;
};

/**
 * Z Level Utils
 */
const setMapZLevel = (zLevel) => {
  const { map } = getMap();
  map.setZLevel(zLevel);
};
const getMapZLevel = () => {
  const { map } = getMap();
  return map.getZLevel();
};
const adjustZLevelControlMaxHeight = () => {
  const { map } = getMap();
  if (
    isObject(map) &&
    isObject(map.zLevelControl) &&
    isFunction(map.zLevelControl.setMaxHeight)
  ) {
    const maxHeight = isMediaQuery() ? 190 : 400;
    map.zLevelControl.setMaxHeight(maxHeight);
  }
};

const updateCampusData = (payload, dispatch) => {
  dispatch({
    type: MAP_ACTIONS.UPDATE_CAMPUS,
    payload,
  });
};

/**
 * return module object
 */
const mapWorker = {
  // Note: please add worker utils under specific category below

  // constants
  CAMPUS_DETAILS,
  MAP_INIT_DEFAULT_CONFIGS,

  // map instance
  initMap,
  getMap,
  resizeMap,
  centerWithZoom,
  clearSelectedPoiValues,
  clearSelectedPoiTypeValues,
  findMapClickFeatureViaLabel,
  getMapClickResult,

  // marker related
  clearClickMarker,
  placeClickMarker,

  // highlighter related
  getHighlighter,

  // campus
  switchCampus,
  jumpCampus,
  getCampusById,
  getCampusCategories,
  getLatestCampusId,
  drawCampusOutline,
  updateCampusData,
  getCampusInfoFromMap,

  // category / type pois
  getCategoryTypeInfo,
  loadTypePoisForCampus,
  clearCategoryMarkers,
  placeCategoryMarkers,

  // map search
  updateSearchController,

  // directions search, routing
  setMapRoute,
  clearMapRoutePath,
  setRouteSearchPointsPopup,
  clearRouteSearchPointsPopup,

  // handlers and callbacks
  handlePoiPointDetails,
  onMapAreaClick,

  // poi
  getFullPoiDetails,
  getFullBuildingPoiDetails,
  formatPOIDetails,
  getZLevelFromPoi,
  getTitleFromPoi,
  convertPoiGeometryFromPolygonToPoint,

  // z level
  setMapZLevel,
  getMapZLevel,
  adjustZLevelControlMaxHeight,
};

export default mapWorker;
