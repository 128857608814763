import React from 'react';
import { TRAVEL_MODES } from '../../../constants/constants';
import TravelModeBtn from './TravelModeBtn';

import s from './TravelModeGroup.module.scss';

/**
 * TravelModeGroup
 */
const TravelModeGroup = ({
  activeMode = TRAVEL_MODES.PEDESTRIAN,
  updateActiveTravelModeCallBack = null,
}) => {
  return (
    <div className={`travel-mode-group ${s.TravelModeGroup}`} role="radiogroup">
      {/* render all travel modes */}
      {Object.keys(TRAVEL_MODES).map((mode, index) => {
        const props = {
          key: `travel-mode-btn-${index + 1}`,
          mode,
          activeMode,
          updateActiveTravelModeCallBack,
        };

        return <TravelModeBtn {...props} />;
      })}
    </div>
  );
};

export default TravelModeGroup;
