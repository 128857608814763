import React, { useState } from 'react';
import s from './FormCheckbox.module.scss';

/**
 * FormCheckbox
 */
const FormCheckbox = ({
  labelText = '',
  customClass = '',
  isChecked = false,
  onChangeHandler = null,
}) => {
  // states
  const [isFocused, setIsFocused] = useState(false);

  // handlers
  const handleInputChange = (e) => {
    if (typeof onChangeHandler === 'function') {
      onChangeHandler(e);
    }
  };
  const handleInputFocus = () => {
    setIsFocused(true);
  };
  const handleInputBlur = () => {
    setIsFocused(false);
  };

  // view models
  const attrProps = {
    'data-checked': isChecked ? 'true' : 'false',
    'data-focused': isFocused ? 'true' : 'false',
  };

  return (
    <fieldset
      className={`form-checkbox-wrapper ${customClass} ${s.FormCheckbox}`}
      {...attrProps}
    >
      <label className="form-checkbox-label" {...attrProps}>
        <input
          type="checkbox"
          className="form-checkbox-input visuallyhidden"
          checked={isChecked}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />
        {labelText}
      </label>
    </fieldset>
  );
};

export default FormCheckbox;
