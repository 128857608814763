import React, { useContext } from 'react';
import { MAP_ACTIONS, UI_ACTIONS } from '../../reducers/mapReducer';
import { OVERLAY_VIEW_STATE } from '../../constants/constants';
import { MapContext } from '../../contexts/MapContext';
import mw from '../../utils/maps';
import { isFunction } from '../../utils/utils';

import s from './SearchControl.module.scss';

export const displayDefaultCategorySearch = ({ dispatch }) => {
  if (!isFunction(dispatch)) {
    return;
  }

  // clear and reset input
  dispatch({
    type: MAP_ACTIONS.UPDATE_SEARCH_RESULT_QUERY,
    payload: {
      query: '',
    },
  });

  // clear react views
  dispatch({
    type: UI_ACTIONS.RESET_MAP_SEARCH_CONTEXT_TO_INITIAL_STATE,
    payload: null,
  });
  // when clear, also hides overlay
  dispatch({
    type: UI_ACTIONS.UPDATE_UI_OVERLAY,
    payload: {
      overlayState: OVERLAY_VIEW_STATE.HIDDEN,
    },
  });

  // clear map store data
  mw.clearClickMarker();
  mw.clearCategoryMarkers();
};

const SearchControlClearButton = () => {
  const { dispatch } = useContext(MapContext);

  const handleSearchClearClick = () => {
    displayDefaultCategorySearch({ dispatch });
  };

  return (
    <button
      className={s.IconClear}
      onClick={handleSearchClearClick}
      data-test-id="searchControl-clear"
    >
      <span className="visuallyhidden">Clear search input</span>
    </button>
  );
};

export default SearchControlClearButton;
