import {
  DIRECTIONS_RESULT_VIEW_STATES,
  SEARCH_CONTROL,
} from '../constants/constants';
import mw from '../utils/maps';
import { deepEqualObj, isObject } from '../utils/utils';
import { updateDeepLinkFromApp } from '../utils/deepLink';
import { isPointValidForDirections } from '../utils/directions';

const updateDirection = (state, action) => {
  const hasDirectionsSearchParamsUpdated = !deepEqualObj(
    {
      ...state.directions,
    },
    {
      ...state.directions,
      ...action.payload,
    },
  );
  console.debug(
    '[hasDirectionsSearchParamsUpdated]:',
    hasDirectionsSearchParamsUpdated,
  );
  if (hasDirectionsSearchParamsUpdated) {
    // persist to map store so can be used by deeplink
    const { store } = mw.getMap();
    const { from, to } = action.payload;
    if (isObject(from)) {
      store.routeSearchPointFrom = {
        ...from,
      };
    }
    if (isObject(to)) {
      store.routeSearchPointTo = {
        ...to,
      };
    }
    updateDeepLinkFromApp({ dataCaller: 'directionsUpdate' });

    return {
      ...state,
      directions: {
        ...state.directions,
        ...action.payload,
      },
      directionsResult: {
        ...state.directionsResult,
        viewState: DIRECTIONS_RESULT_VIEW_STATES.NEVER_LOADED, // resets results view
      },
    };
  }
};

/**
 * _getReducedPointState
 * @private
 */
const _getReducedPointState = (
  state,
  actionPointPayload,
  pointKey = 'from',
) => {
  const hasDirectionsSearchParamsUpdated = !deepEqualObj(
    {
      ...state.directions[pointKey],
    },
    {
      ...actionPointPayload,
    },
  );
  console.debug(
    `[hasDirectionsSearchParamsUpdated -> Point:${pointKey}]:`,
    hasDirectionsSearchParamsUpdated,
  );

  const TARGET_SEARCH_CONTROL = SEARCH_CONTROL.DIRECTIONS;
  const { store } = mw.getMap();
  // sync search UI state to map store as well
  store.uiSearchControl = TARGET_SEARCH_CONTROL;

  if (hasDirectionsSearchParamsUpdated) {
    // persist to map store so can be used by deeplink
    if (pointKey === 'from') {
      store.routeSearchPointFrom = {
        ...actionPointPayload,
      };
    }
    if (pointKey === 'to') {
      store.routeSearchPointTo = {
        ...actionPointPayload,
      };
    }
    updateDeepLinkFromApp({ dataCaller: 'directionsPointUpdate' });

    return {
      ...state,
      ui: {
        ...state.ui,
        searchControl: TARGET_SEARCH_CONTROL,
      },
      directions: {
        ...state.directions,
        [pointKey]: {
          ...actionPointPayload,
        },
      },
    };
  } else {
    return {
      ...state,
      ui: {
        ...state.ui,
        searchControl: TARGET_SEARCH_CONTROL,
      },
    };
  }
};

const updateDirectionPoint = (state, action) => {
  const hasValidFrom = isPointValidForDirections(state.directions.from);
  const hasValidTo = isPointValidForDirections(state.directions.to);
  const pointPayload = action.payload.point;

  // only from is defined -> update to
  if (hasValidFrom && !hasValidTo) {
    return _getReducedPointState(state, pointPayload, 'to');
  }
  // both defined -> update depends on click count
  else if (hasValidFrom && hasValidTo) {
    // Notes: to determine from and to points based on map click count
    const mapClickCount = state.mapClickEventData.count || 0;
    const pointKey = mapClickCount % 2 !== 0 ? 'from' : 'to';
    return _getReducedPointState(state, pointPayload, pointKey);
  }
  // both NOT defined / only to is defined -> update from
  else {
    return _getReducedPointState(state, pointPayload, 'from');
  }
};

const updateDirectionPointFrom = (state, action) => {
  const pointPayload = action.payload.from;
  return _getReducedPointState(state, pointPayload, 'from');
};

const updateDirectionPointTo = (state, action) => {
  const pointPayload = action.payload.to;
  return _getReducedPointState(state, pointPayload, 'to');
};

const directionReducers = {
  updateDirection,
  updateDirectionPoint,
  updateDirectionPointFrom,
  updateDirectionPointTo,
};
export default directionReducers;
