import Loader from '../loader/Loader';
import s from './LoadingPanel.module.scss';

const LoadingPanel = () => {

    return (
        <div className={s.overlay}>
            <Loader />
        </div>
    )

}

export default LoadingPanel;
