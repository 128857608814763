import React from 'react';
import Modal from 'react-modal';
import IconWrapper, { Icons } from '../../icons/Icons';
import c from './Feedback.module.scss';

export const FEEDBACK_FORM_URL =
  'https://monash.az1.qualtrics.com/jfe/form/SV_5sDHbdSfp9ixQDs';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
  },
};

Modal.setAppElement('#root');

function Feedback({ isOpen, onCloseHandler }) {
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = '#f00';
  }

  function closeModal() {
    onCloseHandler();
  }

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <button className={c.close} onClick={closeModal}>
        <IconWrapper>{Icons.CrossCircle()}</IconWrapper>
      </button>
      <iframe
        title="Feedback"
        style={{ height: 'calc(100% + 100px)', width: '100%' }}
        src={FEEDBACK_FORM_URL}
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
      >
        Loading…
      </iframe>
    </Modal>
  );
}

export default Feedback;
