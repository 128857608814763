import React from 'react';
import { TRAVEL_MODES } from '../../../constants/constants';
import { isStringEmpty } from '../../../utils/string';
import IconWrapper, { Icons } from '../../icons/Icons';

import s from './TravelModeBtn.module.scss';

/**
 * getTravelModeLabel
 * @param mode
 * @returns {string}
 */
export const getTravelModeLabel = (mode = '') => {
  switch (mode) {
    case TRAVEL_MODES.PEDESTRIAN:
      return 'Walking';
    case TRAVEL_MODES.BICYCLE:
      return 'Cycling';
    case TRAVEL_MODES.DRIVE:
      return 'Driving';
    case TRAVEL_MODES.TRANSIT:
      return 'Public Transit';
    default:
      return '';
  }
};

/**
 * getTravelModeIcon
 * @param mode
 * @returns {null|*}
 */
export const getTravelModeIcon = (mode = '') => {
  let icon;

  switch (mode) {
    case TRAVEL_MODES.PEDESTRIAN:
      icon = Icons.Walk();
      break;
    case TRAVEL_MODES.BICYCLE:
      icon = Icons.Cycle();
      break;
    case TRAVEL_MODES.DRIVE:
      icon = Icons.Car();
      break;
    case TRAVEL_MODES.TRANSIT:
      icon = Icons.Train();
      break;
    default:
      icon = null;
  }

  return icon === null ? null : <IconWrapper>{icon}</IconWrapper>;
};

/**
 * isTravelModeActive
 * @param mode
 * @param activeMode
 * @returns {boolean}
 */
export const isTravelModeActive = (mode = '', activeMode = '') => {
  if (typeof mode !== 'string' || isStringEmpty(mode)) {
    return false;
  }
  return mode === activeMode;
};

/**
 * handleBtnTravelModeClick
 * @param mode
 * @param isActive
 * @param callback
 */
const getBtnTravelModeClickHandler =
  (mode = '', isActive = false, callback = null) =>
  (e) => {
    // returns actual click handler fn here
    e.preventDefault();
    if (!isActive && typeof callback === 'function' && !isStringEmpty(mode)) {
      callback(mode);
    }
  };

/**
 * TravelModeBtn
 */
const TravelModeBtn = ({
  mode = '',
  activeMode = '',
  updateActiveTravelModeCallBack = null,
}) => {
  const modeLabel = getTravelModeLabel(mode);
  const isActive = isTravelModeActive(mode, activeMode);

  return (
    <button
      className={`btn-travel-mode ${s.TravelModeBtn}`}
      role="radio"
      title={modeLabel}
      aria-label={modeLabel}
      aria-checked={isActive ? 'true' : 'false'}
      data-active={isActive ? 'true' : 'false'}
      onClick={getBtnTravelModeClickHandler(
        mode,
        isActive,
        updateActiveTravelModeCallBack,
      )}
    >
      {getTravelModeIcon(mode)}
    </button>
  );
};

export default TravelModeBtn;
