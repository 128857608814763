import React from 'react';
import ReactDOM from 'react-dom';

import MapContextProvider from './contexts/MapContext';
import ErrorBoundary from './components/error/ErrorBoundary';
import './index.scss';
import App from './App';
//import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV !== 'production') {
  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 1000);
}

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <MapContextProvider>
        <h1 className="visuallyhidden">Digital Wayfinding</h1>
        <App />
      </MapContextProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
