/**
 * FEATURES
 * constants static feature toggles
 */
const FEATURES = {
  SEARCH_DEEP_LINK_SUPPORT: true,
  MOBILE_FULLSCREEN_ALLOWED: true,
  DISPLAY_SEARCH_RESULT_DISTANCE_TO_CAMPUS_CENTRE: false,
  DISPLAY_SEARCH_RESULT_DISTANCE_TO_GEOLOCATION: true,
};
export default FEATURES;
