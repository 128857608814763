import * as _ from 'lodash-es';
import FEATURES from '../../constants/features';
import mw, { DEFAULT_CAMPUS_ID } from '../../utils/maps';
import { isNumber } from '../../utils/number';
import { isDefined, isObject } from '../../utils/utils';
import { isStringEmpty, convertHtmlStringToText } from '../../utils/string';
import { computeGeoDistanceInKmBetween } from '../../utils/distance';

/**
 * formatDispPoiNames
 * - converts Allocate+ names into UX friendly display names text
 * @param dispPoiNames
 */
export const formatDispPoiNames = (dispPoiNames) => {
  if (!Array.isArray(dispPoiNames) || dispPoiNames.length <= 1) {
    return null;
  }

  // remove first item as is not needed for display
  const names = [...dispPoiNames];
  names.shift();

  // return a complete string without HTML tags
  return `${convertHtmlStringToText(names.join(', '))}`;
};

/**
 * formatPoiInfosNames
 * @param poi
 * @returns {null|*}
 */
export const formatPoiInfosNames = (poi) => {
  if (!isObject(poi)) {
    return null;
  }
  const names = _.get(poi, 'properties.names', null);
  if (!Array.isArray(names) || names.length <= 1) {
    return null;
  }

  // map names into names list
  const title = _.get(poi, 'properties.title', '');
  const displayNames = names
    .filter((name) => {
      return !isStringEmpty(name) && name !== title;
    })
    .map((name) => {
      return name.trim();
    });

  // return complete string
  return displayNames.join(', ');
};

/**
 * formatMixedSearchResult
 * @param {[*]} result
 * @param latestCampusId
 * @returns {[]}
 */
export const formatMixedSearchResult = (
  result,
  latestCampusId = DEFAULT_CAMPUS_ID,
) => {
  const search_results = [];
  const latestCampusData = mw.getCampusById(latestCampusId);

  result.forEach((item) => {
    const title = _.get(item, 'properties.title', null);
    if (isStringEmpty(title)) {
      return;
    }

    // TODO: to refactor the mapping logic here, lots of edge cases to be patched up
    const type = _.get(item, 'properties.type', null);
    switch (type) {
      case 'category':
        const campusData = {
          campusId: latestCampusId, // pass in latest map campusId so we can retrieve more data from API
          campusName: isObject(latestCampusData) ? latestCampusData.name : '',
        };
        if (isNumber(latestCampusId)) {
          search_results.push({
            title,
            type,
            id: item.properties.id,
            originalCategoryDetails: {
              ...item,
              ...campusData,
            },
            ...campusData,
          });
        }
        break;

      default:
        const hasZLevel = !isStringEmpty(item.properties.zName);
        const hasBuilding =
          isDefined(item.properties.dispBldNames) &&
          !isStringEmpty(item.properties.dispBldNames[0]);
        const campusId = _.get(item, 'properties.campusId', null);
        const campusDetail = isNumber(campusId)
          ? mw.getCampusById(campusId)
          : null;
        const campusName = isObject(campusDetail)
          ? campusDetail.name || ''
          : '';
        const campusLng = isObject(campusDetail) ? campusDetail.lng : null;
        const campusLat = isObject(campusDetail) ? campusDetail.lat : null;
        const coordinates = _.get(item, 'geometry.coordinates', null);
        const coordinateLng = Array.isArray(coordinates)
          ? coordinates[0]
          : null;
        const coordinateLat = Array.isArray(coordinates)
          ? coordinates[1]
          : null;
        const dispPoiNames = _.get(item, 'properties.dispPoiNames', null);

        search_results.push({
          building: hasBuilding
            ? convertHtmlStringToText(item.properties.dispBldNames[0])
            : null,
          campusId,
          campusName,
          coordinates,
          coordinateLng,
          coordinateLat,
          floor: hasZLevel
            ? convertHtmlStringToText(item.properties.zName) === 'G'
              ? 'Ground'
              : convertHtmlStringToText(item.properties.zName)
            : null,
          hasZLevel: hasZLevel,
          hasBuilding: hasBuilding,
          formattedDispPoiNames: formatDispPoiNames(dispPoiNames),
          resultId: item.properties.poiId || item.properties.id,
          title: !isStringEmpty(title) ? convertHtmlStringToText(title) : null,
          type,
          distanceToCampus: {
            km: FEATURES.DISPLAY_SEARCH_RESULT_DISTANCE_TO_CAMPUS_CENTRE
              ? computeGeoDistanceInKmBetween(
                  campusLng,
                  campusLat,
                  coordinateLng,
                  coordinateLat,
                )
              : null,
          },
          distanceToGeolocation: {
            km: null, // default value, updated via geolocation updates in real time
          },
          originalPoiDetails: {
            ...item,
          },
        });
    }
  });

  return search_results;
};
