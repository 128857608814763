import * as _ from 'lodash-es';
import BIData from '../../api/BIData';
import React, { useEffect, useContext, useRef, useCallback } from 'react';
import mw from '../../utils/maps';
import { MapContext } from '../../contexts/MapContext';
import { OVERLAY_VIEW_STATE, SEARCH_DISPLAY } from '../../constants/constants';
import { MAP_ACTIONS, UI_ACTIONS } from '../../reducers/mapReducer';
import { isCampusDataAvailableForId } from '../../utils/data/campusData';
import { isStringEmpty } from '../../utils/string';
import { isFunction, isObject, typeDelay } from '../../utils/utils';
import { formatMixedSearchResult } from './SearchResults.mapper';

import s from './SearchControl.module.scss';

export const isSearchTermValid = (value = '') => {
  return !isStringEmpty(value) && value.trim().length > 2;
};

export const displayMixedSearchOverlay = ({ map, dispatch }) => {
  if (!isObject(map) || !isFunction(dispatch)) {
    return;
  }

  // display overlay
  const overlayState = _.get(map, 'ui.overlayState');
  if (overlayState !== OVERLAY_VIEW_STATE.VISIBLE) {
    dispatch({
      type: UI_ACTIONS.UPDATE_UI_OVERLAY,
      payload: {
        overlayState: OVERLAY_VIEW_STATE.VISIBLE,
      },
    });
  }
  // update UI to search result
  const searchDisplay = _.get(map, 'searchDisplay.component');
  if (searchDisplay !== SEARCH_DISPLAY.SEARCH_MIXED) {
    dispatch({
      type: UI_ACTIONS.UPDATE_UI_SEARCH_DISPLAY,
      payload: {
        component: SEARCH_DISPLAY.SEARCH_MIXED,
      },
    });
  }
};

const SearchControlInputField = () => {
  // app states
  const { map, dispatch } = useContext(MapContext);
  const mapCampusId = _.get(map, 'campus.id');
  const mapSearchDisplayComp = _.get(map, 'searchDisplay.component');
  // search field
  const searchInputRef = useRef(null);
  const searchInputTerm = map.searchResults.query;
  // search campus name
  const searchCampusName = mw.getCampusById(
    mapCampusId || mw.getLatestCampusId(),
  )?.name;

  // search util
  const doMixedResultSearch = useCallback(
    (query, isFromCampusSwitch = false) => {
      console.debug(
        'doMixedResultSearch:',
        isFromCampusSwitch ? 'auto campus switch' : 'manual input change',
      );
      if (isStringEmpty(query)) {
        return;
      }

      const { store } = mw.getMap();

      // Notes:
      // - configure search controller before execute search
      // - needs to update the search controller with latest campusId value from the map location
      // - search without a campusId will return category results without campusId, therefore cannot retrieve POIs within
      const latestCampusId = mw.getLatestCampusId();
      // do nothing for ad-hoc mazemap campus ids
      if (!isCampusDataAvailableForId(latestCampusId)) {
        dispatch({
          type: UI_ACTIONS.UPDATE_UI_SEARCH_DISPLAY,
          payload: {
            component: SEARCH_DISPLAY.SEARCH_MIXED,
            isSearching: false,
          },
        });
        return;
      }
      mw.updateSearchController({
        campusid: latestCampusId,
      });

      // Perform a search query using the Search object
      const asyncSearch = async () => {
        try {
          // analytics
          BIData.push(BIData.TAG_SEARCH, { query });

          const response = await store.searchController.search(query);
          const search_results = formatMixedSearchResult(
            response.results.features,
            latestCampusId,
          );

          dispatch({
            type: MAP_ACTIONS.UPDATE_SEARCH_RESULT,
            payload: {
              results: search_results,
              num_results: search_results.length,
            },
          });
          dispatch({
            type: UI_ACTIONS.UPDATE_UI_SEARCH_DISPLAY,
            payload: {
              component: SEARCH_DISPLAY.SEARCH_MIXED,
              isSearching: false,
            },
          });
        } catch (e) {
          e === 'Search request cancelled due to new search initiated'
            ? console.warn('doMixedResultSearch: ', e)
            : console.error(e);
        }
      };
      asyncSearch().finally();
    },
    [dispatch],
  );

  // auto fire search again upon campus context switch
  useEffect(() => {
    if (
      mapSearchDisplayComp === SEARCH_DISPLAY.SEARCH_MIXED &&
      isCampusDataAvailableForId(mapCampusId) &&
      !!searchInputRef
    ) {
      const searchInputValue = searchInputRef.current.value || '';
      if (isSearchTermValid(searchInputValue)) {
        dispatch({
          type: UI_ACTIONS.UPDATE_UI_SEARCH_DISPLAY,
          payload: {
            component: SEARCH_DISPLAY.SEARCH_MIXED,
            isSearching: true,
          },
        });
        doMixedResultSearch(searchInputValue, true);
      }
    }
  }, [dispatch, doMixedResultSearch, mapCampusId, mapSearchDisplayComp]);

  // search input handlers
  const handleSearchInputChange = (e) => {
    const value = (e && e.target.value) || '';
    dispatch({
      type: MAP_ACTIONS.UPDATE_SEARCH_RESULT_QUERY,
      payload: {
        query: value,
      },
    });

    // following operations requires debounce
    typeDelay(() => {
      if (value.trim() === '') {
        dispatch({
          type: UI_ACTIONS.RESET_MAP_SEARCH_CONTEXT_TO_INITIAL_STATE,
          payload: null,
        });
        // overlay should still be visible here
      } else {
        if (isSearchTermValid(value)) {
          dispatch({
            type: UI_ACTIONS.UPDATE_UI_SEARCH_DISPLAY,
            payload: {
              component: SEARCH_DISPLAY.SEARCH_MIXED,
              isSearching: true,
            },
          });
          doMixedResultSearch(value);
        }
      }
    }, 500);
  };
  const handleOnInputFocus = () => {
    displayMixedSearchOverlay({
      map,
      dispatch,
    });
  };

  return (
    <input
      ref={searchInputRef}
      tabIndex="0"
      className={s.SearchInput}
      autoComplete="off"
      type="text"
      name="search"
      placeholder={`Search ${
        !isStringEmpty(searchCampusName) ? searchCampusName : ''
      }`}
      maxLength={50}
      value={searchInputTerm}
      onChange={handleSearchInputChange}
      onFocus={handleOnInputFocus}
      data-test-id="searchControl-search-input"
    />
  );
};

export default SearchControlInputField;
