import React from 'react';
import { isStringEmpty } from '../../utils/string';
import IconWrapper, { Icons } from '../icons/Icons';

export const MSG_BOX_TYPE = {
  // adding new types here
  ERROR: 'ERROR',
};

/**
 * MsgBox
 */
const MsgBox = ({ msg = '', type = MSG_BOX_TYPE.ERROR }) => {
  if (isStringEmpty(msg) || isStringEmpty(type)) {
    return null;
  }

  return (
    <div className={`msgBox msgBox--${type.toLowerCase()}`}>
      <IconWrapper>{Icons.CrossCircle()}</IconWrapper>
      <p className="msgBox__msg">{msg}</p>
    </div>
  );
};

export default MsgBox;
