import * as _ from 'lodash-es';
import { TRAVEL_STEP_TYPES } from '../../constants/constants';
import { isNumber } from '../../utils/number';
import { isObject } from '../../utils/utils';

/**
 * pickDistanceMetric
 * @param distanceWalkingMeters
 * @param distanceCyclingMeters
 * @param distanceDrivingMeters
 * @returns {null|*}
 */
export const pickDistanceMetric = ({
  distanceDrivingMeters,
  distanceCyclingMeters,
  distanceWalkingMeters,
}) => {
  if (isNumber(distanceDrivingMeters) && distanceDrivingMeters > 0) {
    return distanceDrivingMeters;
  } else if (isNumber(distanceCyclingMeters) && distanceCyclingMeters > 0) {
    return distanceCyclingMeters;
  } else if (isNumber(distanceWalkingMeters) && distanceWalkingMeters > 0) {
    return distanceWalkingMeters;
  } else {
    return null;
  }
};

/**
 * pickDurationMetric
 * @param durationDrivingMinutes
 * @param durationCyclingMinutes
 * @param durationWalkingMinutes
 * @param durationEstimateSeconds
 * @returns {null|number|*}
 */
export const pickDurationMetric = ({
  durationDrivingMinutes,
  durationCyclingMinutes,
  durationWalkingMinutes,
  durationEstimateSeconds,
}) => {
  if (isNumber(durationEstimateSeconds) && durationEstimateSeconds > 0) {
    return Math.ceil(durationEstimateSeconds / 60);
  } else if (isNumber(durationDrivingMinutes) && durationDrivingMinutes > 0) {
    return durationDrivingMinutes;
  } else if (isNumber(durationCyclingMinutes) && durationCyclingMinutes > 0) {
    return durationCyclingMinutes;
  } else if (isNumber(durationWalkingMinutes) && durationWalkingMinutes > 0) {
    return durationWalkingMinutes;
  } else {
    return null;
  }
};

/**
 * transformDirectionSearchSteps
 * @param data
 */

export const FEATURE_GEO_TYPE = {
  LINE_STRING: 'LineString',
  POINT: 'Point', // building, PT, etc.
};

export const transformDirectionSearchSteps = (data = null) => {
  console.debug('transformDirectionSearchSteps: ', data);

  const pathMetrics = _.get(data, 'properties.pathMetrics', null);
  const features = _.get(data, 'features', []);

  const steps = [];
  for (let i = 0; i < features.length; i += 1) {
    const feat = features[i];
    const geoType = _.get(feat, 'geometry.type', '');
    const properties = _.get(feat, 'properties', {});

    // Notes: based on different GeoType, different properties will be provided
    if (geoType === FEATURE_GEO_TYPE.LINE_STRING) {
      const {
        travelType = '',
        timeEstimateSeconds = null,
        m = null,
      } = properties;

      steps.push({
        type: travelType,
        durationMinutes: isNumber(timeEstimateSeconds)
          ? timeEstimateSeconds / 60
          : null,
        distanceMeters: isNumber(m) ? m : null,
      });
    } else if (geoType === FEATURE_GEO_TYPE.POINT) {
      const { routepoint = '' } = properties;

      // Skip PT route point until further requirements e.g. on/off bus, trams
      // PT line string data will be used instead e.g. bus, rail durations, etc.
      if (
        routepoint === TRAVEL_STEP_TYPES.BUS ||
        routepoint === TRAVEL_STEP_TYPES.TRAM ||
        routepoint === TRAVEL_STEP_TYPES.RAIL ||
        routepoint === TRAVEL_STEP_TYPES.NO_HC
      ) {
        continue;
      }

      steps.push({
        type: routepoint,
        // 'Point' type has no duration and distance data
      });
    }
  }

  return {
    metrics: isObject(pathMetrics)
      ? {
          distanceMeters: pickDistanceMetric(pathMetrics),
          durationMinutes: pickDurationMetric(pathMetrics),
        }
      : null,
    steps: !!steps.length ? [...steps] : null,
  };
};

/**
 * transformDirectionSearchIns
 * @param data
 */
export const transformDirectionSearchIns = (data = null) => {
  let result = null;
  try {
    result = data.routes[0].legs[0].steps.map((item) => {
      return item['instruction'].trim();
    });
  } catch (e) {
    console.warn('transformDirectionSearchIns: ', e);
  }
  return result;
};
