/**
 * UI_COLOURS
 * constants for monash UI colours
 */
export const UI_COLOURS = {
  BLUE_DEFAULT: '#006dae',
  BLUE_HOVER: '#0289d4',
  BLACK: '#000',
  WHITE: '#fff',
};

/**
 * MM_LAYERS
 * constants for MazeMap feature layers
 */
export const MM_LAYERS = {
  FLOOR_OUTLINE: 'mm-floor-outline',
  BUILDING_LABEL: 'mm-building-label',
  BUILDING_FILL: 'mm-building-fill',
  CAMPUS_AREA: 'mm-campus-area',
  CAMPUS_LABEL: 'mm-campus-label',
  CAMPUS_DOT: 'mm-campus-dot',
};

/**
 * SEARCH_SCREENS
 * constants for UI display
 */
export const DISPLAY = {
  UNKNOWN_VALUE: '--',
  ROUTE_SEARCH_DEFAULT_ERROR: 'Routing information cannot be retrieved',
};

/**
 * POINT_DISPLAY_TEXT
 * constants for Point data display text
 */
export const POINT_DISPLAY_TEXT = {
  NONE: '',
  DEFAULT: 'Point',
  USER_LOCATION: 'My Location',
  NON_CAMPUS: 'Outdoor',
};

/**
 * SEARCH_DISPLAY
 * constants for different components to display
 */
export const SEARCH_DISPLAY = {
  NONE: 'NONE', // Show nothing, used for blank display
  SEARCH_MIXED: 'SEARCH_MIXED', // show the search results
  SEARCH_CATEGORIES: 'SEARCH_CATEGORIES', // Default - show the categories (from campus filter)
  SEARCH_RESULT_POI_DETAIL: 'SEARCH_RESULT_POI_DETAIL', // show the poi details
  SEARCH_RESULT_CATEGORY_DETAIL: 'SEARCH_RESULT_CATEGORY_DETAIL', // show the category details
};

/**
 * BACK_BTN_TARGET
 * constants for search input back button targets
 */
export const BACK_BTN_TARGET = {
  NONE: 'NONE',
  CATEGORY_DETAILS: 'CATEGORY_DETAILS',
  MIXED_SEARCH_RESULT: 'MIXED_SEARCH_RESULT',
};

/**
 * DIRECTIONS_RESULT_VIEW_STATES
 * constants for directions results view states
 */
export const DIRECTIONS_RESULT_VIEW_STATES = {
  NEVER_LOADED: 'NEVER_LOADED', // never loaded, used for resetting results view
  LOADING: 'LOADING', // loading in progress
  ROUTE_LOADED: 'ROUTE_LOADED', // only metrics and steps are loaded, NOT instructions
  DETAILS_LOADED: 'DETAILS_LOADED', // all data is loaded
  ERROR: 'ERROR', // used when basic API fails where there is no data at all
};

/**
 * BOTTOM_SHEET_VIEW_STATE
 * constants for bottom sheet UI view states
 */
export const BOTTOM_SHEET_VIEW_STATE = {
  HIDDEN: 'HIDDEN', // hidden from UI
  EXPANDED: 'EXPANDED', // fully displayed
  COLLAPSED: 'COLLAPSED', // partially displayed
};

/**
 * OVERLAY_VIEW_STATE
 * constants for map overlay UI view states
 */
export const OVERLAY_VIEW_STATE = {
  HIDDEN: 'HIDDEN', // hidden from UI
  VISIBLE: 'VISIBLE', // fully displayed
};

/**
 * SEARCH_SCREENS
 * constants for different search UI groups
 */
export const SEARCH_CONTROL = {
  SEARCH: 'SEARCH',
  DIRECTIONS: 'DIRECTIONS',
};

/**
 * TRAVEL_MODES
 * constants for different travel modes
 */
export const TRAVEL_MODES = {
  PEDESTRIAN: 'PEDESTRIAN',
  BICYCLE: 'BICYCLE',
  DRIVE: 'DRIVE',
  TRANSIT: 'TRANSIT', // using PT
};

/**
 * TRAVEL_OPTIONS
 * constants for different travel options
 */
export const TRAVEL_OPTIONS = {
  AVOID_STAIRS_OBSTACLES: {
    keyText: 'AVOID_STAIRS_OBSTACLES',
    label: 'Avoid stairs and obstacles',
  },
  //... add any new travel options here
  // NEW_OPTION: {
  //   keyText: 'NEW_OPTION',
  //   label: 'New option label text',
  // },
};

/**
 * TRAVEL_STEP_TYPES
 * constants for different travel steps used in directions step data
 */
export const TRAVEL_STEP_TYPES = {
  FOOT: 'FOOT',
  ROAD: 'ROAD', // bicycle
  CAR: 'CAR',
  BUS: 'BUS', // transit
  TRAM: 'TRAM', // transit
  RAIL: 'RAIL', // transit
  BUILDING_ENTER: 'BUILDING_ENTER',
  BUILDING_EXIT: 'BUILDING_EXIT',
  ELEVATOR_UP: 'ELEVATOR-UP',
  ELEVATOR_DOWN: 'ELEVATOR-DOWN',
  STAIR: 'STAIR',
  STAIR_UP: 'STAIR-UP',
  STAIR_DOWN: 'STAIR-DOWN',
  NO_HC: 'NO_HC',
};
