import React from 'react';
import s from './style.module.css';
class ErrorBoundary extends React.Component {
  state = { hasError: false, errorMessage: '' };
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
    this.setState((state) => {
      return {
        ...state,
        errorMessage: error.message,
      };
    });
  }
  render() {
    if (this.state.hasError) {
      return (
        <div className={s.body}>
          <div className={s.cover}>
            <h1>
              Website error&nbsp;<small>500</small>
            </h1>
            <p className={s.lead}>
              An unexpected condition was encountered.
              <br />
              Please try again, refresh the page or contact us if the issue persits.
            </p>
            <p className={s.error}>{this.state.errorMessage}</p>
            <p>
              <button className={s.btn}
                type="button"
                onClick={() => this.setState({ hasError: false })}
              >
                Try again?
              </button>&nbsp;
              <button className={s.btn}
                type="button"
                onClick={() => window.location.reload()}
              >
                Refresh page!
              </button>              
            </p>

            
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
