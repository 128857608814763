import React from 'react';
import { isNumber } from '../../utils/number';
import s from './CategoryResults.module.scss';

// Ref: https://api.mazemap.com/api/icons/199.png?size=30
const ICON_BASE_URL = 'https://api.mazemap.com/api/icons/{0}.png'; // ?size=30

export const formatCategoryResultDataList = (categoryResults = null) => {
  if (!Array.isArray(categoryResults)) {
    return [];
  }

  return categoryResults.map((categoryItem) => {
    return {
      id: categoryItem.id,
      title: categoryItem.title,
      icon_id: categoryItem.icon_id,
      description: null,
      footer: null,
      clickData: {
        id: categoryItem.id,
        title: categoryItem.title,
      },
    };
  });
};

export const getCategoryIcon = (id) => {
  return isNumber(id) ? (
    <img src={ICON_BASE_URL.replace('{0}', id)} alt="" />
  ) : null;
};

const CategoryResults = ({ dataList = [], onItemClickHandler }) => {
  return (
    <div className={s.PoiResults} data-test-id="category-list">
      {dataList.map((item, index) => {
        const itemImage = getCategoryIcon(item.icon_id);
        return (
          <div
            key={`poi-result-${index + 1}`}
            className={s.PoiResultItem}
            tabIndex="0"
            onClick={() => onItemClickHandler(item.clickData)}
            data-test-category={item.id}
          >
            <div
              className={s.Img}
              aria-hidden="true"
              data-has-image={!!itemImage ? 'true' : 'false'}
            >
              {itemImage}
            </div>
            <div className={s.Content}>
              <div className={s.Title}>{item.title}</div>
              {item.description && (
                <div className={s.Text}>{item.description}</div>
              )}
              {item.footer && <div className={s.Footer}>{item.footer}</div>}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CategoryResults;
