import { useState, useEffect } from 'react';

const $mobile = '640px';
const $mobileQuery = `(max-width: ${$mobile})`;

export function useMediaQuery(query = $mobileQuery) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    if (typeof media.addListener !== 'undefined') {
      media.addListener(listener);
    } else {
      media.addEventListener('change', listener);
    }

    return () => {
      if (typeof media.addListener !== 'undefined') {
        media.removeListener(listener);
      } else {
        media.removeEventListener('change', listener);
      }
    };
  }, [matches, query]);

  return matches;
}

/**
 * isMediaQuery
 * - for usages outside of React hooks
 * @param query
 * @returns {boolean}
 */
export const isMediaQuery = (query = $mobileQuery) => {
  const media = window.matchMedia(query);
  return media.matches;
};
