import React, { useContext } from 'react';
import { BOTTOM_SHEET_VIEW_STATE } from '../../constants/constants';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { MapContext } from '../../contexts/MapContext';
import BottomSheet from './BottomSheet';
import PanelMapOverlay from './PanelMapOverlay';
import ReportIssueCTA from '../buttons/ReportIssueCTA';

const PanelMap = ({
  className = '',
  mapArea = null,
  beforeMap = null,
  afterMap = null,
  overlay = null,
  bottomSheetEnabled = true,
}) => {
  const isMobile = useMediaQuery();
  const { map } = useContext(MapContext);
  const { bottomSheetState, overlayState } = map.ui;
  const isBottomSheetExpanded =
    bottomSheetState === BOTTOM_SHEET_VIEW_STATE.EXPANDED;
  const isBottomSheetHidden =
    bottomSheetState === BOTTOM_SHEET_VIEW_STATE.HIDDEN;
  const hasBeforeMap = beforeMap !== null;
  const hasAfterMap = afterMap !== null;
  const hasOverlay = overlay !== null;
  const props = {
    className: className,
  };
  if (isMobile) {
    props['data-bottom-sheet-state'] = bottomSheetState.toLowerCase();
    props['data-overlay-state'] = overlayState.toLowerCase();
  }

  return (
    <div {...props}>
      {/* contents before the map area */}
      {isMobile && hasBeforeMap && (
        <div
          className={`panel-map-area${isBottomSheetExpanded ? ' hidden' : ''}`}
          id="mapBefore"
          data-area="before"
        >
          {beforeMap}
        </div>
      )}
      {/* map area content root */}
      {mapArea}
      {/* contents after the map area, wrapped inside bottom sheet */}
      {isMobile && hasAfterMap && (
        <div
          className={`panel-map-area${
            bottomSheetEnabled && isBottomSheetHidden ? ' hidden' : ''
          }`}
          id="mapAfter"
          data-area="after"
        >
          {bottomSheetEnabled ? (
            <BottomSheet>{afterMap}</BottomSheet>
          ) : (
            <>{afterMap}</>
          )}
        </div>
      )}
      {/* map overlay UI */}
      {isMobile && hasOverlay && (
        <PanelMapOverlay footer={<ReportIssueCTA />}>{overlay}</PanelMapOverlay>
      )}
    </div>
  );
};

export default PanelMap;
